import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  ${({ bg }) => (bg ? `background: #ebebff;` : ``)}
  padding: 10px;
  border-radius: 20px;
`;

export const Title = styled.h3`
  font-size: 32px !important;
  font-weight: 900;
  color: var(--bs-primary);
`;

export const Description = styled.h4`
  font-size: 20px;
  font-weight: 400;
  color: black;
  text-align: center;
`;

export const CardsContainer = styled.section`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const Card = styled.article`
  max-width: 800px;
  width: 100%;
  padding: 10px 10px;
  border-radius: 40px;
  border: 4px solid var(--bs-primary);
  font-size: 20px;
  font-weight: 900;
  color: var(--bs-primary);
  transition: 0.5s all;

  ${({ selected }) =>
    !selected &&
    `
      &:hover {
        color: white !important;
        background-color: #0524dd !important;
      }
  `}
`;

export const Button = styled.div`
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  letter-spacing: 0;
  background-size: 150%;
  background-position-x: 25%;
  position: relative;
  overflow: hidden;
  box-shadow: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 15px 10px;
  line-height: 1.3;
  font-weight: 900;
  border-radius: 36rem !important;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  transition: all 0.5s ease-in;
  text-transform: none;
  font-size: 16px;
  color: white !important;
  background-color: #0524dd !important;
  border: 3px solid #0524dd !important;
  opacity: 1 !important;

  &:hover {
    color: #0524dd !important;
    background-color: white !important;
  }
`;

export default {};
