const Pagination = ({ numbersOfPage, callback, onPrev, onNext, activePage, onFirst, onLast }) => {
  // fill an array with numbers from 1 to numbersOfPage
  const numbers = Array.from(Array(numbersOfPage).keys()).map((x) => x + 1);
  const minPageSlice = activePage - 3 < 0 ? 0 : activePage - 3;
  const maxPageSlice = activePage + 3 > numbers.length ? numbers.length : activePage + 3;
  const isMoreThanSixPages = numbersOfPage > 6;

  if (numbersOfPage === 0) return <></>;
  return (
    <>
      <div className="d-block m-auto text-center prova">
        Pagina <b>{activePage}</b> di {numbersOfPage}
      </div>
      <div
        className="pagination"
        style={{ margin: "auto", display: "flex", width: "100%", justifyContent: "center" }}
      >
        {isMoreThanSixPages && (
          <button type="button" className="btn-white" onClick={onFirst}>
            &laquo; Inizio
          </button>
        )}

        <button type="button" className="  btn-white" onClick={onPrev}>
          {" "}
          &laquo;{" "}
        </button>
        {isMoreThanSixPages}

        {numbers.slice(minPageSlice, maxPageSlice).map((pag) => (
          <button
            key={pag}
            className={activePage === pag ? "btn-primary" : "btn-white"}
            type="button"
            onClick={callback(pag)}
          >
            {pag}
          </button>
        ))}
        {isMoreThanSixPages}
        <button type="button" className="  btn-white" onClick={onNext}>
          {" "}
          &raquo;{" "}
        </button>
        {isMoreThanSixPages && (
          <button type="button" className="  btn-white" onClick={onLast}>
            Fine &raquo;
          </button>
        )}
      </div>
    </>
  );
};

export default Pagination;
