import { useEffect, useState } from "react";
import dateWrapper from "./date-wrapper";

const formatDate = (date, format = "dd-mm-YYYY") => {
  const dayNames = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
  const monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const d = dateWrapper(date);
  const month = d.getMonth();
  const day = d.getDate();
  const year = d.getFullYear();
  const weekDay = d.getDay();
  const hour = d.getHours();
  const minutes = d.getMinutes();

  const monthNumber = month + 1;

  let str = format;
  str = str.replace("YYYY", year);
  str = str.replace("mm", monthNumber >= 10 ? monthNumber : `0${monthNumber}`);
  str = str.replace("/(\bm(\b)/", monthNumber);
  str = str.replace("dd", day >= 10 ? day : `0${day}`);
  str = str.replace(/(\bd\b)/, day);
  str = str.replace(/(\bF\b)/, monthNames[month]);
  str = str.replace(/(\bFs\b)/, monthNames[month].substring(0, 3));
  str = str.replace(/(\bl\b)/, dayNames[weekDay]);
  str = str.replace(/(\bh\b)/, hour >= 10 ? hour : `0${hour}`);
  str = str.replace(/(\bi\b)/, minutes >= 10 ? minutes : `0${minutes}`);

  return str;
};

export default formatDate;
