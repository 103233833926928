import { CircularProgress, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import FormField from "pagensp/CustomComponents/FormField";
import Select from "components/Select";
import { CardsContainer, Card, Container, Title, Description, ProgressBar } from "./style";

/* eslint-disable no-restricted-globals */
function Component({ title, description, cards, bg, children }) {
  return (
    <Container bg={bg}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <CardsContainer>
        {cards?.map((card, index) => (
          <Card key={card.id}>
            <h4>{card.title}</h4>
            <ProgressBar value={card.percentage} />
          </Card>
        ))}
      </CardsContainer>
      {children}
    </Container>
  );
}

const Form = ({ onChange, data }) => {
  const onAddCard = () => {
    const newCards = data.cards ? [...data.cards] : [];
    newCards.push({
      id: Math.floor(Math.random() * 99999),
      title: "",
      percentage: "",
    });
    onChange({ ...data, cards: newCards, activeTab: 0 });
  };

  const onEditCard = (index, key, value) => {
    if (key === "percentage" && isNaN(Number(value))) return;
    const newCards = [...data.cards];
    newCards[index][key] = value;
    onChange({ ...data, cards: [...newCards] });
  };

  const onDeleteCard = (index) =>
    onChange({
      ...data,
      cards: [...data.cards.filter((item, i) => i !== index)],
      activeTab: 0,
    });

  const card = data?.cards?.length > 0 ? data.cards[data.activeTab || 0] : null;

  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <FormField
          type="text"
          label="Titolo"
          value={data.title}
          onChange={({ target }) => onChange({ ...data, title: target.value })}
        />
        <FormField
          type="text"
          label="Descrizione"
          value={data.description}
          onChange={({ target }) => onChange({ ...data, description: target.value })}
        />
        <Select
          label="Sfondo azzurro"
          options={[
            { label: "Si", value: true },
            { label: "No", value: false },
          ]}
          value={data.bg}
          onChange={({ value }) => onChange({ ...data, bg: value })}
          style={{
            zIndex: 9999,
          }}
        />
      </Grid>

      <Grid item xs={12} mb={1}>
        <MDButton variant="gradient" color="info" onClick={onAddCard}>
          Aggiungi Card
        </MDButton>
      </Grid>

      <Grid item xs={12} mb={1}>
        {data?.cards?.map((item, index) => (
          <MDButton
            variant="gradient"
            color={data.activeTab === index ? "primary" : "secondary"}
            onClick={() => onChange({ ...data, activeTab: index })}
          >
            Card {index + 1}
          </MDButton>
        ))}
      </Grid>

      {card && (
        <Grid item xs={12} key={card.id}>
          <FormField
            type="text"
            label="Titolo"
            value={card.title}
            onChange={({ target }) => onEditCard(data.activeTab, "title", target.value)}
          />
          <FormField
            type="text"
            label="Percentuale"
            value={card.percentage}
            onChange={({ target }) => onEditCard(data.activeTab, "percentage", target.value)}
          />

          <MDButton variant="gradient" color="error" onClick={() => onDeleteCard(data.activeTab)}>
            Rimuovi
          </MDButton>
        </Grid>
      )}
    </Grid>
  );
};

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "percentages",
  title: "Card Percentuali",
  description: "Crea card con le barre percentuali",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
