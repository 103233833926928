import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";

// ProductsList page components
import { Link, useNavigate } from "react-router-dom";

import Sidenav from "pagensp/gestproduct/productarchive/components/Sidenav/SidenavProduct";

import useHttp from "../../../hooks/use-http";
import dataTableData from "./data/dataTableData";

/* eslint-disable no-shadow */
function ArchiveProductNsp() {
  const history = useNavigate();
  const { sendRequest: sendProdottiRequest, isLoading } = useHttp();

  const { sendRequest: sendPutRequest } = useHttp();
  const editState = (id, stato) => {
    const data = { data: { visible: !stato } };
    sendPutRequest({
      url: `/products/${id}`,
      methodH: "PUT",
      cache: false,
      bodyH: data,
      manageData: ({ data }) => {
        setRows((prev) =>
          prev.map((item) =>
            item.id === data.id
              ? {
                  ...item,
                  ...data,
                  stato: {
                    value: data.visible,
                    action: () => editState(data.id, data.visible),
                  },
                }
              : item
          )
        );
      },
    });
  };

  const [rows, setRows] = useState([]);
  const handleRows = useCallback(
    (tempRows) => {
      const appoggio = tempRows.list.map((row) => ({
        ...row,
        id: row.id,
        status: row.status || "Paid",
        nome: row.name,
        categoria: row.category ? row.category.text : "",
        prezzo: `€ ${row.price}`,
        tipologia: row.type,
        stato: {
          value: row.visible,
          action: () => editState(row.id, row.visible),
        },
        azione: {
          action: () => history(`/gestione/prodotto/${row.id}`),
        },
      }));
      setRows(appoggio);
    },
    [setRows]
  );
  const filterTable = (tipo) => {
    sendProdottiRequest({ url: "/products", manageData: handleRows, cache: false }).then(() => {
      if (tipo !== "") {
        setRows((old) => old.filter((fil) => fil.tipologia === tipo));
      }
    });
  };

  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...rows] });
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...rows] });
  }, [rows]);

  useEffect(() => {
    sendProdottiRequest({ url: "/products", manageData: handleRows, cache: false });
  }, [handleRows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                Elenco prodotti
                <Link to={{ pathname: "/gestione/prodotto" }}>
                  <MDButton variant="gradient" color="info" sx={{ m: 2 }}>
                    <Icon>add</Icon>&nbsp; Nuovo prodotto
                  </MDButton>
                </Link>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={3}>
            <Card>
              <Sidenav action={filterTable} rows={rows} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              {isLoading ? (
                <Grid container p={3} justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <DataTable table={dataTable} canSearch />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ArchiveProductNsp;
