// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function UserInfo({
  name,
  nameHandler,
  surname,
  surnameHandler,
  phone,
  phoneHandler,
  email,
  emailHandler,
  city,
  cityHandler,
  state,
  stateHandler,
  address,
  addressHandler,
  birthPlace,
  birthPlaceHandler,
  birthPlacestate,
  birthPlaceStateHandler,
  dateOfBirth,
  dateOfBirthHandler,
  bio,
  bioHandler,
  type,
  comuniData,
  zipCode,
  zipcodeHandler,
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informazioni Anagrafiche</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField onChange={nameHandler} type="text" label="Nome" value={name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField onChange={surnameHandler} type="text" label="Cognome" value={surname} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField onChange={emailHandler} type="text" label="Email" value={email} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField onChange={phoneHandler} type="text" label="Telefono" value={phone} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* <FormField onChange={cityHandler} type="text" label="Città" value={city} /> */}
              <Autocomplete
                required
                style={{
                  width: "100%",
                }}
                value={city}
                options={comuniData.map((p) => ({
                  label: `${p.nome} (${p.sigla})`, // `${prefix.name} (${prefix.dial_code})`,
                  value: p.codice,
                }))}
                onChange={(event, value) => {
                  cityHandler(
                    comuniData.find((elem) => elem.codice === value.value),
                    value
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Citta di residenza" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField onChange={stateHandler} type="text" label="Provincia" value={state} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={9} sm={9}>
              <FormField onChange={addressHandler} type="text" label="Indirizzo" value={address} />
            </Grid>
            <Grid item xs={3} sm={3}>
              <FormField onChange={zipcodeHandler} type="text" label="Cap" value={zipCode} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={dateOfBirthHandler}
                type="date"
                label="Data di nascita"
                value={dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* <FormField
                onChange={birthPlaceHandler}
                type="text"
                label="Città di nascita"
                value={birthPlace}
              /> */}
              <Autocomplete
                required
                style={{
                  width: "100%",
                }}
                value={birthPlace}
                options={comuniData.map((p) => ({
                  label: `${p.nome} (${p.sigla})`, // `${prefix.name} (${prefix.dial_code})`,
                  value: p.codice,
                }))}
                onChange={(event, value) => {
                  birthPlaceHandler(
                    comuniData.find((elem) => elem.codice === value.value),
                    value
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Citta di residenza" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={birthPlaceStateHandler}
                type="text"
                label="Provincia di nascita"
                value={birthPlacestate}
              />
            </Grid>
          </Grid>
        </MDBox>
        {type === "operator" ? (
          <>
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <MDBox ml={0.5} lineHeight={0} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Bio Consulente
                    </MDTypography>
                  </MDBox>
                  <br />
                  <MDEditor
                    value={bio}
                    onChange={(e) => {
                      bioHandler(e);
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </>
        ) : (
          <></>
        )}
      </MDBox>
    </Card>
  );
}

export default UserInfo;
