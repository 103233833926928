import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

import Autocomplete from "@mui/material/Autocomplete";

import styleModal from "assets/theme/base/modalconfig";

import Grid from "@mui/material/Grid";

function ModalSubCategory({ open, handleClose, handleLoadData, idSubCat }) {
  const [name, setName] = useState("");
  const [idCategory, setIdCategory] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [sigleCategoryData, setSigleCategoryData] = useState({ label: "", value: null });

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();
  const { sendRequest: sendSingleSubCatRequest, isLoading: isSingleCatLoading } = useHttp();

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const idSubHandler = (e) => {
    setIdCategory(e.value);
  };

  const manageSubCategoryData = ({ name: nameF, category: idCategoryF }) => {
    setName(nameF);
    setSigleCategoryData({ value: idCategoryF.id, label: idCategoryF.text });
    setIdCategory(idCategoryF.id);
  };

  useEffect(() => {
    if (open && idSubCat) {
      sendSingleSubCatRequest({
        methodH: "GET",
        url: `/subcategories/${idSubCat}`,
        manageData: (e) => {
          manageSubCategoryData(e.data); // la funzione per la mappatura dei dati
        },
      }).then(() => {
        handleLoadDataCat();
      });
    } else if (open && idSubCat === 0) {
      setName("");
      setIdCategory(0);
      setSigleCategoryData({ value: "", label: "" });
      handleLoadDataCat();
    }
  }, [idSubCat, open]);

  const saveHandler = () => {
    if (open && idSubCat === 0) {
      const data = {
        data: {
          name,
          category: idCategory,
        },
      };
      sendSaveRequest({
        methodH: "POST",
        url: `/subcategories`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    } else if (open && idSubCat) {
      const data = {
        data: {
          name,
          category: idCategory,
        },
      };
      sendSaveRequest({
        methodH: "PUT",
        url: `/subcategories/${idSubCat}`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    }
  };

  const {
    sendRequest: sendCategoryRequest,
    isLoading: isProductsLoading,
    error: productsError,
  } = useHttp();

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadDataCat = () => {
    sendCategoryRequest({
      url: "/categories",
      manageData: (e) => {
        manageCategoryData(e.list); // la funzione per la mappatura dei dati
      },
    });
  };

  // Riassegno le variabile da api
  const manageCategoryData = (list) => {
    const appData = list.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setCategoryData([...appData]);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={styleModal} pt={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <FormField
                type="text"
                label="Nome Sotto Categoria"
                defaultValue=""
                value={name}
                onChange={nameHandler}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Autocomplete
                onChange={(event, value) => {
                  idSubHandler(value);
                }}
                value={sigleCategoryData}
                options={categoryData}
                renderInput={(params) => (
                  <FormField {...params} label="Categoria" InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                Inserisci
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Fade>
    </Modal>
  );
}
export default ModalSubCategory;
