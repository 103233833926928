import styled from "styled-components";

export const Video = styled.iframe`
  border-radius: 40px;
  background: #d9d9d9;
  aspect-ratio: 16 / 9;
  margin: 20px 30px;
`;

export default {};
