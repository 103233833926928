import AuthContext from "pagensp/store/auth-context";
import { useContext, useEffect, useState } from "react";
import ChatIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DocIcon from "@mui/icons-material/StickyNote2Outlined";
import CardChat from "./section/CardChat";

import "./LiveChat.css";

export default function LiveChat({
  products,
  indexChat,
  handleChat,
  index,
  selectedRoom,
  sendMessage,
  handleSelectedRoom,
  room,
  roomCustomer,
  messages,
  openBoy,
  shouldBeOpen,
  sendReadMessage,
}) {
  const [open, setOpen] = useState(shouldBeOpen || false);
  const { isLoggedIn, userData } = useContext(AuthContext);
  const handleChatOpening = () => {
    if (index) {
      if (indexChat === index) handleChat(0);
      else if (indexChat !== 0) {
        handleChat(0);
        setTimeout(() => handleChat(index), 500);
      } else handleChat(index);
    } else setOpen(!open);
  };
  useEffect(() => setOpen(index === indexChat), [indexChat, openBoy]);

  return (
    isLoggedIn && (
      <>
        <button
          type="button"
          className={`btn-liveChat ${products && "products"}`}
          onClick={() => handleChatOpening()}
        >
          {open ? (
            <CloseIcon fontSize="large" />
          ) : (
            <>{products ? <DocIcon fontSize="medium" /> : <ChatIcon fontSize="medium" />}</>
          )}
        </button>
        {open && (
          <CardChat
            {...{
              selectedRoom,
              sendMessage,
              handleSelectedRoom,
              room,
              roomCustomer,
              messages,
              handleChatOpening,
              userData,
              sendReadMessage,
            }}
            open={open}
            products={products}
          />
        )}
      </>
    )
  );
}
