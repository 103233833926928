/* eslint-disable-line import/no-unresolved */
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Pagination, Navigation } from "swiper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import logo1 from "./logos/rds.svg";
import logo2 from "./logos/milanobiz.png";
import logo3 from "./logos/larepubblica.svg";
import logo4 from "./logos/ilsole24ore.svg";

// import "./slider.css";
import "swiper/swiper.min.css";
import { Button, Container, SwiperContainer } from "./style";

const logosList = [
  {
    id: 1,
    image: logo1,
    link: `https://www.rds.it/podcast/lifestyle/lifestyle-18-02-2022-1557/`,
  },
  {
    id: 2,
    image: logo2,
    link: `https://www.milanobiz.it/burocrazia-troppo-complessa-nonsolopatronato/`,
  },
  {
    id: 3,
    image: logo3,
    link: `https://roma.repubblica.it/dossier-adv/eccellenze-lazio/2022/02/07/news/nonsolopatronato_it_per_presentare_i_documenti_basta_un_click-336810766/`,
  },
  {
    id: 4,
    image: logo4,
    link: `https://www.ilsole24ore.com/art/boom-portali-chiedere-bonus-consulenza-online-si-fa-pagare-AEGOKP9?refresh_ce=1`,
  },
];

function Component() {
  return (
    <Container>
      <div className="row">
        <div className="col-12">
          <div className="titolo">
            <h2 className="titolosezione">
              Parlano
              <span className="text-tertiary fw-bold"> di noi</span>
            </h2>
          </div>
        </div>
      </div>
      <SwiperContainer>
        <Button direction="right" className="custom_nextAboutUs">
          <ChevronRightIcon />
        </Button>
        <Button direction="left" className="custom_prevAboutUs">
          <ChevronLeftIcon />
        </Button>

        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
            },
            // medium
            768: {
              slidesPerView: 3,
            },
            // large
            992: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: ".custom_nextAboutUs",
            prevEl: ".custom_prevAboutUs",
          }}
          spaceBetween={60}
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          style={{ height: "60px" }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {logosList.map((item) => (
            <SwiperSlide key={item.id}>
              <a href={item.link} rel="noreferrer" target="_blank">
                <img className="w-100" src={item.image} loading="lazy" alt="logo" />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </Container>
  );
}

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "parlano-di-noi",
  title: "Parlano di noi",
  description: "Slides con loghi",
  version: 1,
};
