import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { Dropzone, FileItem } from "@dropzone-ui/react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// EditProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";
import { Link } from "react-router-dom";
import Preloader from "components/Preloader/Preloader";

function UserDxSidebar({
  password,
  passwordHandler,
  saveHandler,
  removeUser,
  type,
  typeHandler,
  active,
  activeHandler,
  // userPrivate,
  userPrivateHandler,
  prjectManagerOptions,
  // prjectManager,
  prjectManagerValue,
  editProjectManager,
  // departments,
  departmentsOptions,
  departmentsValue,
  editDepartments,
  operatorMaxSubscriptions,
  operatorMaxSubscriptionsHandler,
  updateFiles,
  file,
  img,
  imgHandlerRemove,
  typeUser,
  isLoading,
}) {
  const [mainImmage, setMainImmage] = useState();

  // useEffect(() => {
  //   setMainImmage(images.find((data) => data.main === true));
  // }, [images]);

  function SwitchCaseType(dataApp) {
    switch (dataApp) {
      case "user":
        return "Cliente";
      case "project_manager":
        return "Project Manager";
      case "operator":
        return "Operatore";
      case "admin":
        return "Admin";
      default:
        return "";
    }
  }

  return (
    <Card>
      <MDBox p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                <Preloader isLoading={isLoading}>save</Preloader>
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex">
              <MDButton variant="gradient" color="light" size="large">
                {typeUser !== "admin" ? (
                  <>
                    {" "}
                    <Link to={{ pathname: "/dashboard" }} color="light">
                      Chiudi
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={{ pathname: "/gestione/utenti" }} color="light">
                      Chiudi
                    </Link>
                  </>
                )}
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex">
              <MDButton variant="gradient" color="error" size="large" onClick={removeUser}>
                <DeleteIcon />
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="h5" fontWeight="bold">
          Configurazione Utente
        </MDTypography>
        <MDBox mt={1}>
          {typeUser === "admin" && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  onChange={passwordHandler}
                  type="password"
                  label="Cambia password"
                  defaultValue=""
                  value={password}
                />
              </Grid>
            </Grid>
          )}
        </MDBox>

        <MDBox mt={1}>
          {typeUser === "admin" && (
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Tipo utente
                </MDTypography>
              </MDBox>
              <Autocomplete
                value={{ label: SwitchCaseType(type), value: type }}
                options={[
                  { label: "Cliente", value: "user" },
                  { label: "Operatore", value: "operator" },
                  { label: "Project manager", value: "project_manager" },
                  { label: "Admin", value: "admin" },
                ]}
                onChange={(event, data) => {
                  typeHandler(data);
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          )}
          <Grid container spacing={2}>
            {type === "operator" && typeUser === "admin" ? (
              <>
                <Grid item xs={12}>
                  <MDBox my={2} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Project manager
                    </MDTypography>
                  </MDBox>
                  <Autocomplete
                    value={
                      prjectManagerValue && prjectManagerValue.value
                        ? prjectManagerValue
                        : { label: "", value: "" }
                    }
                    options={prjectManagerOptions}
                    onChange={(event, data) => {
                      editProjectManager(data);
                    }}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDBox my={2} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Ufficio
                    </MDTypography>
                  </MDBox>
                  <Autocomplete
                    value={
                      departmentsValue && departmentsValue.value
                        ? departmentsValue
                        : { label: "", value: "" }
                    }
                    options={departmentsOptions}
                    onChange={(event, data) => {
                      editDepartments(data);
                    }}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDBox my={2} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Gestione Abbonamenti
                    </MDTypography>
                  </MDBox>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      onChange={operatorMaxSubscriptionsHandler}
                      type="text"
                      label="Inserire num. max"
                      value={operatorMaxSubscriptions}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {type === "user" ? (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={active} onChange={userPrivateHandler} />}
                    label="Cliente Privato"
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              {typeUser === "admin" && (
                <FormControlLabel
                  control={<Switch checked={active} onChange={activeHandler} />}
                  label="Utente attivo"
                />
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Immagine Principale
                </MDTypography>
                <br />
              </MDBox>
              {!img ? (
                <>
                  <br />
                  <MDTypography variant="button">
                    Utilizza una immagine non piu grande di 2Mb
                  </MDTypography>
                  <Dropzone
                    onChange={updateFiles}
                    value={file}
                    maxFiles={1}
                    maxFileSize={2998000}
                    accept=".jpg,.jpeg,.png,image/*"
                  >
                    {file.map((fileG) => (
                      <FileItem {...fileG} preview />
                    ))}
                  </Dropzone>
                </>
              ) : (
                <>
                  <GestUploadImg UploadImage={img} imgHandlerRemove={imgHandlerRemove} />
                </>
              )}
            </Grid>
            {type === "user" ? (
              <>
                <Grid item xs={12}>
                  <MDBox my={2} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Documentazione utente
                    </MDTypography>
                  </MDBox>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {/* <Grid container spacing={2}>
              {attachments.map((attApp, index) => (
                <>
                  <Grid item xs={12} lg={9}>
                    {attApp.id !== undefined ? `Documento ${index + 1}` : attApp.file.name}
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      size="small"
                      onClick={() => {
                        attachmentsHandlerRemove(attApp.id);
                      }}
                    >
                      <DeleteIcon />
                    </MDButton>
                  </Grid>
                </>
              ))}
            </Grid> */}
            {/* <br />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
                <FormField type="file" size="medium" onChange={updateAttachments} />
              </Grid>
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default UserDxSidebar;
