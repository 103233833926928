import Switch from "@mui/material/Switch";

// ProductsList page components
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import MDBadge from "components/MDBadge";

// Images
import EditIcon from "@mui/icons-material/Edit";

import MDButton from "components/MDButton";

export default {
  columns: [
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;

        if (value === "paid") {
          status = <StatusCell icon="done" color="success" status="Payed" />;
        } else if (value === "refunded") {
          status = <StatusCell icon="replay" color="dark" status="Refunded" />;
        } else {
          status = <StatusCell icon="close" color="error" status="Canceled" />;
        }

        return status;
      },
    },
    { Header: "Nome", accessor: "nome" },
    { Header: "Cognome", accessor: "cognome" },
    { Header: "Email", accessor: "email" },
    { Header: "Tipo utente", accessor: "type" },
    {
      Header: "azione",
      accessor: "azione",
      Cell: ({ value }) => (
        <>
          <MDButton
            onClick={() => {
              value.action();
            }}
            color="error"
          >
            <EditIcon />
          </MDButton>
        </>
      ),
    },
  ],
};
