/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
import AnalyticsNSP from "pagensp/Dashboard/analytics";

import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// NSP Menu admin

import EditProductNsp from "pagensp/gestproduct/productdetail/EditProductNsp";
import ArchiveProductNsp from "pagensp/gestproduct/productarchive/ArchiveProductNsp";

import TagConfigNsp from "pagensp/gestconfig/tag/TagConfigNsp";
import CategoryConfigNsp from "pagensp/gestconfig/category/CategoryConfigNsp";
import SubcategoryConfigNsp from "pagensp/gestconfig/subcategory/SubcategoryConfigNsp";
import PersonalFieldsConfigNsp from "pagensp/gestconfig/personalfields/PersonalFieldsConfigNsp";
import PersonaldocumentationConfigNsp from "pagensp/gestconfig/personaldocumentation/PersonaldocumentationConfigNsp";
import FaqConfigNsp from "pagensp/gestconfig/faq/FaqConfigNsp";

import LoginPage from "pagensp/GestAccess/login/GestLogin";
import PasswordReset from "pagensp/GestAccess/resetpwd/GestResetPwd";
import RegistrationPage from "pagensp/GestAccess/register/GestRegister";
import ArchiveUserNsp from "pagensp/GestUser/usersarchive/ArchiveUserNsp";
import EditUserNsp from "pagensp/GestUser/userdetail/EditUsreNSP";
import ArchiveDepartmentsNsp from "pagensp/GestDepartments/departmentsarchive/ArchiveDepartmentsNsp";
import EditDepartmentNSP from "pagensp/GestDepartments/departmentdetail/EditDepartmentNSP";
import CategoryConfigDocNsp from "pagensp/gestconfig/documentcategory/CategoryConfigDocNsp";
import PraticesConfigNsp from "pagensp/GestPractices/PraticesArchive/PraticesConfigNsp";
import PraticesDetail from "pagensp/GestPractices/PraticesDetail";
import MsgNsp from "pagensp/GestMsg/PraticesArchive/MsgNsp";
import MsgNspArchiviati from "pagensp/GestMsg/PraticesArchive/MsgNspArchiviati";

import CategoryBlogNsp from "pagensp/GestBlog/category/CategoryBlogNsp";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

// Test
import TestPage from "pagensp/testPage/TestPage";

// Blog
import ArticleBlogNsp from "pagensp/GestBlog/article/ArticleBlogNsp";
import ArticleBlogDetailNsp from "pagensp/GestBlog/article/ArticleBlogDetailNsp";
import AdsBlogNsp from "pagensp/GestBlog/ads/AdsBlogNsp";

// Landing
import PageLandingNsp from "pagensp/GestLandings/pages/PageLandingNsp";
import PageLandingDetailNsp from "pagensp/GestLandings/pages/PageLandingDetailNsp";

const routes = [
  {
    name: "Profilo",
    key: "profilo",
    route: "/gestione/profilo",
    isType: ["operator"],
    component: <EditUserNsp />,
    type: "collapse",
    icon: <Icon>person</Icon>,
    collapse: [
      {
        name: "Gestisci Profilo",
        key: "profilo",
        route: "/gestione/profilo",
        isType: ["operator"],
        component: <EditUserNsp />,
      },
    ],
  },

  {
    name: "Dettaglio Articolo",
    route: "/blog/articoli/:id",
    key: "blog-articoli",
    component: <ArticleBlogDetailNsp />,
  },

  {
    name: "Dettaglio Landing",
    route: "/landings/pagine/:id",
    key: "landing-pagine",
    component: <PageLandingDetailNsp />,
  },

  {
    name: "Dettaglio Partica",
    route: "/dettagliopratica/:id",
    key: "dettaglio-pratica",
    component: <PraticesDetail />,
  },
  {
    name: "Pratiche corso utente",
    route: "/praticheattive/:id/:nome",
    key: "pratiche-attive",
    component: <PraticesConfigNsp tipo="corso" />,
  },
  {
    name: "Pratiche sospese utente",
    route: "/pratichesospese/:id/:nome",
    key: "pratiche-sospese",
    component: <PraticesConfigNsp tipo="sospeso" />,
  },
  {
    name: "Pratiche chiuse utente",
    route: "/pratichechiuse/:id/:nome",
    key: "pratiche-chiuse",
    component: <PraticesConfigNsp tipo="complete" />,
  },
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      {
        name: "Test alex",
        key: "logout",
        route: "/alex/analytics",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <AnalyticsNSP />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Profile",
        isType: ["admin", "Operator"],
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        isType: ["admin", "Operator"],
        key: "users",
        collapse: [
          {
            name: "New User",
            isType: ["admin", "Operator"],
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
        ],
      },
      {
        name: "Projects",
        isType: ["admin", "Operator"],
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            isType: ["admin", "Operator"],
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
        ],
      },
      {
        name: "Pricing Page",
        isType: ["admin", "Operator"],
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Docs", key: "title-docs" },
  {
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
          },
          {
            name: "License",
            key: "license",
            href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Components",
    key: "components",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
      },
    ],
  },
  {
    type: "collapse",
    name: "Change Log",
    key: "changelog",
    href: "https://github.com/creativetimofficial/ct-material-dashboard/-pro-material-ui/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Gestione",
    isType: ["admin", "operator"],
    key: "gestione",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Prodotti",
        isType: ["admin"],
        key: "gestioneprodotti",
        collapse: [
          {
            name: "Elenco Prodotti",
            key: "prodottie",
            isType: ["admin", "operator"],
            route: "/gestione/prodottie",
            component: <ArchiveProductNsp />,
          },
          {
            name: "Singolo Prodotto",
            key: "Singolo prodotto",
            route: "/gestione/prodotto/:id",
            component: <EditProductNsp />,
          },
          {
            name: "Nuovo Prodotto",
            key: "Nuovo prodotto",
            isType: ["admin"],
            route: "/gestione/prodotto",
            component: <EditProductNsp />,
          },
        ],
      },
      {
        name: "Utenti",
        isType: ["admin", "operator"],
        key: "gestioneutenti",
        collapse: [
          {
            name: "Elenco Utenti",
            key: "prodottie",
            isType: ["admin", "operator"],
            route: "/gestione/utenti/",
            component: <ArchiveUserNsp />,
          },
          {
            name: "Singolo utente",
            key: "utenti",
            route: "/gestione/utente/:id",
            component: <EditUserNsp />,
          },
          {
            name: "Nuovo utente",
            key: "utenti",
            isType: ["admin, operator"],
            route: "/gestione/utente/",
            component: <EditUserNsp />,
          },
        ],
      },
      {
        name: "Uffici",
        isType: ["admin"],
        key: "gestioneuffici",
        collapse: [
          {
            name: "Elenco Uffici",
            key: "prodottie",
            isType: ["admin", "operator"],
            route: "/gestione/uffici/",
            component: <ArchiveDepartmentsNsp />,
          },
          {
            name: "Singolo ufficio",
            key: "utenti",
            route: "/gestione/ufficio/:id",
            component: <EditDepartmentNSP />,
          },
          {
            name: "Nuovo ufficio",
            key: "utenti",
            isType: ["admin", "operator"],
            route: "/gestione/ufficio/",
            component: <EditDepartmentNSP />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Configurazioni",
    isType: ["admin"],
    key: "configurazioni",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Tag",
        isType: ["admin", "operator"],
        key: "tag",
        route: "/config/tag",
        component: <TagConfigNsp />,
      },
      {
        name: "Categorie",
        isType: ["admin", "operator"],
        key: "categorie",
        route: "/config/categorie",
        component: <CategoryConfigNsp />,
      },
      {
        name: "Sotto categorie",
        isType: ["admin", "operator"],
        key: "sottocategorie",
        route: "/config/sottocategorie",
        component: <SubcategoryConfigNsp />,
      },
      {
        name: "Campi anagrafici",
        isType: ["admin", "Operator"],
        key: "campianagrafici",
        route: "/config/campianagrafici",
        component: <PersonalFieldsConfigNsp />,
      },
      {
        name: "Categorie Documenti",
        isType: ["admin", "Operator"],
        key: "categoriedocumenti",
        route: "/config/categoriedocumenti",
        component: <CategoryConfigDocNsp />,
      },
      {
        name: "Documenti richiedibili",
        isType: ["admin", "Operator"],
        key: "documentirichiedibili",
        route: "/config/documentirichiedibili",
        component: <PersonaldocumentationConfigNsp />,
      },
      {
        name: "Faq",
        isType: ["admin", "Operator"],
        key: "faq",
        route: "/config/faq",
        component: <FaqConfigNsp />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Accesso",
    key: "accesso",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Login",
        key: "login",
        route: "/login",
        component: <LoginPage />,
      },
      {
        name: "ResetPwd",
        key: "resetpwd",
        route: "/restepwd",
        component: <PasswordReset />,
      },
      {
        name: "Register",
        key: "register",
        route: "/register",
        component: <RegistrationPage />,
      },
      { name: "TestP", key: "testp", route: "/testp", component: <TestPage /> },
    ],
  },
  {
    type: "collapse",
    name: "Pratiche",
    key: "pratiche",
    isType: ["admin", "operator", "project_manager"],
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Pratiche in sospeso",
        key: "pratichesospeso",
        route: "/pratichesospese",
        isType: ["admin"],
        component: <PraticesConfigNsp tipo="sospeso" />,
      },
      {
        name: "Pratiche in corso",
        key: "praticheincorso",
        route: "/praticheattive",
        isType: ["admin", "operator", "project_manager"],
        component: <PraticesConfigNsp tipo="corso" />,
      },
      {
        name: "Pratiche completate",
        key: "pratichechiuse",
        route: "/pratichechiuse",
        isType: ["admin", "operator", "project_manager"],
        component: <PraticesConfigNsp tipo="complete" />,
      },
      {
        name: "Pratiche eliminate",
        key: "praticheeliminate",
        route: "/praticheeliminate",
        isType: ["admin", "operator", "project_manager"],
        component: <PraticesConfigNsp tipo="praticheeliminate" />,
      },
      {
        name: "Le mie pratiche",
        key: "lemiepratiche",
        route: "/lemiepratiche",
        isType: ["admin", "operator", "project_manager"],
        component: <PraticesConfigNsp tipo="miepratiche" />,
      },
      // {
      //   name: "Chat Aperte",
      //   key: "msgleggere",
      //   route: "/pratichemsgdaleggere",
      //   isType: ["admin"],
      //   component: <MsgNsp letti="si" />,
      // },
      // {
      //   name: "Chat Archiviate",
      //   key: "msgletti",
      //   route: "/pratichemsgletti",
      //   isType: ["admin"],
      //   component: <MsgNspArchiviati letti="no" />,
      // },
      {
        name: "Notifiche",
        key: "notifiche",
        route: "/notifiche",
        isType: ["admin", "operator"],
        component: <PraticesConfigNsp tipo="notifiche" />,
      },
      { name: "TestP", key: "testp", route: "/testp", component: <TestPage /> },
    ],
  },
  {
    type: "collapse",
    name: "Rimborsi",
    key: "rimborsi",
    isType: ["admin"],
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Richieste Rimborso",
        key: "richiesterimborso",
        route: "/richiesterimborso",
        isType: ["admin"],
        component: <PraticesConfigNsp tipo="richiestarimborso" />,
      },
      {
        name: "Rimborsi approvati",
        key: "richiesteapprovate",
        route: "/richiesteapprovate",
        isType: ["admin"],
        component: <PraticesConfigNsp tipo="richiestaapprovata" />,
      },
      {
        name: "Rimborsi rifiutati",
        key: "richiesterifiutate",
        route: "/richiesterifiutate",
        isType: ["admin"],
        component: <PraticesConfigNsp tipo="richiestarifiutata" />,
      },
      { name: "TestP", key: "testp", route: "/testp", component: <TestPage /> },
    ],
  },
  {
    type: "collapse",
    name: "Blog",
    isType: ["admin", "operator"],
    key: "blog",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Categorie",
        isType: ["admin", "operator"],
        key: "categorie",
        route: "/blog/categorie",
        component: <CategoryBlogNsp />,
      },
      {
        name: "Articoli",
        isType: ["admin", "operator"],
        key: "articoli",
        route: "/blog/articoli",
        component: <ArticleBlogNsp />,
      },
      {
        name: "Banner Ads",
        isType: ["admin", "operator"],
        key: "ads",
        route: "/blog/ads",
        component: <AdsBlogNsp />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Landings",
    isType: ["admin", "operator"],
    key: "landings",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Pagine",
        isType: ["admin", "operator"],
        key: "pagine",
        route: "/landings/pagine",
        component: <PageLandingNsp />,
      },
    ],
  },
];

export default routes;
