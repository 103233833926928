/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function SelectProduct({
  tabValue,
  tabHandler,
  allPackageOption,
  addPackage,
  products,
  editPackageValue,
}) {
  const [tabValueGeneral, setTabValueGeneral] = useState("single");
  useEffect(() => {
    if (tabValue !== "package") {
      setTabValueGeneral("single");
    } else {
      setTabValueGeneral("package");
    }
  }, [tabValue]);
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Tipo di prodotto</MDTypography>
        <MDTypography variant="button">
          Prima di iniziare a creare il tuo prodotto scegli se è un prodotto singolo o un insieme di
          prodotti
        </MDTypography>

        <MDBox mt={1}>
          <Grid item xs={12}>
            <MDBox mx="auto" mt={1}>
              <AppBar position="static">
                <Tabs value={tabValueGeneral} onChange={(e) => tabHandler(e.target.innerText)}>
                  <Tab
                    id="single"
                    value="single"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        single
                      </MDBox>
                    }
                  />
                  <Tab
                    id="package"
                    value="package"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        package
                      </MDBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </MDBox>
          </Grid>
          {tabValue !== "package" ? (
            <>
              <Grid item xs={12} pt={2}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={tabValue}
                  onChange={(e) => tabHandler(e.target.value)}
                >
                  <FormControlLabel value="paperwork" control={<Radio />} label="Pratica" />
                  <FormControlLabel value="consulting" control={<Radio />} label="Consulenza" />
                  <FormControlLabel value="subscription" control={<Radio />} label="Abbonamento" />
                </RadioGroup>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2} pt={2}>
                {products.map((doc, index) => (
                  <Grid item xs={9} lg={8}>
                    <Autocomplete
                      defaultValue=""
                      value={
                        doc.product.text
                          ? { label: doc.product.text, value: doc.product.id }
                          : doc.value
                      }
                      options={allPackageOption}
                      onChange={(event, data) => {
                        if (data) {
                          editPackageValue("product", data.value, index);
                        } else {
                          editPackageValue("fileds", "del", index);
                        }
                      }}
                      renderInput={(params) => <MDInput {...params} variant="standard" />}
                    />
                  </Grid>
                ))}
                <br />
                <Grid item xs={9} lg={8} pt={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      addPackage({ product: "" });
                    }}
                  >
                    + Altro Prodotto
                  </MDButton>
                </Grid>
              </Grid>
            </>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default SelectProduct;
