const GhostButton = ({ children, onClick, style, classNamemia = "" }) => {
  const a = "";
  return (
    <button
      type="button"
      style={{ border: "none", background: "none", ...style, margin: "none", padding: "none" }}
      className={`ghost-button ${classNamemia}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default GhostButton;
