import { PreviewOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Autocomplete,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Select from "components/Select";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import moment from "moment";
import { useMemo, useState } from "react";
import WizardSimulationBonus from "../WizardSimulation/WizardSimulationBonus";

/* eslint-disable no-bitwise */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable */

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
  );
}

function generateWizard(items) {
  if (!items) return { wizard: [], bonuses: {} };
  const questionMap = new Map();
  const uuidMap = new Map();

  items.forEach((item) => {
    item.wizard.forEach((question) => {
      const key = question.question;
      if (questionMap.has(key)) {
        const existingQuestion = questionMap.get(key);
        existingQuestion.requirements = {
          ...existingQuestion.requirements,
          ...question.requirements,
        };
        uuidMap.set(question.uuid, existingQuestion.uuid);
      } else {
        questionMap.set(key, question);
      }
    });
  });

  const mergedWizard = Array.from(questionMap.values());

  function updateRequirements(requirements) {
    const updatedRequirements = {};
    for (const uuid in requirements) {
      const newUuid = uuidMap.get(uuid) || uuid;
      updatedRequirements[newUuid] = requirements[uuid];
    }
    return updatedRequirements;
  }

  mergedWizard.forEach((question) => {
    question.requirements = updateRequirements(question.requirements);
  });

  items.forEach((item) => {
    for (const bonusKey in item.bonuses) {
      const bonus = item.bonuses[bonusKey];
      bonus.requirements.forEach((req) => {
        const newUuid = uuidMap.get(req.uuid) || req.uuid;
        req.uuid = newUuid;
      });
    }
  });

  function canAskQuestion(question, answeredQuestions) {
    for (const reqUuid in question.requirements) {
      if (!answeredQuestions.has(reqUuid)) {
        return false;
      }
    }
    return true;
  }

  const sortedWizard = [];
  const answeredQuestions = new Set();
  const unresolvedQuestions = new Set(mergedWizard.map((q) => q.uuid));

  while (sortedWizard.length < mergedWizard.length) {
    const initialLength = sortedWizard.length;

    mergedWizard.forEach((question) => {
      if (!answeredQuestions.has(question.uuid) && canAskQuestion(question, answeredQuestions)) {
        sortedWizard.push(question);
        answeredQuestions.add(question.uuid);
        unresolvedQuestions.delete(question.uuid);
      }
    });

    if (sortedWizard.length === initialLength) {
      return {
        status: "error",
        message:
          "Dipendenza ciclica rilevata, queste domande hanno requisiti non risolvibili, spesso accade perchè viene messo come requisito aver risposto ad una domanda che ha a sua volta un requisito sulla domanda corrente, verificare e sistemare le domande indicate, poi riprovare l'anteprima",
        content: Array.from(unresolvedQuestions).map((uuid) => {
          const question = mergedWizard.find((q) => q.uuid === uuid);
          return {
            question: question.question,
            requirements: question.requirements,
            unmetRequirements: Object.keys(question.requirements).filter(
              (reqUuid) => !answeredQuestions.has(reqUuid)
            ),
          };
        }),
      };
    }
  }

  const finalBonuses = {};
  items.forEach((item) => {
    Object.keys(item.bonuses).forEach((bonusKey) => {
      if (!finalBonuses[bonusKey]) {
        finalBonuses[bonusKey] = item.bonuses[bonusKey];
      }
    });
  });

  return {
    status: "success",
    wizard: sortedWizard,
    bonuses: finalBonuses,
  };
}

function AiDataScopriBonus({ items, setItems, onProcessAi }) {
  const [activeContract, setActiveContract] = useState(0);
  const [loading, setLoading] = useState(false);
  const item = items?.length > 0 ? items[activeContract] : null;
  const [showWizard, setShowWizard] = useState(false);
  const [showWizardSimulation, setShowWizardSimulation] = useState(false);
  const completeWizard = useMemo(
    () =>
      showWizard || showWizardSimulation
        ? generateWizard(items?.filter((e) => e.active)?.map((e) => e?.data))
        : {},
    [items, showWizard, showWizardSimulation]
  );
  const onMultipleUpload = (e) => {
    [...e.target.files].forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (loadFile) => {
        setItems((prev) => [
          ...prev,
          {
            name: file.name,
            description: "",
            active: false,
            document: {
              name: file.name,
              data: loadFile.target.result.replace("data:application/pdf;base64,", ""),
            },
            data: { wizard: [], bonuses: {} },
          },
        ]);
      };
    });
  };
  const onChangeSelect = (select) => setActiveContract(select?.value);
  const updateItem = (target, index) =>
    setItems((prev) =>
      prev.map((e, i) => (i === index ? { ...e, [target.name]: target.value } : e))
    );
  const onAddQuestion = (index) => {
    setItems((prev) =>
      prev.map((iItem, iIndex) =>
        iIndex === index
          ? {
              ...iItem,
              data: {
                ...iItem?.data,
                wizard: [
                  ...iItem?.data?.wizard,
                  {
                    uuid: uuidv4(),
                    question: "",
                    answers: ["Sì", "No", "Non so"],
                    requirements: {},
                  },
                ],
              },
            }
          : iItem
      )
    );
  };
  const updateDataItem = ({ target }, property, index, indexData) =>
    setItems((prev) =>
      prev.map((iItem, iIndex) =>
        iIndex === index
          ? {
              ...iItem,
              data: {
                ...iItem?.data,
                [property]:
                  property === "wizard"
                    ? [
                        ...iItem?.data?.[property]?.map((dItem, dIndex) =>
                          dIndex === indexData ? { ...dItem, [target.name]: target.value } : dItem
                        ),
                      ]
                    : {
                        ...iItem?.data?.[property],
                        [indexData]: {
                          ...iItem?.data?.[property][indexData],
                          [target.name]: target.value,
                        },
                      },
              },
            }
          : iItem
      )
    );

  const removeBonusItem = (index, indexData) =>
    setItems((prev) =>
      prev.map((iItem, iIndex) =>
        iIndex === index
          ? {
              ...iItem,
              data: {
                ...iItem?.data,
                bonuses: (() => {
                  const { [indexData]: _, ...newBonuses } = iItem?.data?.bonuses || {};
                  return newBonuses;
                })(),
              },
            }
          : iItem
      )
    );

  const onRemoveQuestion = (uuid, index) =>
    setItems((prev) =>
      prev.map((iItem, iIndex) =>
        iIndex === index
          ? {
              ...iItem,
              data: {
                wizard: [
                  ...iItem?.data?.wizard
                    ?.filter((dItem) => dItem?.uuid !== uuid)
                    ?.map((dItem) => ({
                      ...dItem,
                      requirements: {
                        ...Object.fromEntries(
                          Object.entries(dItem?.requirements)?.filter(([dUuid]) => dUuid !== uuid)
                        ),
                      },
                    })),
                ],
                bonuses: {
                  ...Object.fromEntries(
                    Object.entries(iItem?.data?.bonuses)?.map(([key, bonus]) => [
                      key,
                      { ...bonus, requirements: bonus.requirements.filter((e) => e.uuid !== uuid) },
                    ])
                  ),
                },
              },
            }
          : iItem
      )
    );

  const onProcessAiCallback = async () => {
    setLoading(true);
    try {
      await onProcessAi();
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  };

  return (
    <>
      <Card>
        <MDBox p={3}>
          <MDTypography component="label" variant="h4" fontWeight="bold">
            AI Documenti Bonus
          </MDTypography>
          <Grid container align="center">
            <Grid item xs={12} sm={9}>
              <Select
                label="Bonus inseriti"
                options={items?.map((e, value) => ({
                  label: `${e?.name} [${e?.active ? "Pubblico" : "Privato"}]`,
                  value,
                }))}
                value={activeContract}
                onChange={(e) => onChangeSelect(e)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDButton
                variant="gradient"
                color="error"
                style={{ marginTop: 25 }}
                onClick={() => {
                  if (
                    !loading &&
                    !confirm(
                      "Sei sicuro di voler lanciare l'elaborazione AI? Questo comporterà un elaborazione di tutti i documenti non ancora elaborati, richiamando api che avranno dei costi, prosegui solo se necessario"
                    )
                  )
                    return;
                  onProcessAiCallback();
                }}
              >
                {loading ? <CircularProgress /> : <>Avvia Elaborazione AI</>}
              </MDButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MDTypography component="label" variant="h6" fontWeight="bold">
              Carica più documenti in una volta (una volta selezionati li troverai nel menu a
              tendina qui sopra)
            </MDTypography>
            <div>
              <input type="file" multiple onChange={onMultipleUpload} />
            </div>
          </Grid>
        </MDBox>
      </Card>

      {item && (
        <>
          <Card style={{ marginTop: 25 }}>
            <MDBox p={3}>
              <MDTypography component="label" variant="h4" fontWeight="bold">
                Elaborazione AI
              </MDTypography>
              <div key={activeContract}>
                <Grid container spacing={2}>
                  {item?.document && (
                    <>
                      <Grid item xs={12} lg={9}>
                        {item?.document?.name || "Documento caricato"}
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        {typeof item?.document === "string" && (
                          <MDButton
                            variant="gradient"
                            color="info"
                            style={{ marginRight: 10 }}
                            onClick={() => window.open(item?.document, "_blank")}
                          >
                            Mostra
                          </MDButton>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Grid container gap={1} wrap>
                      <Chip
                        label="File caricato su AI"
                        color={item?.ai_file_id ? "success" : "warning"}
                      />
                      <Chip
                        label="File vettorizzato"
                        color={item?.ai_vector_store_id ? "success" : "warning"}
                      />
                      <Chip
                        label="Assistant configurato"
                        color={item?.ai_assistant_id ? "success" : "warning"}
                      />
                      <Chip
                        label="Thread creato"
                        color={item?.ai_thread_id ? "success" : "warning"}
                      />
                      <Chip
                        label="Richiesta avviata"
                        color={item?.ai_run_id ? "success" : "warning"}
                      />
                      <Chip
                        label="Richiesta processata"
                        color={item?.processed_at ? "success" : "warning"}
                      />
                      <Divider />
                      {item?.assistant_created_at && (
                        <Grid item xs={12}>
                          <p>
                            <strong>
                              Assistente creato il:{" "}
                              {moment(item?.assistant_created_at).format("DD/MM/YYYY HH:mm")}
                            </strong>
                          </p>
                          {!item?.run_at && (
                            <p>
                              Il thread verrà creato lanciando l&apos;Elaborazione AI 5 Minuti dopo,
                              alle{" "}
                              <Chip
                                label={moment(item?.assistant_created_at)
                                  .add(5, "minutes")
                                  .format("HH:mm")}
                              />
                            </p>
                          )}
                          <Divider />
                        </Grid>
                      )}
                      {item?.run_at && (
                        <Grid item xs={12}>
                          <p>
                            <strong>
                              Richiesta avviata il:{" "}
                              {moment(item?.run_at).format("DD/MM/YYYY HH:mm")}
                            </strong>
                          </p>
                          {!item?.processed_at && (
                            <p>
                              Il processo riceverà la risposta lanciando l&apos;Elaborazione AI 5
                              Minuti dopo, alle{" "}
                              <Chip
                                label={moment(item?.run_at).add(5, "minutes").format("HH:mm")}
                              />
                            </p>
                          )}
                          <Divider />
                        </Grid>
                      )}
                      {item?.processed_at && (
                        <Grid item xs={12}>
                          <strong>
                            Richiesta processata il:{" "}
                            {moment(item?.processed_at).format("DD/MM/YYYY HH:mm")}
                          </strong>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <FormField
                      onChange={(e) => updateItem(e?.target, activeContract)}
                      type="text"
                      label="Rinomina"
                      defaultValue=""
                      name="name"
                      value={item.name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormControlLabel
                      control={<Switch checked={item?.active} />}
                      label="Pubblico"
                      onChange={() =>
                        updateItem({ name: "active", value: !item?.active }, activeContract)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      style={{ marginRight: 15 }}
                      onClick={() => setShowWizard(true)}
                    >
                      <PreviewOutlined /> Anteprima Wizard
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="info"
                      style={{ marginRight: 15 }}
                      onClick={() => setShowWizardSimulation(true)}
                    >
                      <PreviewOutlined /> Simulazione Wizard
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="info"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        if (
                          !confirm(
                            "Sei sicuro di voler forzare nuovamente la richiesta all'AI? " +
                              "I tuoi elementi verranno sostituiti con quelli calcolati dall'AI, " +
                              "vuoi procedere?"
                          )
                        ) {
                          return;
                        }
                        updateItem({ name: "active", value: false }, activeContract);
                        updateItem({ name: "ai_thread_id", value: null }, activeContract);
                        updateItem({ name: "ai_run_id", value: null }, activeContract);
                        updateItem({ name: "run_at", value: null }, activeContract);
                        updateItem({ name: "processed_at", value: null }, activeContract);
                        updateItem({ name: "data", value: { items: [] } }, activeContract);
                      }}
                    >
                      <CancelPresentationIcon /> Forza richiesta AI
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="error"
                      onClick={() => {
                        if (!confirm("Sei sicuro di voler eliminare questo bonus?")) return;
                        setItems((prev) => prev.filter((e, i) => i !== activeContract));
                        setActiveContract(0);
                      }}
                    >
                      <DeleteIcon /> Rimuovi bonus
                    </MDButton>
                  </Grid>
                </Grid>
              </div>
            </MDBox>
          </Card>

          <Divider />

          <Card style={{ marginTop: 25 }}>
            <MDBox p={3}>
              <MDTypography component="label" variant="h4" fontWeight="bold">
                Bonus
              </MDTypography>
              {Object.entries(item?.data?.bonuses || {})?.map(([key, itemData], indexData) => (
                <Grid
                  item
                  xs={12}
                  p={2}
                  key={key}
                  style={indexData % 2 === 1 ? { background: "#e8e8e8" } : {}}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormField
                        onChange={(e) => updateDataItem(e, "bonuses", activeContract, key)}
                        type="text"
                        label="Nome"
                        defaultValue=""
                        name="name"
                        value={itemData.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox ml={0.5} lineHeight={0} display="inline-block">
                        <MDTypography component="label" variant="h6">
                          Descrizione
                        </MDTypography>
                      </MDBox>
                      <br />
                      <MDEditor
                        value={itemData.description}
                        onChange={(value) =>
                          updateDataItem(
                            { target: { name: "description", value } },
                            "bonuses",
                            activeContract,
                            key
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox display="inline-block" sx={{ color: "green !important" }}>
                        Devi aver risposto SI
                      </MDBox>
                      <Autocomplete
                        multiple
                        value={itemData?.requirements
                          ?.filter((e) => e?.answer?.toLowerCase() === "sì")
                          ?.map((el) => ({
                            label: item?.data?.wizard?.find((e) => e.uuid === el.uuid)?.question,
                            value: el.uuid,
                          }))}
                        options={item?.data?.wizard
                          ?.filter(
                            (e) =>
                              !itemData?.requirements?.find(
                                (ee) => ee.uuid === e.uuid && ee?.answer?.toLowerCase() === "sì"
                              )
                          )
                          ?.map((e) => ({
                            label: e.question,
                            value: e.uuid,
                          }))}
                        onChange={(event, value) => {
                          updateDataItem(
                            {
                              target: {
                                name: "requirements",
                                value: [
                                  ...value.map((e) => ({
                                    answer: "Sì",
                                    uuid: e.value,
                                  })),
                                  ...itemData?.requirements?.filter(
                                    (e) => e?.answer?.toLowerCase() === "no"
                                  ),
                                ],
                              },
                            },
                            "bonuses",
                            activeContract,
                            key
                          );
                        }}
                        renderInput={(params) => <MDInput {...params} variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox display="inline-block" sx={{ color: "red !important" }}>
                        Devi aver risposto NO
                      </MDBox>
                      <Autocomplete
                        multiple
                        value={itemData?.requirements
                          ?.filter((e) => e?.answer?.toLowerCase() === "no")
                          ?.map((el) => ({
                            label: item?.data?.wizard?.find((e) => e.uuid === el.uuid)?.question,
                            value: el.uuid,
                          }))}
                        options={item?.data?.wizard
                          ?.filter(
                            (e) =>
                              !itemData?.requirements?.find(
                                (ee) => ee.uuid === e.uuid && ee?.answer?.toLowerCase() === "no"
                              )
                          )
                          ?.map((e) => ({
                            label: e.question,
                            value: e.uuid,
                          }))}
                        onChange={(event, value) => {
                          updateDataItem(
                            {
                              target: {
                                name: "requirements",
                                value: [
                                  ...value.map((e) => ({
                                    answer: "No",
                                    uuid: e.value,
                                  })),
                                  ...itemData?.requirements?.filter(
                                    (e) => e?.answer?.toLowerCase() === "sì"
                                  ),
                                ],
                              },
                            },
                            "bonuses",
                            activeContract,
                            key
                          );
                        }}
                        renderInput={(params) => <MDInput {...params} variant="standard" />}
                      />
                    </Grid>
                    <MDButton
                      variant="gradient"
                      color="error"
                      onClick={() => {
                        if (!confirm("Sei sicuro di voler eliminare questo elemento?")) return;
                        removeBonusItem(activeContract, key);
                      }}
                    >
                      <DeleteIcon /> Rimuovi elemento
                    </MDButton>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          </Card>

          <Divider />

          <Card style={{ marginTop: 25 }}>
            <MDBox p={3}>
              <MDTypography component="label" variant="h4" fontWeight="bold">
                Domande
              </MDTypography>
              {item?.data?.wizard?.map((itemData, indexData) => (
                <Grid
                  item
                  xs={12}
                  p={2}
                  key={indexData}
                  style={indexData % 2 === 1 ? { background: "#e8e8e8" } : {}}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormField
                        onChange={(e) => updateDataItem(e, "wizard", activeContract, indexData)}
                        type="text"
                        label="Domanda"
                        defaultValue=""
                        name="question"
                        value={itemData?.question}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox display="inline-block" sx={{ color: "green !important" }}>
                        Devi aver risposto SI
                      </MDBox>
                      <Autocomplete
                        multiple
                        value={Object.entries(itemData?.requirements || {})
                          ?.filter(([uuid, reply]) => reply?.toLowerCase() === "sì")
                          ?.map(([value]) => ({
                            label: item?.data?.wizard?.find((e) => e.uuid === value)?.question,
                            value,
                          }))}
                        options={item?.data?.wizard
                          ?.filter(
                            (e) =>
                              itemData?.requirements[e.uuid]?.toLowerCase() !== "sì" &&
                              e.uuid !== itemData.uuid
                          )
                          ?.map((e) => ({
                            label: e.question,
                            value: e.uuid,
                          }))}
                        onChange={(event, value) => {
                          updateDataItem(
                            {
                              target: {
                                name: "requirements",
                                value: {
                                  ...Object.fromEntries(
                                    Object.entries(itemData?.requirements || {})?.filter(
                                      ([uuid, reply]) => reply?.toLowerCase() === "no"
                                    )
                                  ),
                                  ...value.reduce(
                                    (prev, current) => ({
                                      ...prev,
                                      [current.value]: "Sì",
                                    }),
                                    {}
                                  ),
                                },
                              },
                            },
                            "wizard",
                            activeContract,
                            indexData
                          );
                        }}
                        renderInput={(params) => <MDInput {...params} variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox display="inline-block" sx={{ color: "red !important" }}>
                        Devi aver risposto NO
                      </MDBox>
                      <Autocomplete
                        multiple
                        value={Object.entries(itemData?.requirements || {})
                          ?.filter(([uuid, reply]) => reply?.toLowerCase() === "no")
                          ?.map(([value]) => ({
                            label: item?.data?.wizard?.find((e) => e.uuid === value)?.question,
                            value,
                          }))}
                        options={item?.data?.wizard
                          ?.filter(
                            (e) =>
                              itemData?.requirements[e.uuid]?.toLowerCase() !== "no" &&
                              e.uuid !== itemData.uuid
                          )
                          ?.map((e) => ({
                            label: e.question,
                            value: e.uuid,
                          }))}
                        onChange={(event, value) => {
                          updateDataItem(
                            {
                              target: {
                                name: "requirements",
                                value: {
                                  ...Object.fromEntries(
                                    Object.entries(itemData?.requirements || {})?.filter(
                                      ([uuid, reply]) => reply?.toLowerCase() === "sì"
                                    )
                                  ),
                                  ...value.reduce(
                                    (prev, current) => ({
                                      ...prev,
                                      [current.value]: "No",
                                    }),
                                    {}
                                  ),
                                },
                              },
                            },
                            "wizard",
                            activeContract,
                            indexData
                          );
                        }}
                        renderInput={(params) => <MDInput {...params} variant="standard" />}
                      />
                    </Grid>
                    <MDButton
                      variant="gradient"
                      color="error"
                      style={{ margin: 15 }}
                      onClick={() => onRemoveQuestion(itemData?.uuid, activeContract)}
                    >
                      <DeleteIcon /> Rimuovi
                    </MDButton>
                  </Grid>
                </Grid>
              ))}

              <MDButton
                variant="gradient"
                color="info"
                style={{ margin: 15 }}
                onClick={() => onAddQuestion(activeContract)}
              >
                <AddIcon /> Aggiungi domanda
              </MDButton>
              {/* <pre>{JSON.stringify(item?.data, null, 4)}</pre> */}
            </MDBox>
          </Card>

          <Dialog
            fullWidth
            open={showWizard}
            onClose={() => setShowWizard(false)}
            maxWidth="lg"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            {completeWizard?.status === "error" ? (
              <Alert severity="error" title="ERRORE DA RISOLVERE">
                {completeWizard?.message}
                {completeWizard?.content?.map((e, i) => (
                  <div key={i}>
                    <MDTypography component="label" variant="h6">
                      {e.question}
                    </MDTypography>
                  </div>
                ))}
              </Alert>
            ) : (
              <>
                <DialogTitle id="scroll-dialog-title">Elenco domande wizard</DialogTitle>
                <DialogContent>
                  <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                    {completeWizard?.wizard?.map((w, i) => (
                      <div key={w.uuid}>
                        <MDTypography component="label" variant="h6">
                          [{i + 1}] {w.question}
                        </MDTypography>
                        <p>
                          {Object.entries(w.requirements || {})?.map(([uuid, r]) => (
                            <Chip
                              color={r === "Sì" ? "success" : "error"}
                              variant="outlined"
                              label={
                                completeWizard?.wizard?.find((cw) => cw.uuid === uuid)?.question ||
                                "DOMANDA NON TROVATA"
                              }
                              style={{ margin: 3 }}
                            />
                          ))}
                        </p>
                      </div>
                    ))}

                    {completeWizard?.bonuses && (
                      <>
                        <Divider />
                        <DialogTitle id="scroll-dialog-title">Elenco bonus</DialogTitle>
                        {Object.entries(completeWizard?.bonuses)?.map(([k, b]) => (
                          <div key={k}>
                            <MDTypography component="label" variant="h6">
                              {b?.name}
                            </MDTypography>
                            <p>
                              {Object.values(b?.requirements || {})?.map((r) => (
                                <Chip
                                  key={r?.uuid}
                                  color={r?.answer === "Sì" ? "success" : "error"}
                                  variant="outlined"
                                  label={
                                    completeWizard?.wizard?.find((cw) => cw.uuid === r?.uuid)
                                      ?.question || "DOMANDA NON TROVATA"
                                  }
                                  style={{ margin: 3 }}
                                />
                              ))}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          </Dialog>

          <WizardSimulationBonus
            open={showWizardSimulation}
            onClose={() => setShowWizardSimulation(false)}
            wizardData={completeWizard}
          />
        </>
      )}
    </>
  );
}

export default AiDataScopriBonus;
