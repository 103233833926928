import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";

// ProductsList page components
import { Link, useNavigate } from "react-router-dom";

import useHttp from "../../../hooks/use-http";
import dataTableData from "./data/dataTableData";

function ArchiveDepartmentsNsp() {
  const history = useNavigate();
  const {
    sendRequest: sendDepartmentsRequest,
    isLoading: isDepartmentsLoading,
    error: departmentsError,
  } = useHttp();

  const [rows, setRows] = useState([]);
  const handleRows = useCallback(
    (tempRows) => {
      const appoggio = tempRows.list.map((row) => ({
        id: row.id,
        businessName: row.business_name,
        email: row.email,
        phone: row.phone,
        city: row.city,
        numeroOperator: 0,
        azione: {
          action: () => {
            history(`/gestione/ufficio/${row.id}`);
          },
        },
      }));
      setRows(appoggio);
    },
    [setRows]
  );

  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...rows] });
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...rows] });
  }, [rows]);

  useEffect(() => {
    sendDepartmentsRequest({ url: "/departments", manageData: handleRows });
  }, [handleRows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                Elenco Uffici
                <Link to={{ pathname: "/gestione/ufficio" }}>
                  <MDButton variant="gradient" color="info" sx={{ m: 2 }}>
                    <Icon>add</Icon>&nbsp; Nuovo Ufficio
                  </MDButton>
                </Link>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <DataTable table={dataTable} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ArchiveDepartmentsNsp;
