// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function DepartmentInfo({
  businessName,
  businessNameHandler,
  fiscalCode,
  fiscalCodeHandler,
  vatNumber,
  vatNumberHandler,
  invoiceCode,
  invoiceCodeHandler,
  phone,
  phoneHandler,
  email,
  emailHandler,
  city,
  cityHandler,
  state,
  stateHandler,
  address,
  addressHandler,
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informazioni Utente</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                onChange={businessNameHandler}
                type="text"
                label="Ragione Sociale"
                defaultValue=""
                value={businessName}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                onChange={emailHandler}
                type="text"
                label="Email"
                defaultValue=""
                value={email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                onChange={phoneHandler}
                type="text"
                label="Telefono"
                defaultValue=""
                value={phone}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                onChange={stateHandler}
                type="text"
                label="Provincia"
                defaultValue=""
                value={state}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                onChange={cityHandler}
                type="text"
                label="Città"
                defaultValue=""
                value={city}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                onChange={addressHandler}
                type="text"
                label="Indirizzo"
                defaultValue=""
                value={address}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={vatNumberHandler}
                type="text"
                label="P.Iva"
                defaultValue=""
                value={vatNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={fiscalCodeHandler}
                type="text"
                label="Codice fiscale"
                defaultValue=""
                value={fiscalCode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={invoiceCodeHandler}
                type="text"
                label="Codice attività"
                defaultValue=""
                value={invoiceCode}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DepartmentInfo;
