import { Autocomplete, Grid, Switch } from "@mui/material";
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Preloader from "components/Preloader";
import Editor, { cellPlugins } from "components/ReactPage";
import Select from "components/Select";
import { platforms } from "config";
import useHttp from "hooks/use-http";
import FormField from "pagensp/CustomComponents/FormField";
import ErrContext from "pagensp/store/error-context";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateBlocks } from "./utils";

// Tipologie di landing
const LANDING_TYPES = [
  {
    label: "Sponsor Prodotto",
    value: "PRODUCT",
  },
  {
    label: "Sponsor Wizard",
    value: "WIZARD",
  },
];

// Lista wizard associabili
const WIZARD_LIST = [
  {
    label: "Consulenza sul lavoro",
    value: "CONSULENZA_LAVORO",
  },
  {
    label: "Consulenza previdenziale",
    value: "CONSULENZA_PREVIDENZIALE",
  },
  {
    label: "Consulenza legale",
    value: "CONSULENZA_LEGALE",
  },
  {
    label: "Successioni",
    value: "SUCCESSIONI",
  },
];

// Validations
const SLUG_REGEX = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

function PageLandingDetailNsp() {
  const { id: pageId } = useParams();
  const { sendError } = useContext(ErrContext);
  const MODE = useMemo(() => (pageId === "new" ? "CREATE" : "UPDATE"), [pageId]);
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const [fields, setFields] = useState({
    title: "",
    subtitle: "",
    cta_text: "",
    meta_title: "",
    meta_description: "",
    type: "",
    slug: "",
    platforms: [],
    wizard: null,
    content: null,
    product: null,
    active: false,
    image: null,
  });

  const onChange = (key, value) => setFields((prev) => ({ ...prev, [key]: value }));
  const onChangeSelect = (select, name) => onChange(name, select?.value);
  const onChangeSwitch = ({ target }, name) => onChange(name, target.checked);

  // SAVE
  const { isLoading, sendRequest: saveRequest } = useHttp();
  const onSave = () => {
    if (!SLUG_REGEX.test(fields.slug)) {
      sendError("Slug non valido, ammessi solo lettere, numeri e trattini");
      return;
    }
    saveRequest({
      url: MODE === "CREATE" ? "/landings" : `/landings/${pageId}`,
      methodH: MODE === "CREATE" ? "POST" : "PUT",
      bodyH: {
        data: {
          ...fields,
          content: JSON.stringify(fields.content),
          // If there is new upload then save the image
          image: typeof fields.image === "object" ? fields.image : undefined,
          product: fields.type === "PRODUCT" ? fields.product : null,
          wizard: fields.type === "WIZARD" ? fields.wizard : null,
          platforms: fields.platforms.map((item) => item.value).join(","),
        },
      },
      manageData: () => history(`/landings/pagine`),
    });
  };

  const onCreateBlocks = (mode) => {
    generateBlocks({
      mode,
      fields,
    }).then((content) => {
      setFields({
        ...fields,
        content,
      });
    });
  };

  // PRODUCTS
  const { sendRequest: sendProductsRequest } = useHttp();
  useEffect(() => {
    sendProductsRequest({
      url: "/products",
      manageData: ({ list }) =>
        setProducts(
          list.map(({ id, name, category }) => ({
            label: `${category?.text || "NESSUNA CATEGORIA"} - ${name}`,
            value: id,
          }))
        ),
    });
  }, []);

  // UPDATE
  const { sendRequest: getArticle } = useHttp();
  useEffect(() => {
    if (pageId && MODE === "UPDATE")
      getArticle({
        url: `/landings/${pageId}`,
        manageData: ({ data }) =>
          setFields({
            ...data,
            product: data?.product?.id || null,
            platforms:
              platforms.filter((item) => data.platforms?.split(",").includes(item.value)) || [],
            content: (() => {
              try {
                return JSON.parse(data.content);
              } catch (e) {
                return null;
              }
            })(),
            id: undefined,
          }),
      });
  }, [pageId, MODE]);

  // RENDER
  const SectionHeader = useMemo(
    () => (
      <Grid container justifyContent="space-between" spacing={2} p={1}>
        <Grid item>
          <MDTypography variant="h5">Dati pagina</MDTypography>
        </Grid>
        <Grid item alignItems="center" justifyContent="flex-end">
          <Grid container item alignItems="center" justifyContent="flex-end">
            <MDTypography variant="h7">Pubblica</MDTypography>
            <Switch
              checked={fields.active}
              onChange={(e) => onChangeSwitch(e, "active")}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid item>
            <MDButton variant="gradient" color="info" onClick={onSave} fullWidth>
              <Preloader isLoading={isLoading} fallback={<>Salvataggio in corso</>}>
                Salva
              </Preloader>
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    ),
    [fields.active, onSave]
  );

  const LeftCol = useMemo(
    () => (
      <Grid item xs={12} lg={6}>
        <FormField
          type="text"
          label="Titolo"
          value={fields.title}
          onChange={(e) => onChange("title", e.target.value)}
        />
        <FormField
          type="text"
          label="Sottotitolo"
          value={fields.subtitle}
          onChange={(e) => onChange("subtitle", e.target.value)}
        />
        <FormField
          type="text"
          label="Testo CTA"
          value={fields.cta_text}
          onChange={(e) => onChange("cta_text", e.target.value)}
        />
        <Select
          label="Tipo landing"
          options={LANDING_TYPES}
          value={fields.type}
          onChange={(e) => onChangeSelect(e, "type")}
        />
        {fields.type === "PRODUCT" && (
          <Select
            label="Scegli il prodotto che vuoi associare"
            options={products}
            value={fields.product}
            onChange={(e) => onChangeSelect(e, "product")}
          />
        )}
        {fields.type === "WIZARD" && (
          <Select
            label="Scegli il wizard che vuoi associare"
            options={WIZARD_LIST}
            value={fields.wizard}
            onChange={(e) => onChangeSelect(e, "wizard")}
          />
        )}
      </Grid>
    ),
    [
      fields.title,
      fields.subtitle,
      fields.cta_text,
      fields.description,
      fields.type,
      fields.product,
      fields.wizard,
      products,
    ]
  );

  const RightCol = useMemo(
    () => (
      <Grid item xs={12} lg={6}>
        <FormField
          type="text"
          label="Slug (solo lettere, numeri, trattini)"
          value={fields.slug}
          onChange={(e) => onChange("slug", e.target.value)}
        />
        <FormField
          type="text"
          label="Meta Title"
          value={fields.meta_title}
          onChange={(e) => onChange("meta_title", e.target.value)}
        />
        <FormField
          type="text"
          multiline
          rows={5}
          label="Meta Description"
          value={fields.meta_description}
          onChange={(e) => onChange("meta_description", e.target.value)}
        />

        <MDTypography variant="button">Pubblica su piattaforme</MDTypography>
        <Autocomplete
          multiple
          value={fields.platforms}
          options={platforms}
          onChange={(event, data) => onChange("platforms", data)}
          renderInput={(params) => <MDInput {...params} variant="standard" />}
        />
      </Grid>
    ),
    [fields.slug, fields.meta_title, fields.meta_description, fields.platforms, platforms]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" borderRadius={12} p={2} my={2}>
        {SectionHeader}
        <Grid container spacing={2} mb={2}>
          {LeftCol}
          {RightCol}
          <Grid item xs={12} mt={2}>
            <MDButton variant="gradient" color="info" onClick={() => onCreateBlocks("landing")}>
              Crea Template Landing
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox bgColor="white" borderRadius={12} p={2} my={2}>
        <MDTypography variant="h5">Contenuto pagina</MDTypography>
        <Editor
          cellPlugins={cellPlugins}
          value={fields.content}
          onChange={(value) => onChange("content", value)}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default PageLandingDetailNsp;
