// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function UserOrgInfo({
  businessName,
  businessNameHandler,
  fiscalCode,
  fiscalCodeHandler,
  vatNumber,
  vatNumberHandler,
  invoiceCode,
  invoiceCodeHandler,
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Dati fiscali</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                onChange={businessNameHandler}
                type="text"
                label="Ragione Sociale"
                defaultValue=""
                value={businessName}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={fiscalCodeHandler}
                type="text"
                label="Codice fiscale"
                defaultValue=""
                value={fiscalCode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={vatNumberHandler}
                type="text"
                label="P.Iva"
                defaultValue=""
                value={vatNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                onChange={invoiceCodeHandler}
                type="text"
                label="Codice attività"
                defaultValue=""
                value={invoiceCode}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default UserOrgInfo;
