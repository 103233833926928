import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";

// Custom Hooks
import { useParams, useNavigate } from "react-router-dom";
import useHttp from "hooks/use-http";
// EditProduct page components
// import ProductImage from "pagensp/gestproduct/productdetail/components/ProductImage/ProductImage";
// import ProductInfo from "pagensp/gestproduct/productdetail/components/ProductInfo/ProductInfo";
// import ProductDxSidebar from "pagensp/gestproduct/productdetail/components/ProductDxSidebar/ProductDxSidebar";
// import SelectProduct from "pagensp/gestproduct/productdetail/components/SelectProduct/SelectProduct";
// import SelectPrersonalInformation from "pagensp/gestproduct/productdetail/components/SelectPrersonalInformation/SelectPrersonalInformation";
// import SelectPrersonaldocumentation from "pagensp/gestproduct/productdetail/components/SelectPrersonaldocumentation/SelectPrersonaldocumentation";
// import CustomFaq from "pagensp/gestproduct/productdetail/components/CustomFaq/CustomFaq";
import DepartmentInfo from "./components/DepartmentInfo";
import DepartmentDxSidebar from "./components/DepartmentDxSidebar";

function EditDepartmentNSP() {
  const { id: pId } = useParams();

  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [fiscalCode, setFiscalCode] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [invoiceCode, setInvoiceCode] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState();
  const navigate = useNavigate();
  const { isLoading: isUserLoading, sendRequest: sendUserRequest, error: userError } = useHttp();

  useEffect(() => {
    if (pId) {
      sendUserRequest({
        url: `/departments/${pId}`,
        manageData: ({ data }) => {
          manageUserGet(data);
        },
      });
    }
  }, [pId]);

  const manageUserGet = ({
    business_name: businessNameG,
    email: emailG,
    fiscal_code: fiscalCodeG,
    vat_number: vatNumberG,
    invoice_code: invoiceCodeG,
    phone: phoneG,
    city: cityG,
    state: stateG,
    address: addressG,
  }) => {
    setBusinessName(businessNameG);
    setEmail(emailG);
    setFiscalCode(fiscalCodeG);
    setVatNumber(vatNumberG);
    setInvoiceCode(invoiceCodeG);
    setPhone(phoneG);
    setCity(cityG);
    setState(stateG);
    setAddress(addressG);
  };

  const businessNameHandler = (e) => {
    setBusinessName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const stateHandler = (e) => {
    setState(e.target.value);
  };

  const fiscalCodeHandler = (e) => {
    setFiscalCode(e.target.value);
  };

  const addressHandler = (e) => {
    setAddress(e.target.value);
  };

  const vatNumberHandler = (e) => {
    setVatNumber(e.target.value);
  };

  const invoiceCodeHandler = (e) => {
    setInvoiceCode(e.target.value);
  };

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();

  const saveHandler = () => {
    const data = {
      data: {
        city,
        state,
        address,
        email,
        phone,
        business_name: businessName,
        fiscal_code: fiscalCode === "" ? null : fiscalCode,
        vat_number: vatNumber === "" ? null : vatNumber,
        invoice_code: invoiceCode === "" ? null : invoiceCode,
        invoiceCode,
      },
    };
    sendSaveRequest({
      methodH: pId ? "PUT" : "POST",
      url: pId ? `/departments/${pId}` : `/departments/`,
      bodyH: data,
    }).then(() => {
      setTimeout(() => navigate(`/gestione/uffici`), 500);
    });
  };

  const {
    sendRequest: sendDelUserRequest,
    isLoading: isDelUserLoading,
    error: delDelUserError,
  } = useHttp();

  const removeUser = () => {
    if (window.confirm("Sei sicuro di voler eliminare questo Utente")) {
      sendDelUserRequest({
        methodH: "DELETE",
        url: `/departments/${pId}`,
      }).then(() => {});
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DepartmentInfo
                    businessName={businessName}
                    businessNameHandler={businessNameHandler}
                    fiscalCode={fiscalCode}
                    fiscalCodeHandler={fiscalCodeHandler}
                    vatNumber={vatNumber}
                    vatNumberHandler={vatNumberHandler}
                    invoiceCode={invoiceCode}
                    invoiceCodeHandler={invoiceCodeHandler}
                    phone={phone}
                    phoneHandler={phoneHandler}
                    email={email}
                    emailHandler={emailHandler}
                    city={city}
                    cityHandler={cityHandler}
                    state={state}
                    stateHandler={stateHandler}
                    address={address}
                    addressHandler={addressHandler}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <DepartmentDxSidebar
              saveHandler={saveHandler}
              removeUser={removeUser}
              isSaveLoading={isSaveLoading}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditDepartmentNSP;
