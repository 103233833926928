import { useEffect } from "react";
import Chat from "../component/Chat";
import StartPanel from "../component/StartPanel";

export default function BodyLiveChat({
  type,
  selectedRoom,
  sendMessage,
  handleSelectedRoom,
  room,
  messages,
  products,
  roomCustomer,
  sendReadMessage,
}) {
  return (
    <>
      <div className="h-100">
        <div className="row h-100">
          <div className="col-12">
            {products ? (
              <StartPanel
                {...{ selectedRoom, handleSelectedRoom, room, messages, roomCustomer }}
                type={1}
              />
            ) : (
              <>
                {selectedRoom === null ? (
                  <StartPanel
                    {...{ selectedRoom, handleSelectedRoom, room, messages, roomCustomer }}
                    type={0}
                  />
                ) : (
                  <Chat
                    {...{
                      selectedRoom,
                      handleSelectedRoom,
                      room,
                      messages,
                      sendMessage,
                      sendReadMessage,
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
