import React, { useState } from "react";

const ComponentsContext = React.createContext({
  avaiableComponents: [],
  nameOfComponents: [],
  // eslint-disable-next-line
  componentSetter: (components) => {},
  // eslint-disable-next-line
  namesSetter: (components) => {},
});

export const ComponentsContextProvider = ({ children }) => {
  // eslint-disable-next-line
  const [nameOfComponents, setNameOfComponents] = useState([]);
  const initialComponents = JSON.parse(localStorage.getItem("components"));
  const [components, setComponents] = useState(initialComponents);

  const setterHandler = (componentsH) => {
    setComponents(componentsH);
    localStorage.setItem("components", components);
  };

  const contextValue = {
    avaiableComponents: components,
    componentSetter: setterHandler,
    nameOfComponents,
  };

  return <ComponentsContext.Provider value={contextValue}>{children}</ComponentsContext.Provider>;
};
export default ComponentsContext;
