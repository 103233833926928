import MDTypography from "components/MDTypography";

// ProductsList page components
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default {
  columns: [
    {
      Header: "Tag",
      accessor: "tag",
      Cell: ({ value }) => <MDTypography variant="button">{value}</MDTypography>,
    },
    {
      Header: "Azione",
      width: "1%",
      disableSortBy: true,
      manualSortBy: false,
      accessor: "delete",
      Cell: ({ value }) => (
        <>
          <MDButton
            color="error"
            onClick={() => {
              value.action();
            }}
          >
            <DeleteIcon />
          </MDButton>{" "}
        </>
      ),
    },
    {
      Header: "",
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ value }) => (
        <>
          <MDButton
            color="info"
            onClick={() => {
              value.action();
            }}
          >
            <EditIcon />
          </MDButton>
        </>
      ),
    },
  ],
};
