/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */

// ProductsList page components
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Icon } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import moment from "moment";

export default {
  columns: [
    {
      Header: "Titolo",
      accessor: "title",
      Cell: ({ value }) => <MDTypography variant="button">{value}</MDTypography>,
    },
    { Header: "Categoria", accessor: "category.text" },
    { Header: "Piattaforma", accessor: "platforms" },
    {
      Header: "Attivo",
      accessor: "active",
      Cell: ({ value }) => (
        <Icon fontSize="30">{value ? "check_circle" : "highlight_off_rounded"}</Icon>
      ),
    },
    {
      Header: "Home",
      accessor: "featured",
      Cell: ({ value }) => (
        <Icon fontSize="30">{value ? "check_circle" : "highlight_off_rounded"}</Icon>
      ),
    },
    {
      Header: "data inizio",
      accessor: "date_start",
      Cell: ({ value }) => <span>{moment(value).format("DD/MM/YYYY")}</span>,
    },
    {
      Header: "data fine",
      accessor: "date_end",
      Cell: ({ value }) => <span>{moment(value).format("DD/MM/YYYY")}</span>,
    },
    {
      Header: "Azione",
      width: "1%",
      disableSortBy: true,
      manualSortBy: false,
      accessor: "delete",
      Cell: ({ value }) => (
        <>
          <MDButton
            color="error"
            onClick={() => {
              value.action();
            }}
          >
            <DeleteIcon />
          </MDButton>{" "}
        </>
      ),
    },
    {
      Header: "",
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ value }) => (
        <>
          <MDButton
            color="info"
            onClick={() => {
              value.action();
            }}
          >
            <EditIcon />
          </MDButton>
        </>
      ),
    },
  ],
};
