import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Preloader = ({
  isLoading,
  fallback = (
    <Grid container flex justifyContent="center" alignItems="center" spacing={2} py={4}>
      <CircularProgress />
    </Grid>
  ),
  children,
}) => (isLoading ? fallback : children);
export default Preloader;
