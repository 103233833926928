/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import formatDate from "function/format-date";

// Images
import { Chip } from "@mui/material";

import useHttp from "hooks/use-http";
import { useEffect, useState } from "react";
import ApprovalProcess from "./ApprovalProcess";
import PreviewFile from "./PreviewFile";
import { generatePreavvisoDimissioniPdf } from "./utils";
import { prepareAssets } from "../../../../../components/ReactPdfEditor/utils/prepareAssets";

prepareAssets();
const About = ({
  pratices,
  user,
  handleLoadData,
  handlePay,
  paymentSendOk,
  approveRefound,
  rejectRefound,
  onRestore,
}) => {
  const [ai, setAi] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const { sendRequest } = useHttp();
  const approveWrap = () => {
    approveRefound();
    window.location.reload();
  };
  const rejectWrap = () => {
    rejectRefound();
    window.location.reload();
  };

  useEffect(() => {
    if (pratices?.product?.id === 164) {
      const idFields = {
        type: 54,
        age: 55,
        level: 56,
      };
      sendRequest({
        url: `/products/${pratices?.product?.id}`,
        manageData: ({ data }) => {
          const type = pratices?.fields?.find((item) => item.field.id === idFields.type)?.user_field
            ?.text;
          const age = pratices?.fields?.find((item) => item.field.id === idFields.age)?.user_field
            ?.text;
          const level = pratices?.fields?.find((item) => item.field.id === idFields.level)
            ?.user_field?.text;

          console.log("product", data, { type, age, level });

          if (!data?.ai_data?.length) {
            return;
          }
          const itemSelected = data?.ai_data?.find((e) => e.name === type);
          if (!itemSelected) {
            return;
          }
          try {
            const aiData = JSON.parse(itemSelected.data);
            const elementSelected = aiData?.items?.find((e) => e.age === age && e.level === level);
            const days = elementSelected?.days;
            const note = elementSelected?.note;
            setAi({
              ...itemSelected,
              data: aiData,
              days,
              type,
              age,
              level,
              note,
              message: (
                <>
                  Numero di giorni di preavviso: <h1>{days || "Non trovato"}</h1>
                </>
              ),
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    }
  }, [pratices]);

  return (
    <MDBox mx={4} my={4}>
      <Grid>
        <Grid item xs={12} container>
          <MDTypography variant="h3" fontWeight="bold" sx={{ bgcolorcolor: "primary.main" }}>
            {pratices?.product.text}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container my={2} spacing={3}>
        <Grid item xs={12} md={4} sx={{ borderRight: "1px solid gray" }}>
          <MDTypography
            variant="body2"
            color="text"
            pb={2}
            sx={{ borderBottom: "0.0625rem solid #f6f6f6" }}
          >
            {pratices?.consulting_slot ? (
              <>
                <strong>Richiesta Appuntamento il:</strong>
                <br /> {pratices?.consulting_slot.start} <br />
                {pratices?.user_request ? (
                  <>
                    <strong>Richiesta utente:</strong>
                    <br /> {pratices?.user_request} <br />
                  </>
                ) : (
                  <></>
                )}
                {pratices?.zoom_meeting_join_url ? (
                  <>
                    <MDButton
                      color="primary"
                      style={{ opacity: "1" }}
                      onClick={() => {
                        setTimeout(() => {
                          window.open(pratices?.zoom_meeting_join_url, "_blank");
                        }, 1000);
                      }}
                    >
                      Partecipa alla Call
                    </MDButton>
                  </>
                ) : (
                  <></>
                )}
                <br />
                <br />
                <hr />
              </>
            ) : (
              <></>
            )}
            <br />
            {pratices?.refund === "requested" ? (
              <>
                <MDButton color="primary" style={{ opacity: "1" }} onClick={approveWrap}>
                  Approva rimborso
                </MDButton>
                <br />
                <br />
                <MDButton color="primary" style={{ opacity: "1" }} onClick={rejectWrap}>
                  Rifiuta rimborso
                </MDButton>
                <br />
                <br />
                <hr />
              </>
            ) : (
              <></>
            )}
            <strong>Numero Pratica:</strong> {pratices?.id} <br />
            <strong>Numero Ordine:</strong> {pratices?.transaction} <br />
            <strong>Data di acquisto:</strong> {formatDate(pratices?.created, "dd/mm/YYYY")} <br />
            <strong>Data di consegna:</strong>{" "}
            {pratices?.product_type !== "subscription" ? (
              <>
                {pratices?.expected_date
                  ? formatDate(pratices?.expected_date, "dd/mm/YYYY")
                  : "Documenti non ancora approvati"}{" "}
              </>
            ) : (
              <>
                {pratices?.subscription_ref_date
                  ? `${formatDate(pratices?.subscription_ref_date, "dd/mm/YYYY")} (${
                      pratices?.subscription_num
                    } di ${pratices?.subscription_tot})`
                  : "Documenti non ancora approvati"}{" "}
              </>
            )}
            <br />
            <strong>Utente registrato il:</strong> {formatDate(user?.signup_date, "dd/mm/YYYY")}
          </MDTypography>

          <MDBox position="relative" height="max-content" mx="auto">
            {/* <MDAvatar src={team2} alt="profile picture" size="xxl" variant="rounded" />
              <MDBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <Tooltip title="Edit" placement="top">
                  <MDButton variant="gradient" color="info" size="small" iconOnly>
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox> */}
            <MDTypography variant="body2" color="text" mt={2}>
              <strong>Nome utente: </strong>
              {user?.name} {user?.surname}
              <br />
              <strong>E-mail: </strong>
              {user?.email}
              <br />
              <strong>Indirizzo: </strong>
              {user?.address}, {user?.city}, {user?.state}
              <br />
              <strong>Telefono: </strong>
              {user?.phone}
              <br />
              <strong>Codice Fiscale: </strong>
              {user?.fiscal_code}
              <br />
              <strong>Data di nascita: </strong> {formatDate(user?.date_of_birth, "dd/mm/YYYY")}
              <br />
              <strong>Comune di nascita: </strong> {user?.birth_place}, {user?.birth_place_state}
            </MDTypography>
            {ai && (
              <>
                <Chip label="Assistente AI" color="primary" style={{ marginTop: 25 }} />
                <MDTypography variant="body2" mt={2}>
                  {ai.message}
                </MDTypography>
                <div>
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={() => setPreviewFile({ ...ai, url: ai.document })}
                    style={{ marginBottom: 10 }}
                  >
                    Consulta il contratto
                  </MDButton>
                </div>
                <div>
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(ai.document, "_blank")}
                    style={{ marginBottom: 10 }}
                  >
                    Scarica il contratto
                  </MDButton>
                </div>
                <div>
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={() => generatePreavvisoDimissioniPdf({ ai, user, pratices })}
                  >
                    Scarica documento da allegare
                  </MDButton>
                </div>

                {previewFile && (
                  <PreviewFile
                    item={previewFile}
                    categories={[]}
                    onSubmit={() => {}}
                    onClose={() => setPreviewFile(null)}
                    onConfirm={() => setPreviewFile(null)}
                    onReject={() => setPreviewFile(null)}
                    onPrev={() => {}}
                    onNext={() => {}}
                  />
                )}
              </>
            )}
            {pratices?.payment_method === "bank_transfer" && !pratices?.paid && (
              <>
                {!paymentSendOk ? (
                  <>
                    <MDTypography variant="h5" fontWeight="regular">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-white my-3"
                        onClick={() => {
                          handlePay();
                        }}
                      >
                        Segna Ordine come pagato
                      </button>
                    </MDTypography>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {pratices?.deleted_at && (
              <MDTypography variant="h5" fontWeight="regular">
                <button type="button" className="btn-danger text-white my-3" onClick={onRestore}>
                  Ripristina ordine
                </button>
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={8}>
          <MDBox mb={2}>
            <ApprovalProcess {...pratices} user={user} handleLoadData={handleLoadData} />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default About;
