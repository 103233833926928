import styled from "styled-components";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(10, 31, 68, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1200;
`;

export const Container = styled.div`
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #3c4751;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const View = styled.div`
  width: 100%;
  height: 100%;

  .react-pdf__Document,
  .react-pdf__Page {
    width: 100%;
    height: calc(100% - 110px);
    overflow: auto;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding-right: 15px;
`;

export const Image = styled.img`
  width: 100%;
  height: calc(100% - 110px);
  object-fit: contain;
`;

export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background: ${({ type, disabled }) => {
    if (disabled) return "#D6DCE1";
    return type === "secondary" ? "white" : "var(--bs-primary)";
  }};
  opacity: ${({ disabled }) => (disabled ? "0.2" : "1")};
  color: ${({ type }) => (type === "secondary" ? "var(--bs-primary)" : "white")};
  border: 1px solid ${({ type }) => (type === "secondary" ? "var(--bs-primary)" : "white")};
  border-radius: 59px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
`;

export const SelectContainer = styled.div`
  width: 400px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const ArrowLeft = styled(KeyboardArrowLeftIcon)`
  position: absolute;
  top: 50%;
  left: 45px;
  translate: -50% 0px;
  scale: 2.5;
  background: white;
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
  border-radius: 60px;
  cursor: pointer;
`;

export const ArrowRight = styled(KeyboardArrowRightIcon)`
  position: absolute;
  top: 50%;
  right: 8px;
  translate: -50% 0px;
  scale: 2.5;
  background: white;
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
  border-radius: 60px;
  cursor: pointer;
`;

export default {};
