import React, { useContext } from "react";

import ErrContext from "pagensp/store/error-context";

import classes from "./ErrorWrapper.module.css";

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const ErrorWrapper = ({ children }) => {
  const { errorText, sendError } = useContext(ErrContext);
  return (
    <div>
      {errorText !== "" && (
        <div className={classes.errorBanner}>
          {errorText}
          <div className={classes.errorButton} onClick={() => sendError("")}>
            X
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default ErrorWrapper;
