import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { Dropzone, FileItem } from "@dropzone-ui/react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Preloader from "components/Preloader";

// EditProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";
import { Link } from "react-router-dom";

function DepartmentDxSidebar({ saveHandler, removeUser, isSaveLoading }) {
  const [mainImmage, setMainImmage] = useState();

  // useEffect(() => {
  //   setMainImmage(images.find((data) => data.main === true));
  // }, [images]);

  return (
    <Card>
      <MDBox p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                <Preloader isLoading={isSaveLoading}>save</Preloader>
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex">
              <MDButton variant="gradient" color="light" size="large">
                <Link to={{ pathname: "/gestione/uffici" }} color="light">
                  Chiudi
                </Link>
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex">
              <MDButton variant="gradient" color="error" size="large" onClick={removeUser}>
                <DeleteIcon />
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="h5" fontWeight="bold">
          Configurazione ufficio
        </MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                {!mainImmage ? (
                  <>
                    <br />
                    <MDTypography variant="button">
                      Immagine Ufficio
                      <br />
                      Utilizza una immagine non piu grande di 2Mb
                    </MDTypography>
                    <Dropzone
                      onChange=""
                      value=""
                      maxFiles={1}
                      maxFileSize={2998000}
                      accept=".jpg,.jpeg,.png,image/*"
                    >
                      {/* {files.map((file) => (
                        <FileItem {...file} preview />
                      ))} */}
                    </Dropzone>
                  </>
                ) : (
                  <>
                    {/* <GestUploadImg
                      UploadImage={mainImmage.img}
                      imgHandlerRemove={imgHandlerRemove}
                    /> */}
                  </>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DepartmentDxSidebar;
