import { Autocomplete, Avatar, Grid, Switch } from "@mui/material";
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Preloader from "components/Preloader";
import Editor, { cellPlugins } from "components/ReactPage";
import Select from "components/Select";
import UploadSingle from "components/UploadSingle";
import { platforms } from "config";
import useHttp from "hooks/use-http";
import moment from "moment";
import FormField from "pagensp/CustomComponents/FormField";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ArticleBlogDetailNsp() {
  const { id: articleId } = useParams();
  const MODE = useMemo(() => (articleId === "new" ? "CREATE" : "UPDATE"), [articleId]);
  const history = useNavigate();
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [fields, setFields] = useState({
    title: "",
    subtitle: "",
    meta_title: "",
    meta_description: "",
    description: "",
    content: null,
    category: null,
    active: false,
    date_start: null,
    date_end: null,
    tags: [],
    platforms: [],
    image: null,
    user: null,
    date_creation: moment().format("YYYY-MM-DD"),
  });
  const onChange = (key, value) => setFields((prev) => ({ ...prev, [key]: value }));
  const onChangeSelect = (select, name) => onChange(name, select?.value);
  const onChangeSwitch = ({ target }, name) => onChange(name, target.checked);

  // SAVE
  const { isLoading, sendRequest: saveRequest } = useHttp();
  const onSave = () => {
    saveRequest({
      url: MODE === "CREATE" ? "/articles" : `/articles/${articleId}`,
      methodH: MODE === "CREATE" ? "POST" : "PUT",
      cache: false,
      bodyH: {
        data: {
          ...fields,
          content: JSON.stringify(fields.content),
          // If there is new upload then save the image
          image: typeof fields.image === "object" ? fields.image : undefined,
          tags: fields.tags.map(({ value }) => ({ tag: value })),
          platforms: fields.platforms?.value || null,
        },
      },
      manageData: () => history(`/blog/articoli`),
    });
  };

  // CATEGORIES
  const { sendRequest: sendCategoriesRequest } = useHttp();
  useEffect(() => {
    sendCategoriesRequest({
      url: "/categories-blog",
      manageData: ({ list }) =>
        setCategories(
          list.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    });
  }, []);

  // TAGS
  const { sendRequest: sendTagsRequest } = useHttp();
  useEffect(() => {
    sendTagsRequest({
      url: "/tags",
      manageData: ({ list }) =>
        setTags(
          list.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    });
  }, []);

  // USERS
  const { sendRequest: sendUsersRequest } = useHttp();
  useEffect(() => {
    sendUsersRequest({
      url: `/users?filters=[["type","!=","user"]]`,
      manageData: ({ list }) =>
        setUsers(
          list
            .map(({ id, name, surname, img }) => ({
              img,
              label: `${name} ${surname}`,
              value: id,
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))
        ),
    });
  }, []);

  // UPDATE
  const { sendRequest: getArticle } = useHttp();
  useEffect(() => {
    if (articleId && MODE === "UPDATE")
      getArticle({
        url: `/articles/${articleId}`,
        cache: false,
        manageData: ({ data }) =>
          setFields({
            ...data,
            category: data.category.id,
            user: data.user.id,
            tags: data.tags.map(({ tag }) => ({ label: tag.text, value: tag.id })),
            content: (() => {
              try {
                return JSON.parse(data.content);
              } catch (e) {
                return null;
              }
            })(),
            id: undefined,
          }),
      });
  }, [articleId, MODE]);
  // RENDER
  const SectionHeader = useMemo(
    () => (
      <Grid container justifyContent="space-between" spacing={2} p={1}>
        <Grid item>
          <MDTypography variant="h5">Dati pagina</MDTypography>
        </Grid>
        <Grid item alignItems="center" justifyContent="flex-end">
          <Grid container item alignItems="center" justifyContent="flex-end">
            <MDTypography variant="h7">Pubblica</MDTypography>
            <Switch
              checked={fields.active}
              onChange={(e) => onChangeSwitch(e, "active")}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid item>
            <MDButton variant="gradient" color="info" onClick={onSave} fullWidth>
              <Preloader isLoading={isLoading} fallback={<>Salvataggio in corso</>}>
                Salva
              </Preloader>
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    ),
    [fields.active, onSave]
  );

  const LeftCol = useMemo(
    () => (
      <Grid item xs={12} lg={6}>
        <FormField
          type="text"
          label="Titolo"
          value={fields.title}
          onChange={(e) => onChange("title", e.target.value)}
        />
        <MDTypography variant="button">Descrizione</MDTypography>
        <MDEditor value={fields.description} onChange={(value) => onChange("description", value)} />
        <Select
          label="Categoria"
          options={categories}
          value={fields.category}
          onChange={(e) => onChangeSelect(e, "category")}
        />

        <MDTypography variant="button">Tags</MDTypography>
        <Autocomplete
          multiple
          value={fields.tags}
          options={tags}
          onChange={(event, data) => onChange("tags", data)}
          renderInput={(params) => <MDInput {...params} variant="standard" />}
        />

        <MDTypography variant="button">Pubblica su piattaforma</MDTypography>
        <Autocomplete
          value={fields.platforms}
          options={platforms}
          onChange={(event, data) => onChange("platforms", data)}
          renderInput={(params) => <MDInput {...params} variant="standard" />}
        />
      </Grid>
    ),
    [
      fields.title,
      fields.description,
      fields.category,
      fields.tags,
      fields.platforms,
      tags,
      categories,
    ]
  );

  const RightCol = useMemo(
    () => (
      <Grid item xs={12} lg={6}>
        <FormField
          type="text"
          label="Sottotitolo"
          value={fields.subtitle}
          onChange={(e) => onChange("subtitle", e.target.value)}
        />
        <FormField
          type="text"
          label="Meta Title"
          value={fields.meta_title}
          onChange={(e) => onChange("meta_title", e.target.value)}
        />
        <FormField
          type="text"
          multiline
          rows={5}
          label="Meta Description"
          value={fields.meta_description}
          onChange={(e) => onChange("meta_description", e.target.value)}
        />

        <UploadSingle
          item={fields.image}
          onUpdate={(value) => onChange("image", value)}
          accept=".jpg,.jpeg,image/jpg,image/jpeg"
        />
      </Grid>
    ),
    [fields.subtitle, fields.meta_title, fields.meta_description, fields.image]
  );

  const SectionPublishHome = useMemo(
    () => (
      <MDBox bgColor="white" borderRadius={12} p={2} my={2}>
        <MDTypography variant="h5">Visualizza in Home</MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={6}>
            <Grid item alignItems="center">
              <MDTypography variant="button">Pubblica su home</MDTypography>
              <Switch
                checked={fields.featured}
                onChange={(e) => onChangeSwitch(e, "featured")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDTypography variant="button">Data inizio</MDTypography>
            <MDDatePicker
              input={{
                variant: "standard",
                placeholder: "Seleziona data di inizio",
                style: { width: "100%" },
              }}
              value={fields.date_start}
              onChange={([value]) => onChange("date_start", value)}
            />

            <MDTypography variant="button">Data fine</MDTypography>
            <MDDatePicker
              input={{
                variant: "standard",
                placeholder: "Seleziona data di fine",
                style: { width: "100%" },
              }}
              value={fields.date_end}
              onChange={([value]) => onChange("date_end", value)}
            />
          </Grid>
        </Grid>
      </MDBox>
    ),
    [fields.featured, fields.date_start, fields.date_end]
  );

  const SectionAuthor = useMemo(
    () => (
      <MDBox bgColor="white" borderRadius={12} p={2} my={2}>
        <MDTypography variant="h5">Autore e pubblicazione</MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={6}>
            <Select
              label="Autore"
              options={users}
              value={fields.user}
              onChange={(e) => onChangeSelect(e, "user")}
            />
            {fields.user && users?.length > 0 && (
              <Avatar
                alt=""
                src={users.find((u) => u.value === fields.user)?.img}
                sx={{ width: 56, height: 56 }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDTypography variant="button">Data pubblicazione</MDTypography>
            <MDDatePicker
              input={{
                variant: "standard",
                placeholder: "Seleziona data di pubblicazione",
                style: { width: "100%" },
              }}
              value={fields.date_creation}
              onChange={([value]) => onChange("date_creation", value)}
            />
          </Grid>
        </Grid>
      </MDBox>
    ),
    [fields.user, fields.date_creation, users]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" borderRadius={12} p={2} my={2}>
        {SectionHeader}
        <Grid container spacing={2} mb={2}>
          {LeftCol}
          {RightCol}
        </Grid>
      </MDBox>
      {SectionPublishHome}
      {SectionAuthor}
      <MDBox bgColor="white" borderRadius={12} p={2} my={2}>
        <MDTypography variant="h5">Contenuto pagina</MDTypography>
        <Editor
          cellPlugins={cellPlugins}
          value={fields.content}
          onChange={(value) => onChange("content", value)}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default ArticleBlogDetailNsp;
