import { createFile } from "components/ReactPdfEditor/hooks/useUploader";
import { save } from "components/ReactPdfEditor/utils/pdf";
import moment from "moment";

export const generatePreavvisoDimissioniPdf = async ({ ai, user, pratices }) =>
  save(
    await createFile("/templates/preavviso-dimissioni.pdf"),
    [
      [
        {
          type: "text",
          x: 236,
          y: 57,
          text: `${pratices.id}`,
          lineHeight: 1,
          size: 30,
          fontFamily: "Helvetica",
          width: 200,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 62,
          y: 148,
          text: `${user.name} ${user.surname}`,
          lineHeight: 1,
          size: 16,
          fontFamily: "Helvetica",
          width: 200,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 105,
          y: 170,
          text: `${user.email}`,
          lineHeight: 1,
          size: 14,
          fontFamily: "Helvetica",
          width: 200,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 122,
          y: 187,
          text: `${user.phone}`,
          lineHeight: 1,
          size: 14,
          fontFamily: "Helvetica",
          width: 200,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 440,
          y: 143,
          text: `${moment().format("DD/MM/YYYY")}`,
          lineHeight: 1,
          size: 14,
          fontFamily: "Helvetica",
          width: 200,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 62,
          y: 265,
          text: `${ai.type}`,
          lineHeight: 10,
          size: 10,
          fontFamily: "Helvetica",
          width: 200,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 280,
          y: 265,
          text: `${ai.age}`,
          lineHeight: 10,
          size: 10,
          fontFamily: "Helvetica",
          width: 250,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 360,
          y: 265,
          text: `${ai.level}`,
          lineHeight: 10,
          size: 10,
          fontFamily: "Helvetica",
          width: 250,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 62,
          y: 400,
          text: `Secondo le informazioni fornite dal cliente i giorni di preavviso da indicare in sede di dimissioni sono i seguenti: ${
            ai.days || "____"
          } giorni`,
          lineHeight: 26,
          size: 24,
          fontFamily: "Helvetica",
          width: 500,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
        {
          type: "text",
          x: 62,
          y: 530,
          text: (() => {
            const element = document.createElement("div");
            element.innerHTML = ai.note;
            return element.textContent;
          })(),
          lineHeight: 15,
          size: 14,
          fontFamily: "Helvetica",
          width: 500,
          color: {
            type: "RGB",
            blue: 255,
            green: 255,
            red: 255,
          },
        },
      ],
    ],
    "preavviso_dimissioni.pdf",
    true
  );

export default {};
