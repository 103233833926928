export const platforms = [
  {
    label: "WeWelfare",
    value: "WeWelfare",
  },
  {
    label: "CAF Milano",
    value: "CAF Milano",
  },
  {
    label: "CAF Roma",
    value: "CAF Roma",
  },
];

export default {};
