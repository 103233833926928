// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Dropzone, FileItem } from "@dropzone-ui/react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";

function ProductImage({ images, updateFilesGallery, filesGallery, imgGalleryHandlerRemove }) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Immagini</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            {images
              .filter((data) => data.main !== true)
              .map((image) => (
                <Grid item xs={12} sm={6} lg={4}>
                  <GestUploadImg
                    UploadImage={image.img}
                    imgHandlerRemove={() => {
                      imgGalleryHandlerRemove(image.id);
                    }}
                  />
                </Grid>
              ))}
            <Grid item xs={12} sm={6} lg={4}>
              <Dropzone
                onChange={updateFilesGallery}
                value={filesGallery}
                maxFiles={1}
                maxFileSize={2998000}
                accept=".jpg,.jpeg,.png,image/*"
              >
                {filesGallery.map((file) => (
                  <FileItem {...file} preview />
                ))}
              </Dropzone>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ProductImage;
