import { useContext, useEffect, useState } from "react";

import AuthContext from "pagensp/store/auth-context";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import ComplexStatisticsCard from "commoncomponent/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "commoncomponent/Footer";
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import GhostButton from "components/GhostButton/GhostButton";

// Custom Hooks
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import Select from "components/Select";
import TimeSlots from "components/TimeSlots/TimeSlots";
import useHttp from "hooks/use-http";
import { useNavigate, useParams } from "react-router-dom";
import comuniData from "./Comuni/index.json";
import UserDxSidebar from "./components/UserDxSidebar";
import UserInfo from "./components/UserInfo";
import UserOperatorsProducts from "./components/UserOperatorsProducts";
import UserOrgInfo from "./components/UserOrgInfo";

function EditUserNsp() {
  const { id: pId } = useParams();
  const { typeUser } = useContext(AuthContext);
  const { uid } = typeUser !== "admin" ? useContext(AuthContext) : { uid: undefined };
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [userPrivate, setUserPrivate] = useState(true);
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [fiscalCode, setFiscalCode] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [invoiceCode, setInvoiceCode] = useState("");
  const [phone, setPhone] = useState("");
  const [operatorMaxSubscriptions, setOperatorMaxSubscriptions] = useState(0);
  const [active, setActive] = useState(true);
  const [projectManager, setProjectManager] = useState(null);
  const [prjectManagerOptions, setprjectManagerOptions] = useState([]);
  const [prjectManagerValue, setPrjectManagerValue] = useState();
  const [birthPlace, setBirthPlace] = useState("");
  const [birthPlacestate, setBirthPlaceState] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [documents, setDocuments] = useState([]);
  const [fields, setFields] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsSend, setProductsSend] = useState();
  const [operators, setOperators] = useState([]);
  const [password, setPassword] = useState("");
  const [bio, setBio] = useState("");
  const [departments, setDepartments] = useState(null);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [departmentsValue, setDepartmentsValue] = useState("");
  const [img, setImg] = useState("");
  const [imgUser, setImgUser] = useState({ name: "", data: "" });
  const [file, setFile] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [countTotal, setCountTotal] = useState();
  const [signatureOffice, setSignatureOffice] = useState(null);
  const navigate = useNavigate();

  const { isLoading: isUserLoading, sendRequest: sendUserRequest, error: userError } = useHttp();

  useEffect(() => {
    if (pId) {
      sendUserRequest({
        url: `/users/${pId}`,
        cache: false,
        manageData: ({ data }) => {
          manageUserGet(data);
        },
      });
    } else if (uid) {
      console.log("il UID", uid);
      sendUserRequest({
        url: `/users/${uid}`,
        cache: false,
        manageData: ({ data }) => {
          console.log("il data", data);
          manageUserGet(data);
        },
      });
    }
  }, []);

  const { sendRequest: sendCountRequest } = useHttp();
  useEffect(() => {
    sendCountRequest({
      url: `/orders/counters-backend?user=${pId}`,
      manageData: (e) => {
        setCountTotal(e); // la funzione per la mappatura dei dati
      },
    });
  }, []);

  const manageUserGet = ({
    business_name: businessNameG,
    name: nameG,
    surname: surnameG,
    private: privateG,
    type: typeG,
    email: emailG,
    fiscal_code: fiscalCodeG,
    vat_number: vatNumberG,
    invoice_code: invoiceCodeG,
    department: departmentG,
    phone: phoneG,
    operator_max_subscriptions: operatorMaxSubscriptionsG,
    active: activeG,
    project_manager: projectManagerG,
    birth_place: birthPlaceG,
    birth_place_state: birthPlaceStateG,
    date_of_birth: dateOfBirthG,
    city: cityG,
    state: stateG,
    address: addressG,
    documents: documentsG,
    fields: fieldsG,
    products: productsG,
    operators: operatorsG,
    img: imgG,
    bio: bioG,
    zip_code: zipCodeG,
    signature_office: signatureOfficeG,
  }) => {
    setProducts(productsG || []);
    setBusinessName(businessNameG);
    setName(nameG);
    setSurName(surnameG);
    setUserPrivate(privateG);
    setType(typeG);
    setEmail(emailG);
    setFiscalCode(fiscalCodeG);
    setVatNumber(vatNumberG);
    setInvoiceCode(invoiceCodeG);
    setDepartments(departmentG?.id);
    initDepartmentsValue(departmentG);
    setPhone(phoneG);
    setOperatorMaxSubscriptions(operatorMaxSubscriptionsG);
    setActive(activeG);
    setProjectManager(projectManagerG?.id);
    initProjectManagerValue(projectManagerG);
    setBirthPlace(birthPlaceG);
    setBirthPlaceState(birthPlaceStateG);
    setDateOfBirth(dateOfBirthG);
    setCity(cityG);
    setState(stateG);
    setAddress(addressG);
    setDocuments(documentsG);
    setFields(fieldsG);
    setOperators(operatorsG);
    setImg(imgG);
    setBio(bioG);
    setZipCode(zipCodeG);
    setSignatureOffice(signatureOfficeG);
  };

  useEffect(() => {}, [products]);

  const zipcodeHandler = (e) => {
    setZipCode(e.target.value);
  };
  const nameHandler = (e) => {
    setName(e.target.value);
  };
  const surNameHandler = (e) => {
    setSurName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const birthPlaceHandler = (e) => {
    // setBirthPlace(e.target.value);
    setBirthPlace(e.nome);
    setBirthPlaceState(e.sigla);
  };

  const birthPlaceStateHandler = (e) => {
    setBirthPlaceState(e.target.value);
  };

  const dateOfBirthHandler = (e) => {
    setDateOfBirth(e.target.value);
  };

  // const cityHandler = (e) => {
  //   setCity(e.target.value);
  // };

  const cityHandler = (e) => {
    console.log("alex da comune", e.nome);
    setCity(e.nome);
    setState(e.sigla);
    // setUser({ ...user, city: e.nome, state: e.sigla });
    // setUser({ ...user, state: e.sigla });
  };

  const stateHandler = (e) => {
    setState(e.target.value);
  };

  const businessNameHandler = (e) => {
    setBusinessName(e.target.value);
  };

  const fiscalCodeHandler = (e) => {
    setFiscalCode(e.target.value);
  };

  const addressHandler = (e) => {
    setAddress(e.target.value);
  };

  const vatNumberHandler = (e) => {
    setVatNumber(e.target.value);
  };

  const invoiceCodeHandler = (e) => {
    setInvoiceCode(e.target.value);
  };

  const operatorMaxSubscriptionsHandler = (e) => {
    setOperatorMaxSubscriptions(+e.target.value);
  };

  const typeHandler = (e) => {
    setType(e.value);
  };

  const userPrivateHandler = () => {
    setUserPrivate((old) => !old);
  };

  const activeHandler = () => {
    setActive((old) => !old);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const bioHandler = (e) => {
    setBio(e);
  };

  const operatorHandler = (e) => {
    setOperators(e.value);
  };

  // Project manager
  const { sendRequest: sendProjectManagerRequest, isLoading: isProjectManagerLoading } = useHttp();

  useEffect(() => {
    if (typeUser === "admin") {
      sendProjectManagerRequest({
        url: `/users?filters=[["type","=","project_manager"]]`,
        manageData: manageProjectManager,
      });
    }
  }, [typeUser]);

  const manageProjectManager = (data) => {
    const oldApp = [];
    data.list.forEach((element, i) => {
      oldApp[i] = { value: "", label: "" };
      oldApp[i].value = element.id;
      oldApp[i].label = `${element.name} ${element.surname}`;
    });
    setprjectManagerOptions([...oldApp]);
  };

  const initProjectManagerValue = (data) => {
    setPrjectManagerValue({ label: data?.text, value: data?.id });
  };

  const editProjectManager = (e) => {
    if (e !== null) {
      setProjectManager(e.value);
      setPrjectManagerValue({ label: e.label, value: e.value });
    } else {
      setProjectManager(null);
      setPrjectManagerValue({ label: "", value: "" });
    }
  };

  // fine Project manager

  // Departments
  const { sendRequest: sendDepartmentsRequest, isLoading: isDepartmentsLoading } = useHttp();

  useEffect(() => {
    if (typeUser === "admin") {
      sendDepartmentsRequest({
        url: `/departments`,
        manageData: manageDepartments,
      });
    }
  }, [typeUser]);

  const manageDepartments = (data) => {
    const oldApp = [];
    data.list.forEach((element, i) => {
      oldApp[i] = { value: "", label: "" };
      oldApp[i].value = element.id;
      oldApp[i].label = `${element.business_name}`;
    });
    setDepartmentsOptions([...oldApp]);
  };

  const initDepartmentsValue = (data) => {
    setDepartmentsValue({ label: data?.text, value: data?.id });
  };

  const editDepartments = (e) => {
    if (e !== null) {
      setDepartments(e.value);
      setDepartmentsValue({ label: e.label, value: e.value });
    } else {
      setDepartments(null);
      setDepartmentsValue({ label: "", value: "" });
    }
  };

  // fine Departments

  // Products

  const {
    isLoading: isProductsLoading,
    sendRequest: sendProductsRequest,
    eror: productsError,
  } = useHttp();

  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    sendProductsRequest({ url: "/products", manageData: manageProducts });
  }, []);

  const manageProducts = (doc) => {
    setAllProducts(doc.list);
  };

  const addProducts = (newPackage) => {
    setProducts((old) => [...old, newPackage]);
  };

  const replaceProduct = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      if (element.product.text) {
        oldApp[i] = { product: "", max: "" };
        oldApp[i].product = element.product.id;
        oldApp[i].max = element.max;
      } else {
        oldApp[i] = element;
      }
    });
    setProductsSend([...oldApp]);
  };

  const editProductValue = (value, index) => {
    const old = [...products];
    if (value === "del") {
      old.splice(index, 1);
    } else {
      old[index].product = value;
    }
    replaceProduct(old);
    setProducts([...old]);
  };

  const editProductsMaxValue = (value, index) => {
    const old = [...products];
    old[index].max = Number(value);
    replaceProduct(old);
    setProducts([...old]);
  };
  // Fine Products

  // Immage
  const imgHandlerRemove = () => {
    setImg("");
    setImgUser(null);
  };

  const updateFiles = (incommingFiles) => {
    setFile(incommingFiles);
    const filesx = incommingFiles[0].file;
    const reader = new FileReader();
    reader.readAsDataURL(filesx);
    reader.onload = (e) => {
      const mime = e.target.result.match(/^data:(.*);/)[1];
      const image = e.target.result.replace(`data:${mime};base64,`, "");
      setImgUser({
        name: filesx.name,
        data: image,
      });
    };
  };
  // Fine Immage

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();

  const saveHandler = () => {
    const data = {
      data: {
        ...(typeUser === "admin" && { type }),
        name,
        zip_code: zipCode,
        surname: surName,
        city,
        state,
        address,
        ...(typeUser === "admin" && { email }),
        phone,
        birth_place: birthPlace,
        birth_place_state: birthPlacestate,
        date_of_birth: dateOfBirth,
        bio,
        ...(typeUser === "admin" && { department: departments }),
        ...(typeUser === "admin" && { project_manager: projectManager }),
        products: productsSend,
        ...(password && { password }),
        ...(imgUser.data && { img: imgUser }),
        // img: imgUser,
        ...(typeUser === "admin" && { operator_max_subscriptions: operatorMaxSubscriptions }),
        signature_office: signatureOffice,
      },
    };
    sendSaveRequest({
      methodH: pId || uid ? "PUT" : "POST",
      url: pId || uid ? `/users/${pId || uid}` : `/users/`,
      bodyH: data,
      cache: false,
    }).then(() => {
      setTimeout(() => navigate(`/gestione/utenti`), 500);
    });
  };

  const {
    sendRequest: sendDelUserRequest,
    isLoading: isDelUserLoading,
    error: delDelUserError,
  } = useHttp();

  const removeUser = () => {
    if (window.confirm("Sei sicuro di voler eliminare questo Utente")) {
      sendDelUserRequest({
        methodH: "DELETE",
        url: `/users/${pId}`,
      }).then(() => {});
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <GhostButton
                onClick={() => {
                  navigate(`/pratichesospese/${pId}/${name} ${surName}`);
                }}
              >
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Pratiche Sospese"
                  count={countTotal?.pending.count}
                />
              </GhostButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <GhostButton
                onClick={() => {
                  navigate(`/praticheattive/${pId}/${name} ${surName}`);
                }}
              >
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Pratiche in corso"
                  count={countTotal?.active.count}
                />
              </GhostButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <GhostButton
                onClick={() => {
                  navigate(`/pratichechiuse/${pId}/${name} ${surName}`);
                }}
              >
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Pratiche Complete"
                  count={countTotal?.done.count}
                />
              </GhostButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Totale Spesa"
                count={`${countTotal?.all.amount} €`}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <UserInfo
                    name={name}
                    nameHandler={nameHandler}
                    surname={surName}
                    surnameHandler={surNameHandler}
                    phone={phone}
                    phoneHandler={phoneHandler}
                    email={email}
                    emailHandler={emailHandler}
                    city={city}
                    cityHandler={cityHandler}
                    state={state}
                    stateHandler={stateHandler}
                    address={address}
                    addressHandler={addressHandler}
                    birthPlace={birthPlace}
                    birthPlaceHandler={birthPlaceHandler}
                    birthPlacestate={birthPlacestate}
                    birthPlaceStateHandler={birthPlaceStateHandler}
                    dateOfBirth={dateOfBirth}
                    dateOfBirthHandler={dateOfBirthHandler}
                    bio={bio}
                    bioHandler={bioHandler}
                    type={type}
                    comuniData={comuniData}
                    zipCode={zipCode}
                    zipcodeHandler={zipcodeHandler}
                  />
                </Grid>
                {type === "user" ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <UserOrgInfo
                        businessName={businessName}
                        businessNameHandler={businessNameHandler}
                        fiscalCode={fiscalCode}
                        fiscalCodeHandler={fiscalCodeHandler}
                        vatNumber={vatNumber}
                        vatNumberHandler={vatNumberHandler}
                        invoiceCode={invoiceCode}
                        invoiceCodeHandler={invoiceCodeHandler}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {["operator", "admin", "project_manager"].includes(type) && (
                  <Grid item xs={12} md={12}>
                    <MDBox my={3}>
                      <Card>
                        <MDBox p={2}>
                          <MDTypography variant="h5">Firma Ufficio</MDTypography>
                          <Select
                            label="Scegli la firma ufficio da associare"
                            value={signatureOffice}
                            options={[
                              {
                                label: "Non selezionato",
                                value: null,
                              },
                              {
                                label: "Ufficio Salvatore",
                                value: "salvatore",
                              },
                              {
                                label: "Ufficio Domenico",
                                value: "domenico",
                              },
                            ]}
                            onChange={({ value }) => setSignatureOffice(value)}
                          />
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                )}
                {type === "operator" && typeUser === "admin" ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <UserOperatorsProducts
                        products={products}
                        allProductOption={allProducts.map((val) => ({
                          label: val.name,
                          value: val.id,
                        }))}
                        addProducts={addProducts}
                        editProductsValue={editProductValue}
                        editProductsMaxValue={editProductsMaxValue}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {typeUser === "operator" && (
                  <Grid item xs={12} md={12}>
                    <MDBox my={3}>
                      <TimeSlots operator={uid} />
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <UserDxSidebar
              isLoading={isSaveLoading}
              password={password}
              passwordHandler={passwordHandler}
              saveHandler={saveHandler}
              removeUser={removeUser}
              type={type}
              typeHandler={typeHandler}
              active={active}
              activeHandler={activeHandler}
              userPrivate={userPrivate}
              userPrivateHandler={userPrivateHandler}
              projectManager={projectManager}
              prjectManagerOptions={prjectManagerOptions}
              prjectManagerValue={prjectManagerValue}
              editProjectManager={editProjectManager}
              departments={departments}
              departmentsOptions={departmentsOptions}
              departmentsValue={departmentsValue}
              editDepartments={editDepartments}
              operatorMaxSubscriptions={operatorMaxSubscriptions}
              operatorMaxSubscriptionsHandler={operatorMaxSubscriptionsHandler}
              updateFiles={updateFiles}
              file={file}
              img={img}
              imgHandlerRemove={imgHandlerRemove}
              typeUser={typeUser}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditUserNsp;
