import { createValue } from "@react-page/editor";
import { cellPlugins } from "components/ReactPage";
import CardsLanding from "components/ReactPage/plugins/CardsLanding";
import CounterLanding from "components/ReactPage/plugins/CounterLanding";
import CtaProduct from "components/ReactPage/plugins/CtaProduct";
import LandingVideo from "components/ReactPage/plugins/LandingVideo";
import Percentages from "components/ReactPage/plugins/Percentages";
import Steps from "components/ReactPage/plugins/Steps";
import TimerLanding from "components/ReactPage/plugins/TimerLanding";
import TrustpilotLanding from "components/ReactPage/plugins/TrustpilotLanding";
import Faq from "layouts/pages/pricing-page/components/Faq";
import moment from "moment";

/* eslint-disable no-fallthrough */
/* eslint-disable no-case-declarations */
export const generateBlocks = async ({ mode, fields, product }) => {
  switch (mode) {
    case "landing":
      return createValue(
        {
          rows: [
            {
              plugin: TimerLanding,
              data: {
                title: "LO SCONTO TERMINA TRA:",
                expire: moment().add(24, "hours").toISOString(),
                buttonText: fields?.cta_text?.toUpperCase() || "",
                product: fields?.product || null,
              },
            },
            {
              plugin: CounterLanding,
              data: {
                title: "PERÒ È ANCHE GIUSTO DIRTI CHE..",
                count: "999",
                description:
                  "Non per metterti fretta eh, ma questo è il numero di posti rimasti per ottenere il servizio scontato!",
                buttonText: fields?.cta_text?.toUpperCase() || "",
                product: fields?.product || null,
              },
            },
            {
              plugin: LandingVideo,
              data: {
                src: "https://www.youtube.com/watch?v=D0UnqGm_miA",
              },
            },
            {
              plugin: CtaProduct,
              data: {
                buttonText: fields?.cta_text?.toUpperCase() || "",
                product: fields?.product || null,
              },
            },
            {
              plugin: Steps,
              data: {
                title: "COME FUNZIONA?",
                description: "Qui inserisci un testo che introduca gli step da seguire",
                bg: true,
                cards: [
                  {
                    id: 1,
                    title: "Titolo",
                    description: "Descrizione",
                  },
                  {
                    id: 2,
                    title: "Titolo",
                    description: "Descrizione",
                  },
                  {
                    id: 3,
                    title: "Titolo",
                    description: "Descrizione",
                  },
                ],
              },
            },
            {
              plugin: Percentages,
              data: {
                title: "I BENEFICI",
                description: "Qui dai una piccola prefazione, non troppo lunga",
                cards: [
                  {
                    id: 1,
                    title: "Titolo",
                    percentage: 33,
                  },
                  {
                    id: 2,
                    title: "Titolo",
                    percentage: 66,
                  },
                  {
                    id: 3,
                    title: "Titolo",
                    percentage: 100,
                  },
                ],
              },
            },
            {
              plugin: TrustpilotLanding,
              data: {
                title: "I NOSTRI CLIENTI",
                description: "Qui sotto metti lo slider con le recensioni trust pilot",
                bg: true,
              },
            },
            {
              plugin: CardsLanding,
              data: {
                title: "100% PERSONE REALI",
                description:
                  "Qui fai capire che pur essendo online si interfacceranno sempre con una persona anche grazie all’assistenza clienti dedicata.",
                cards: [
                  {
                    id: 1,
                    mode: "VIDEO",
                    image: null,
                    imageSize: "cover",
                    imageHeight: 300,
                    video: "https://www.youtube.com/watch?v=D0UnqGm_miA",
                    videoHeight: 300,
                  },
                  {
                    id: 2,
                    mode: "VIDEO",
                    image: null,
                    imageSize: "cover",
                    imageHeight: 300,
                    video: "https://www.youtube.com/watch?v=D0UnqGm_miA",
                    videoHeight: 300,
                  },
                  {
                    id: 3,
                    mode: "VIDEO",
                    image: null,
                    imageSize: "cover",
                    imageHeight: 300,
                    video: "https://www.youtube.com/watch?v=D0UnqGm_miA",
                    videoHeight: 300,
                  },
                ],
              },
            },
            {
              plugin: CardsLanding,
              data: {
                title: "PARLANO DI NOI",
                bg: true,
                cards: [
                  {
                    id: 1,
                    mode: "VIDEO",
                    image: null,
                    imageSize: "cover",
                    imageHeight: 300,
                    video: "https://www.youtube.com/watch?v=D0UnqGm_miA",
                    videoHeight: 300,
                  },
                  {
                    id: 2,
                    mode: "VIDEO",
                    image: null,
                    imageSize: "cover",
                    imageHeight: 300,
                    video: "https://www.youtube.com/watch?v=D0UnqGm_miA",
                    videoHeight: 300,
                  },
                  {
                    id: 3,
                    mode: "VIDEO",
                    image: null,
                    imageSize: "cover",
                    imageHeight: 300,
                    video: "https://www.youtube.com/watch?v=D0UnqGm_miA",
                    videoHeight: 300,
                  },
                ],
              },
            },
            {
              plugin: Faq,
              data: {
                title: "DOMANDE FREQUENTI",
                cards: [
                  {
                    id: 1,
                    title: "Domanda 1",
                    description: "Risposta 1",
                  },
                  {
                    id: 2,
                    title: "Domanda 2",
                    description: "Risposta 2",
                  },
                  {
                    id: 3,
                    title: "Domanda 3",
                    description: "Risposta 3",
                  },
                  {
                    id: 4,
                    title: "Domanda 4",
                    description: "Risposta 4",
                  },
                ],
              },
            },
            {
              plugin: CtaProduct,
              data: {
                title: "LA PAGINA È FINITA",
                description: "Ultima frase che invogli l'utente ad acquistare il servizio",
                bg: true,
                buttonText: fields?.cta_text?.toUpperCase() || "",
                product: fields?.product || null,
              },
            },
          ].map(({ plugin, data }) => [
            {
              plugin,
              data,
            },
          ]),
        },
        {
          cellPlugins,
        }
      );
    default:
      return null;
  }
};

export default {};
