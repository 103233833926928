import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function CustomFaq({ faq, addFaqSteps, editFaqTitleValue, editFaqTextValue, faqHandlerRemove }) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Info Aggiuntive</MDTypography>
        {faq.map((faqApp, index) => (
          <>
            <MDBox mt={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    onChange={(e) => {
                      editFaqTitleValue(e.target.value, index);
                    }}
                    type="text"
                    label="Titolo"
                    defaultValue="ciao"
                    value={faqApp.title}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <MDBox ml={0.5} lineHeight={0} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Descrizione
                    </MDTypography>
                  </MDBox>
                  <br />
                  <MDEditor
                    value={faqApp.text}
                    onChange={(e) => {
                      editFaqTextValue(e, index);
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <br />
            <Grid item xs={12} lg={3}>
              <MDButton
                variant="gradient"
                color="error"
                size="small"
                onClick={() => {
                  faqHandlerRemove(index);
                }}
              >
                <DeleteIcon />
              </MDButton>
            </Grid>
            <br />
            <hr />
          </>
        ))}
        <br />
        <Grid item xs={12} sm={3}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              addFaqSteps({ title: "", text: "" });
            }}
          >
            + Aggiungi Faq
          </MDButton>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default CustomFaq;
