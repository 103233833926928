/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";

// @mui material components
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import { CircularProgress } from "@material-ui/core";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import TaskIcon from "@mui/icons-material/Task";
import { Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import moment from "moment";
import FormField from "pagensp/CustomComponents/FormField";

const ReactPdfEditor = lazy(() => import("components/ReactPdfEditor"));

/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
function UploadPratices({
  convertBlobToBase64,
  pratices,
  user,
  steps,
  activeStep,
  setDocFile,
  onUploadDocumentStep,
}) {
  const attachmentRef = useRef();
  const signedAttachmentRef = useRef();
  const [signatureMethod, setSignatureMethod] = useState(
    localStorage.getItem("wewelfare_signature_method") || "FIRMA_WEWELFARE"
  );
  useEffect(() => {
    localStorage.setItem("wewelfare_signature_method", signatureMethod);
    if (signatureMethod === "FIRMA_ESTERNA") {
      setSignDocumentLink(steps[activeStep]?.data?.sign_document_link || "");
    } else if (signatureMethod === "FIRMA_WEWELFARE") {
      setSignDocumentLink(
        steps[activeStep]?.data?.sign_document_link ||
          `${getBaseUrl()}/dashboard/confirm-document/${pratices?.id}/${currentStep?.id}`
      );
    }
  }, [signatureMethod]);

  const [showReactPdfEditor, setShowReactPdfEditor] = useState(false);
  const [reactPdfLoading, setReactPdfLoading] = useState(false);
  const [signDocumentLink, setSignDocumentLink] = useState(
    steps[activeStep]?.data?.sign_document_link || ""
  );
  const onUpload = async ({ originalFile, editFile, signDocumentLink, confirmSignedFile }) => {
    setReactPdfLoading(true);
    await onUploadDocumentStep({
      attachment: originalFile
        ? {
            name: `step_${steps[activeStep].data.id}.pdf`,
            data: originalFile.replace(/data:([^;]+);base64,/, ""),
          }
        : undefined,
      signed_file: editFile
        ? {
            name: `step_${steps[activeStep].data.id}_signed.pdf`,
            data: editFile.replace(/data:([^;]+);base64,/, ""),
          }
        : undefined,
      sign_document_link: signDocumentLink || undefined,
      confirm_signed_file: confirmSignedFile || undefined,
    });
    setReactPdfLoading(false);
    setShowReactPdfEditor(false);
  };

  const currentStep = useMemo(() => steps[activeStep]?.data, [steps, activeStep]);
  const isStepDone = useMemo(() => currentStep?.done, [currentStep]);
  const isSignRequired = useMemo(() => currentStep?.to_sign, [currentStep]);
  const isValidSignDocumentLink = useMemo(() => {
    if (
      signatureMethod === "FIRMA_WEWELFARE" &&
      (!currentStep?.attachment || !currentStep?.signed_file)
    ) {
      return false;
    }
    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
    return urlPattern.test(signDocumentLink);
  }, [signDocumentLink, currentStep, signatureMethod]);
  const getBaseUrl = () => {
    if (
      window.location.hostname.includes("staging") ||
      window.location.hostname.includes("localhost")
    ) {
      return {
        WeWelfare: "https://nsp-staging.click",
        "CAF Milano": "https://cafmilano-staging.click",
        "CAF Roma": "https://cafroma-staging.click",
      }[pratices.platform];
    }
    return {
      WeWelfare: "https://wewelfare.com",
      "CAF Milano": "https://cafmilano.org",
      "CAF Roma": "https://cafroma.org",
    }[pratices.platform];
  };
  const onSendDocumentLink = () => {
    if (confirm("Sei sicuro di voler inviare la mail per la conferma della firma al cliente?")) {
      onUploadDocumentStep({
        sign_document_link: signDocumentLink,
      });
    }
  };
  const onConfirmSigned = () => {
    if (
      confirm(
        "Sei sicuro di voler confermare la firma del cliente? Questa azione conclude lo step e farà passare al successivo"
      )
    ) {
      onUpload({ confirmSignedFile: true });
    }
  };
  const getConfirmIcon = (condition) =>
    condition ? (
      <CheckCircleTwoToneIcon fontSize="large" color="success" />
    ) : (
      <CancelTwoToneIcon fontSize="large" color="error" />
    );

  return (
    <MDBox>
      <MDBox width="80%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="div" fontWeight="regular" style={{ textAlign: "left" }}>
            <Grid container spacing={1}>
              {/* STATO PRATICA */}
              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <strong>Stato step</strong>
                </Grid>
                <p>Documento da caricare: {currentStep?.description}</p>
                {currentStep?.started && (
                  <p>
                    Data inizio:
                    {moment(currentStep?.started).format("DD/MM/YYYY")}
                  </p>
                )}
                {currentStep?.expected_date && (
                  <p>
                    Data di consegna prevista:
                    {moment(currentStep?.expected_date).format("DD/MM/YYYY")}
                  </p>
                )}
                {currentStep?.signed && (
                  <p>Data firma: {moment(currentStep?.signed).format("DD/MM/YYYY")}</p>
                )}
                <p>Firma richiesta dal cliente: {isSignRequired ? "Si" : "No"}</p>
              </Grid>

              <Grid item xs={12} md={6}>
                {/* STATO PRATICA PER DOCUMENTI CON FIRMA */}
                {isSignRequired && (
                  <>
                    <Grid item xs={12}>
                      <strong>Step firma</strong>
                    </Grid>
                    <Grid item xs={12}>
                      {getConfirmIcon(currentStep?.attachment)}
                      Caricato allegato da far firmare al cliente
                    </Grid>
                    {signatureMethod === "FIRMA_ESTERNA" && (
                      <>
                        <Grid item xs={12}>
                          {getConfirmIcon(currentStep?.sign_document_link)}
                          Link inviato al cliente
                        </Grid>
                        <Grid item xs={12}>
                          {getConfirmIcon(currentStep?.sign_document_link_visited)}
                          Link visitato dal cliente
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      {getConfirmIcon(currentStep?.signed_file)}
                      Preparato allegato firmato per il cliente
                    </Grid>
                    {signatureMethod === "FIRMA_WEWELFARE" && (
                      <>
                        <Grid item xs={12}>
                          {getConfirmIcon(currentStep?.sign_document_link)}
                          Link inviato al cliente
                        </Grid>
                        <Grid item xs={12}>
                          {getConfirmIcon(currentStep?.sign_document_link_visited)}
                          Link visitato dal cliente
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      {getConfirmIcon(currentStep?.signed)}
                      Conferma del documento firmato dal cliente
                    </Grid>
                  </>
                )}

                {/* STATO PRATICA PER DOCUMENTI SENZA FIRMA */}
                {!isSignRequired && (
                  <>
                    <Grid item xs={12}>
                      <strong>Step</strong>
                    </Grid>
                    <Grid item xs={12}>
                      {getConfirmIcon(currentStep?.attachment)}
                      Caricato allegato da fornire al cliente
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <hr />

            {/* ALLEGATI CARICATI DALL'OPERATORE */}
            {(currentStep?.attachment || currentStep?.signed_file) && (
              <>
                <Grid item xs={12} mt={3}>
                  <strong>Qui trovi i documenti che hai caricato</strong>
                </Grid>
                <Grid container gap={1} mt={1}>
                  {currentStep?.attachment && (
                    <MDButton
                      color="primary"
                      onClick={() => window.open(currentStep?.attachment, "_blank")}
                    >
                      Scarica allegato
                    </MDButton>
                  )}

                  {currentStep?.signed_file && (
                    <MDButton
                      color="primary"
                      onClick={() => window.open(currentStep?.signed_file, "_blank")}
                    >
                      Scarica allegato firmato
                    </MDButton>
                  )}
                </Grid>
                <hr />
              </>
            )}

            {/* SE LO STEP È IN CORSO E LA FIRMA È RICHIESTA */}
            {isSignRequired && (
              <>
                <Grid item xs={12} mt={3}>
                  <strong>Scegli il metodo con cui procedere</strong>
                </Grid>
                <Grid container gap={1} mt={1}>
                  <Tabs
                    value={signatureMethod}
                    onChange={(e, value) => setSignatureMethod(value)}
                    indicatorColor="secondary"
                  >
                    <Tab
                      id="FIRMA_WEWELFARE"
                      value="FIRMA_WEWELFARE"
                      label={
                        <MDBox
                          py={0.5}
                          px={2}
                          variant="gradient"
                          bgColor={signatureMethod === "FIRMA_WEWELFARE" ? "secondary" : ""}
                          borderRadius={5}
                        >
                          Usa Firma Wewelfare
                        </MDBox>
                      }
                    />
                    <Tab
                      id="FIRMA_ESTERNA"
                      value="FIRMA_ESTERNA"
                      label={
                        <MDBox
                          py={0.5}
                          px={2}
                          variant="gradient"
                          bgColor={signatureMethod === "FIRMA_ESTERNA" ? "secondary" : ""}
                          borderRadius={5}
                        >
                          Usa il tuo sistema di firma
                        </MDBox>
                      }
                    />
                  </Tabs>
                </Grid>

                {/* FIRMA ESTERNA - CARICAMENTO ALLEGATO */}
                {signatureMethod === "FIRMA_ESTERNA" && (
                  <>
                    <Grid item xs={12} mt={3}>
                      <strong>
                        {currentStep.attachment && getConfirmIcon(true)}
                        1) Carica manualmente il tuo allegato da far firmare al CLIENTE
                      </strong>
                    </Grid>
                    <Grid container gap={1} mt={1}>
                      <MDButton
                        color={currentStep.attachment ? "error" : "primary"}
                        onClick={() => attachmentRef.current.click()}
                      >
                        <Grid container gap={1}>
                          <FileUploadIcon fontSize="large" />
                          Upload allegato
                        </Grid>
                        <input
                          ref={attachmentRef}
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            convertBlobToBase64(e.target.files[0]).then((originalFile) =>
                              onUpload({ originalFile })
                            )
                          }
                        />
                      </MDButton>
                    </Grid>
                  </>
                )}

                {/* FIRMA WEWELFARE - EDITOR */}
                {signatureMethod === "FIRMA_WEWELFARE" && (
                  <>
                    {!currentStep?.attachment || !currentStep?.signed_file ? (
                      <Grid item xs={12} mt={5}>
                        <strong>1) Sequi questi passaggi:</strong>
                        <br />
                        <ul>
                          <li>Clicca sul pulsante &quot;Apri Editor&quot;</li>
                          <li>
                            Clicca sul pulsante &quot;Firma cliente&quot; per aggiungere la sua
                            firma all&apos;interno della pagina del documento
                          </li>
                          <li>Trascina la firma dove vuoi</li>
                          <li>Quando hai finito, clicca sul pulsante &quot;Salva&quot;</li>
                          <li>
                            Ritornerai su questa schermata e potrai inviare la richiesta di conferma
                            da parte del cliente con il pulsante che apparirà qui sotto
                          </li>
                          <li>
                            Il documento firmato sarà visibile al cliente una volta che viene
                            firmato
                          </li>
                        </ul>
                      </Grid>
                    ) : (
                      <Grid item xs={12} mt={5}>
                        <strong>
                          {getConfirmIcon(true)}
                          1) Apri l&apos;editor se vuoi caricare nuovamente l&apos;allegato e la
                          versione firmata
                        </strong>
                      </Grid>
                    )}

                    <Suspense fallback={<CircularProgress size={80} />}>
                      <Grid item xs={12}>
                        <MDButton color="primary" onClick={() => setShowReactPdfEditor(true)}>
                          Apri Editor
                        </MDButton>
                      </Grid>
                      <ReactPdfEditor
                        show={showReactPdfEditor}
                        user={user}
                        loading={reactPdfLoading}
                        onUpload={onUpload}
                        onClose={() => setShowReactPdfEditor(false)}
                      />
                    </Suspense>
                  </>
                )}

                {/* LINK FIRMA CLIENTE */}

                <Grid container spacing={1} mt={3}>
                  <Grid item xs={12}>
                    {!currentStep?.sign_document_link ? (
                      <>
                        {signatureMethod === "FIRMA_ESTERNA" && (
                          <strong>
                            2) Inserisci il link da mandare al cliente per firmare il documento.
                          </strong>
                        )}
                        {signatureMethod === "FIRMA_WEWELFARE" && (
                          <strong>
                            2) Quando hai caricato tramite Editor gli allegati, invia il link al
                            cliente tramite l&apos;apposito pulsante
                          </strong>
                        )}
                      </>
                    ) : (
                      <strong>
                        {getConfirmIcon(true)}
                        2) È stata inviata una email al cliente all&apos;indirizzo {user?.email}.
                        <br />
                        Se hai bisogno di rimandare il link o di correggerlo puoi farlo qui
                      </strong>
                    )}
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormField
                        value={signDocumentLink}
                        onChange={({ target }) => setSignDocumentLink(target.value)}
                        label="Inserisci il link da inviare al cliente per firmare il documento"
                        variant="standard"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container justifyContent="end">
                        <MDButton
                          color={currentStep?.sign_document_link ? "error" : "primary"}
                          disabled={!isValidSignDocumentLink}
                          onClick={onSendDocumentLink}
                        >
                          <Grid container gap={1}>
                            <FileUploadIcon fontSize="large" />
                            Invia link al cliente
                          </Grid>
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* FIRMA ESTERNA - CARICAMENTO ALLEGATO FIRMATO + CONFERMA FIRMA */}
            {!isStepDone && isSignRequired && signatureMethod === "FIRMA_ESTERNA" && (
              <>
                <Grid item xs={12} mt={3}>
                  <strong>
                    {currentStep.signed_file && getConfirmIcon(true)}
                    3) Carica manualmente l&apos;allegato firmato dal cliente
                  </strong>
                </Grid>
                <Grid container gap={1} mt={1}>
                  {currentStep?.attachment && (
                    <MDButton
                      color={currentStep.signed_file ? "error" : "primary"}
                      onClick={() => signedAttachmentRef.current.click()}
                      disabled={!currentStep.sign_document_link}
                    >
                      <Grid container gap={1}>
                        <FileUploadIcon fontSize="large" />
                        Upload allegato con firma
                      </Grid>
                      <input
                        ref={signedAttachmentRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          convertBlobToBase64(e.target.files[0]).then((editFile) =>
                            onUpload({ editFile })
                          )
                        }
                      />
                    </MDButton>
                  )}
                </Grid>
              </>
            )}

            {/* FIRMA ESTERNA - CARICAMENTO ALLEGATO FIRMATO + CONFERMA FIRMA */}
            {isSignRequired && (
              <>
                <Grid item xs={12} mt={3}>
                  <strong>Se serve ricaricare un documento puoi farlo qui</strong>
                </Grid>
                <Grid container gap={1} mt={1}>
                  <MDButton
                    color={currentStep.attachment ? "error" : "primary"}
                    onClick={() => attachmentRef.current.click()}
                  >
                    <Grid container gap={1}>
                      <FileUploadIcon fontSize="large" />
                      Upload allegato
                    </Grid>
                    <input
                      ref={attachmentRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        convertBlobToBase64(e.target.files[0]).then((originalFile) =>
                          onUpload({ originalFile })
                        )
                      }
                    />
                  </MDButton>
                  <MDButton
                    color={currentStep.signed_file ? "error" : "primary"}
                    onClick={() => signedAttachmentRef.current.click()}
                    disabled={!currentStep.sign_document_link}
                  >
                    <Grid container gap={1}>
                      <FileUploadIcon fontSize="large" />
                      Upload allegato con firma
                    </Grid>
                    <input
                      ref={signedAttachmentRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        convertBlobToBase64(e.target.files[0]).then((editFile) =>
                          onUpload({ editFile })
                        )
                      }
                    />
                  </MDButton>
                </Grid>
              </>
            )}

            {/* FIRMA ESTERNA - CARICAMENTO ALLEGATO FIRMATO + CONFERMA FIRMA */}
            {!isStepDone && isSignRequired && signatureMethod === "FIRMA_ESTERNA" && (
              <>
                <Grid item xs={12} mt={3}>
                  <strong>
                    {currentStep?.signed && getConfirmIcon(true)}
                    4) Conferma manualmente che il cliente ha firmato il documento
                  </strong>
                </Grid>
                <Grid container gap={1} mt={1}>
                  <MDButton
                    color="primary"
                    onClick={onConfirmSigned}
                    disabled={!currentStep?.signed_file || currentStep?.signed}
                  >
                    <Grid container gap={1}>
                      <TaskIcon fontSize="large" />
                      Conferma firma cliente
                    </Grid>
                  </MDButton>
                </Grid>
              </>
            )}

            {/* FIRMA ESTERNA - CARICAMENTO ALLEGATO FIRMATO + CONFERMA FIRMA */}
            {!isStepDone && isSignRequired && signatureMethod === "FIRMA_WEWELFARE" && (
              <>
                <Grid item xs={12} mt={3}>
                  <strong>
                    {currentStep?.signed && getConfirmIcon(true)}
                    3) Se ci sono problemi ed è necessario confermare manualmente la firma del
                    cliente clicca il pulsante qui sotto (solo caso di emergenza)
                  </strong>
                </Grid>
                <Grid container gap={1} mt={1}>
                  <MDButton
                    color="error"
                    onClick={onConfirmSigned}
                    disabled={!currentStep?.signed_file || currentStep?.signed}
                  >
                    <Grid container gap={1}>
                      <TaskIcon fontSize="large" />
                      Conferma firma cliente
                    </Grid>
                  </MDButton>
                </Grid>
              </>
            )}

            {/* SE LO STEP È IN CORSO E LA FIRMA NON È RICHIESTA */}
            {!isSignRequired && (
              <>
                <input
                  type="file"
                  onChange={(e) =>
                    convertBlobToBase64(e.target.files[0]).then((res) => setDocFile(res))
                  }
                />
              </>
            )}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default UploadPratices;
