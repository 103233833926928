import styled from "styled-components";

export const Heading1 = styled.h1`
  font-size: 20px;
  color: black;
`;

export const Heading2 = styled.h2`
  font-size: 20px;
  color: black;
`;

export const Heading3 = styled.h3`
  font-size: 20px;
  color: black;
`;

export const Heading4 = styled.h4`
  font-size: 20px;
  color: black;
`;

export const Heading5 = styled.h5`
  font-size: 20px;
  color: black;
`;

export const Heading6 = styled.h6`
  font-size: 20px;
  color: black;
`;

export const Paragraph = styled.p``;

export default [
  {
    Renderer: ({ data }) => <Heading1>{data.content}</Heading1>,
    id: "heading-h1",
    title: "Elemento H1",
    description: "Tag HTML H1",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
  {
    Renderer: ({ data }) => <Heading2>{data.content}</Heading2>,
    id: "heading-h2",
    title: "Elemento H2",
    description: "Tag HTML H2",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
  {
    Renderer: ({ data }) => <Heading3>{data.content}</Heading3>,
    id: "heading-h3",
    title: "Elemento H3",
    description: "Tag HTML H3",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
  {
    Renderer: ({ data }) => <Heading4>{data.content}</Heading4>,
    id: "heading-h4",
    title: "Elemento H4",
    description: "Tag HTML H4",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
  {
    Renderer: ({ data }) => <Heading5>{data.content}</Heading5>,
    id: "heading-h5",
    title: "Elemento H5",
    description: "Tag HTML H5",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
  {
    Renderer: ({ data }) => <Heading6>{data.content}</Heading6>,
    id: "heading-h6",
    title: "Elemento H6",
    description: "Tag HTML H6",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
  {
    Renderer: ({ data }) => <Paragraph>{data.content}</Paragraph>,
    id: "paragrah",
    title: "Paragrafo",
    description: "Tag HTML p",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          content: {
            type: "string",
            default: "Contenuto",
          },
        },
        required: ["content"],
      },
    },
  },
];
