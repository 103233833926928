/* eslint-disable react/jsx-no-target-blank */

import useTrustBox from "hooks/useTrustBox";
import { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import FormField from "pagensp/CustomComponents/FormField";
import Select from "components/Select";
import { CardsContainer, Container, Title, Description } from "./style";

const TrustBox = ({ DataTemplateId, DataStyleHeight, DataStars, DataThem, dataWidth }) => {
  // Crea un riferimento all'elemento <div> che rappresenterà il TrustBox
  const ref = useRef(null);
  useEffect(() => {
    // Se window.Trustpilot è disponibile significa che abbiamo bisogno di caricare il TrustBox dal nostro riferimento.
    // Se non lo è, significa che lo script che hai incollato nel <head /> non è stato ancora caricato.
    // Quando lo sarà, il TrustBox verrà automaticamente caricato.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="it-IT"
      data-template-id={DataTemplateId}
      data-style-font-size="10px"
      data-style-width={dataWidth}
      data-businessunit-id="63b72a132965cd4e3ea7cab3"
      data-style-height={DataStyleHeight}
      data-theme={DataThem}
      data-stars={DataStars}
      data-review-languages="it"
    >
      <>
        <a href="https://it.trustpilot.com/review/wewelfare.com" target="_blank" rel="noopener">
          {" "}
          Trustpilot
        </a>
      </>
    </div>
  );
};

function Component({ title, description, bg, children }) {
  const {
    config: { theme, width },
  } = useTrustBox();
  return (
    <Container bg={bg}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <CardsContainer>
        <TrustBox
          DataStyleHeight="300"
          DataTemplateId="54ad5defc6454f065c28af8b"
          DataStars="4,5"
          DataThem={theme}
          dataWidth={width}
        />
      </CardsContainer>
      {children}
    </Container>
  );
}

const Form = ({ onChange, data }) => (
  <Grid container>
    <Grid item xs={12} mb={1}>
      <FormField
        type="text"
        label="Titolo"
        value={data.title}
        onChange={({ target }) => onChange({ ...data, title: target.value })}
      />
      <FormField
        type="text"
        label="Descrizione"
        value={data.description}
        onChange={({ target }) => onChange({ ...data, description: target.value })}
      />
      <Select
        label="Sfondo azzurro"
        options={[
          { label: "Si", value: true },
          { label: "No", value: false },
        ]}
        value={data.bg}
        onChange={({ value }) => onChange({ ...data, bg: value })}
        style={{
          zIndex: 9999,
        }}
      />
    </Grid>
  </Grid>
);

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "trustpilot-landing",
  title: "Landing Trustpilot",
  description: "Widget con recensioni",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
