import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import UploadSingle from "components/UploadSingle";
import FormField from "pagensp/CustomComponents/FormField";
import { CardsContainer, Card, Image } from "./style";

function Component({ cards }) {
  return (
    <CardsContainer>
      {cards?.map((card) => (
        <Card key={card.id}>
          {card.mode === "IMAGE" && card.image && (
            <Image
              src={card.image}
              alt={card.title}
              height={card.imageHeight}
              size={card.imageSize}
            />
          )}
          {card.mode === "VIDEO" && card.video && (
            <iframe
              width="100%"
              height={card.videoHeight}
              title={card.title}
              src={`https://www.youtube.com/embed/${card.video}`}
            />
          )}
          <h3>{card.title}</h3>
          <p>{card.description}</p>
        </Card>
      ))}
    </CardsContainer>
  );
}

const Form = ({ onChange, data }) => {
  const onAddCard = () => {
    const newCards = data.cards ? [...data.cards] : [];
    newCards.push({
      id: Math.floor(Math.random() * 99999),
      mode: "IMAGE",
      image: null,
      imageSize: "cover",
      imageHeight: 300,
      video: null,
      videoHeight: 300,
      title: "",
      description: "",
    });
    onChange({ ...data, cards: newCards, activeTab: 0 });
  };

  const onEditCard = (index, key, value) => {
    const newCards = [...data.cards];
    newCards[index][key] = value;
    onChange({ ...data, cards: [...newCards] });
  };

  const onDeleteCard = (index) =>
    onChange({
      ...data,
      cards: [...data.cards.filter((item, i) => i !== index)],
      activeTab: 0,
    });

  const card = data?.cards?.length > 0 ? data.cards[data.activeTab || 0] : null;

  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <MDButton variant="gradient" color="info" onClick={onAddCard}>
          Aggiungi Card
        </MDButton>
      </Grid>

      <Grid item xs={12} mb={1}>
        {data?.cards?.map((item, index) => (
          <MDButton
            variant="gradient"
            color={data.activeTab === index ? "primary" : "secondary"}
            onClick={() => onChange({ ...data, activeTab: index })}
          >
            Card {index + 1}
          </MDButton>
        ))}
      </Grid>

      {card && (
        <Grid item xs={12} key={card.id}>
          <FormField
            type="text"
            label="Titolo"
            value={card.title}
            onChange={({ target }) => onEditCard(data.activeTab, "title", target.value)}
          />
          <FormField
            type="text"
            multiline
            rows={3}
            label="Descrizione"
            value={card.description}
            onChange={({ target }) => onEditCard(data.activeTab, "description", target.value)}
          />

          <MDTypography>Scegli se inserire un immagine o un video</MDTypography>
          <Grid container>
            <Grid item xs={12}>
              <MDButton
                variant="gradient"
                color={card.mode === "IMAGE" ? "primary" : "secondary"}
                onClick={() => onEditCard(data.activeTab, "mode", "IMAGE")}
              >
                Immagine
              </MDButton>
              <MDButton
                variant="gradient"
                color={card.mode === "VIDEO" ? "primary" : "secondary"}
                onClick={() => onEditCard(data.activeTab, "mode", "VIDEO")}
              >
                Video
              </MDButton>
            </Grid>
          </Grid>

          {card.mode === "VIDEO" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormField
                  type="text"
                  label="Youtube Code (esempio: tgbNymZ7vqY)"
                  value={card.video}
                  onChange={({ target }) => onEditCard(data.activeTab, "video", target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  type="number"
                  label="Altezza iframe video"
                  value={card.videoHeight}
                  onChange={({ target }) => onEditCard(data.activeTab, "videoHeight", target.value)}
                />
              </Grid>
            </Grid>
          )}

          {card.mode === "IMAGE" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormField
                  type="number"
                  label="Altezza immagine"
                  value={card.imageHeight}
                  onChange={({ target }) => onEditCard(data.activeTab, "imageHeight", target.value)}
                />

                <MDTypography>Adattamento immagine</MDTypography>
                <MDButton
                  variant="gradient"
                  color={card.imageSize === "cover" ? "primary" : "secondary"}
                  onClick={() => onEditCard(data.activeTab, "imageSize", "cover")}
                >
                  Riempi
                </MDButton>
                <MDButton
                  variant="gradient"
                  color={card.imageSize === "contain" ? "primary" : "secondary"}
                  onClick={() => onEditCard(data.activeTab, "imageSize", "contain")}
                >
                  Adatta
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <UploadSingle
                  item={card.image}
                  onUpdate={({ url }) => onEditCard(data.activeTab, "image", url)}
                  accept=".jpg,.jpeg,image/jpg,image/jpeg"
                />
              </Grid>
            </Grid>
          )}
          <MDButton variant="gradient" color="error" onClick={() => onDeleteCard(data.activeTab)}>
            Rimuovi
          </MDButton>
        </Grid>
      )}
    </Grid>
  );
};

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "cards",
  title: "Cards",
  description: "Cards affiancate",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
