import { useState, useEffect } from "react";

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const selectPage = (e) => {
    if (+e.target.value > totalPages) {
      setCurrentPage(totalPages);
    } else if (+e.target.value < 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(+e.target.value);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((old) => old + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((old) => old - 1);
    }
  };

  const firstPage = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const lastPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const calculateTotalPages = (totalPostsF, postsPerPageF) => {
    const totalPagesTemp = Math.ceil(totalPosts / postsPerPage);
    setTotalPages(totalPagesTemp);
  };

  useEffect(() => {
    calculateTotalPages(totalPosts, postsPerPage);
  }, [totalPosts, postsPerPage]);

  return {
    currentPage,
    postsPerPage,
    totalPages,
    setTotalPages,
    totalPosts,
    paginate,
    nextPage,
    prevPage,
    firstPage,
    lastPage,
    setPostPerPage,
    setTotalPosts,
    selectPage,
    setCurrentPage,
  };
};

export default usePagination;
