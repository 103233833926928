import styled from "styled-components";

const MOBILE_BREAKPOINT = 800;
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  ${({ bg }) => (bg ? `background: #ebebff;` : ``)}
  padding: 10px;
  border-radius: 20px;
  margin: 20px 0;
  border: 4px solid black;
`;

export const Title = styled.h3`
  font-size: 32px !important;
  font-weight: 900;
  color: black;
`;

export const Timer = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Number = styled.h5`
  position: relative;
  font-size: 8vw;
  font-weight: 400;
  color: white;
  background: var(--bs-tertiary);
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 60px;
  min-width: 8vw;
  font-family: "Share Tech", sans-serif;
`;

export const Label = styled.div`
  background: white;
  color: black;
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  padding: 10px;
`;

export const CardsContainer = styled.section`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const Button = styled.div`
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  letter-spacing: 0;
  background-size: 150%;
  background-position-x: 25%;
  position: relative;
  overflow: hidden;
  box-shadow: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 15px 10px;
  line-height: 1.3;
  font-weight: 900;
  border-radius: 36rem !important;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  transition: all 0.5s ease-in;
  text-transform: none;
  font-size: 16px;
  color: white !important;
  background-color: #0524dd !important;
  border: 3px solid #0524dd !important;
  opacity: 1 !important;

  &:hover {
    color: #0524dd !important;
    background-color: white !important;
  }
`;

export default {};
