import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

import styleModal from "assets/theme/base/modalconfig";

import Grid from "@mui/material/Grid";

function ModalPraticesdataTableData({ open, handleClose, handleLoadData, idTag }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  // const [type, setType] = useState({ label: "", value: "" });

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();
  const { sendRequest: sendSinglePersonalFieldRequest, isLoading: isSinglePersonalFieldLoading } =
    useHttp();

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e);
  };

  const managePersonalFieldData = ({ name: nameF, description: textF }) => {
    setName(nameF);
    setDescription(textF);
  };

  useEffect(() => {
    if (open && idTag) {
      sendSinglePersonalFieldRequest({
        methodH: "GET",
        url: `/tags/${idTag}`,
        manageData: (e) => {
          managePersonalFieldData(e.data); // la funzione per la mappatura dei dati
        },
      });
    } else if (open && idTag === 0) {
      setName("");
      setDescription("");
    }
  }, [idTag, open]);

  const saveHandler = () => {
    if (open && idTag === 0) {
      const data = {
        data: {
          name,
          description,
        },
      };
      sendSaveRequest({
        methodH: "POST",
        url: `/tags`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
        handleClose();
      });
    } else if (open && idTag) {
      const data = {
        data: {
          name,
          description,
        },
      };
      sendSaveRequest({
        methodH: "PUT",
        url: `/tags/${idTag}`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
        handleClose();
      });
    }
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={styleModal} pt={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <FormField
                type="text"
                label="Nome Campo"
                defaultValue=""
                value={name}
                onChange={nameHandler}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Testo Faq
                </MDTypography>
              </MDBox>
              <MDEditor
                value={description}
                onChange={(e) => {
                  descriptionHandler(e);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                Inserisci
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Fade>
    </Modal>
  );
}
export default ModalPraticesdataTableData;
