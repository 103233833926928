import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";

import { Dropzone, FileItem } from "@dropzone-ui/react";

import styleModal from "assets/theme/base/modalconfig";

import Grid from "@mui/material/Grid";

function ModalCategory({ open, handleClose, handleLoadData, idCat }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [imgCat, setImgCat] = useState({ name: "", data: "" });
  const [files, setFiles] = useState([]);

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();
  const { sendRequest: sendSingleCatRequest, isLoading: isSingleCatLoading } = useHttp();

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e);
  };

  const imgHandlerRemove = () => {
    setImg("");
  };

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
    const filesx = incommingFiles[0].file;
    const reader = new FileReader();
    reader.readAsDataURL(filesx);
    reader.onload = (e) => {
      setImgCat({
        name: filesx.name,
        data: e.target.result.replace("data:image/jpeg;base64,", ""),
      });
    };
  };

  const manageCategoryData = ({ name: nameF, description: descriptionF, img: imgF }) => {
    setName(nameF);
    setDescription(descriptionF);
    setImg(imgF);
  };

  useEffect(() => {
    if (open && idCat) {
      sendSingleCatRequest({
        methodH: "GET",
        url: `/documents-categories/${idCat}`,
        manageData: (e) => {
          manageCategoryData(e.data); // la funzione per la mappatura dei dati
        },
      });
    } else if (open && idCat === 0) {
      setName("");
      setDescription("");
      setImgCat({});
    }
  }, [idCat, open]);

  const saveHandler = () => {
    if (open && idCat === 0) {
      const data = imgCat.name
        ? {
            data: {
              name,
              description,
              img: imgCat,
            },
          }
        : {
            data: {
              name,
              description,
            },
          };
      sendSaveRequest({
        methodH: "POST",
        url: `/documents-categories`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    } else if (open && idCat) {
      const data = imgCat.name
        ? {
            data: {
              name,
              // description,
              // img: imgCat,
            },
          }
        : {
            data: {
              name,
              // description,
            },
          };
      sendSaveRequest({
        methodH: "PUT",
        url: `/documents-categories/${idCat}`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={styleModal} pt={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <FormField
                type="text"
                label="Nome Categoria"
                defaultValue=""
                value={name}
                onChange={nameHandler}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Descrivi la categoria
                </MDTypography>
              </MDBox>
              <MDEditor
                value={description}
                onChange={(e) => {
                  descriptionHandler(e);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Immagine Principale
                </MDTypography>
                <br />
              </MDBox>
              {!img ? (
                <>
                  <br />
                  <MDTypography variant="button">
                    Utilizza una immagine non piu grande di 2Mb
                  </MDTypography>
                  <Dropzone
                    onChange={updateFiles}
                    value={files}
                    maxFiles={1}
                    maxFileSize={2998000}
                    accept=".jpg,.jpeg,.png,image/*"
                  >
                    {files.map((file) => (
                      <FileItem {...file} preview />
                    ))}
                  </Dropzone>
                </>
              ) : (
                <>
                  <GestUploadImg UploadImage={img} imgHandlerRemove={imgHandlerRemove} />
                </>
              )}
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                Inserisci
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Fade>
    </Modal>
  );
}
export default ModalCategory;
