import slate from "@react-page/plugins-slate";

// you can further customize slate to your needs
const CustomSlate = slate((def) => ({
  ...def, // this is the default configuration
  title: "Paragrafo con Editor",
  description: "Testo personalizzabile",
}));

export default CustomSlate;
