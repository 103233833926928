/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";
import ModalCategory from "./components/ModalCategory";

// Data
import dataTableData from "./data/CategorydataTableData";

// import SidenavProduct from "pagensp/gestproduct/productarchive/components/Sidenav";

function CategoryBlogNsp() {
  const [categoryData, setCategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [idCat, setIdCat] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleOpenNew = () => {
    setOpen(true);
    setIdCat(0);
  };
  const handleClose = () => setOpen(false);
  // Load data
  // hook per chiamare API
  const {
    sendRequest: sendCategoryRequest,
    isLoading: isProductsLoading,
    error: productsError,
  } = useHttp();

  // Creao il datatable con la formattazione della tab importata
  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...categoryData] });

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadData = () => {
    sendCategoryRequest({
      url: "/categories-blog",
      manageData: (e) => {
        manageCategoryData(e.list); // la funzione per la mappatura dei dati
      },
    });
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...categoryData] });
  }, [categoryData]);

  // Riassegno le variabile da api
  const manageCategoryData = (list) => {
    const appData = list.map((item) => ({
      nome: item.name,
      descrizione: item.description,
      delete: {
        action: () => {
          handleDelData(item.id);
        },
      },
      edit: {
        action: () => {
          setIdCat(item.id);
          handleOpen();
        },
      },
    }));
    setCategoryData([...appData]);
  };

  useEffect(() => {
    handleLoadData();
  }, []);
  // End Load data

  // Delete
  const {
    sendRequest: sendDelCategoryRequest,
    isLoading: isDelProductsLoading,
    error: delProductsError,
  } = useHttp();

  const handleDelData = (idApp) => {
    if (window.confirm(`Sei sicuro di voler eliminare questa Categoria?`)) {
      sendDelCategoryRequest({
        methodH: "DELETE",
        url: `/categories-blog/${idApp}`,
      }).then(() => {
        handleLoadData();
      });
    }
  };
  // End Delete
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              Elenco Categorie
              <MDButton variant="gradient" color="info" sx={{ m: 2 }} onClick={handleOpenNew}>
                <Icon>add</Icon>&nbsp; Nuova categoria
              </MDButton>
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <DataTable handleOpen={handleOpen} table={dataTable} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ModalCategory
        open={open}
        handleClose={handleClose}
        handleLoadData={handleLoadData}
        idCat={idCat}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CategoryBlogNsp;
