import { useState } from "react";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import FormField from "pagensp/CustomComponents/FormField";
import Select from "components/Select";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CardsContainer, Card, Container, Title, Description, Answer, Question } from "./style";

function Component({ title, description, cards, bg }) {
  const [show, setShow] = useState(null);
  return (
    <Container bg={bg}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      <CardsContainer>
        {cards?.map((card) => (
          <Card key={card.id} selected={show === card.id}>
            <Question onClick={() => setShow(show !== card.id ? card.id : null)}>
              <span>{card.title}</span>
              {show === card.id ? (
                <RemoveIcon style={{ fontSize: "45px !important" }} />
              ) : (
                <AddIcon style={{ fontSize: "45px !important" }} />
              )}
            </Question>
            <Answer show={show === card.id}>{card.description}</Answer>
          </Card>
        ))}
      </CardsContainer>
    </Container>
  );
}

const Form = ({ onChange, data }) => {
  const onAddCard = () => {
    const newCards = data.cards ? [...data.cards] : [];
    newCards.push({
      id: Math.floor(Math.random() * 99999),
      title: "",
      description: "",
    });
    onChange({ ...data, cards: newCards, activeTab: 0 });
  };

  const onEditCard = (index, key, value) => {
    const newCards = [...data.cards];
    newCards[index][key] = value;
    onChange({ ...data, cards: [...newCards] });
  };

  const onDeleteCard = (index) =>
    onChange({
      ...data,
      cards: [...data.cards.filter((item, i) => i !== index)],
      activeTab: 0,
    });

  const card = data?.cards?.length > 0 ? data.cards[data.activeTab || 0] : null;

  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <FormField
          type="text"
          label="Titolo"
          value={data.title}
          onChange={({ target }) => onChange({ ...data, title: target.value })}
        />
        <FormField
          type="text"
          label="Descrizione"
          value={data.description}
          onChange={({ target }) => onChange({ ...data, description: target.value })}
        />
        <Select
          label="Sfondo azzurro"
          options={[
            { label: "Si", value: true },
            { label: "No", value: false },
          ]}
          value={data.bg}
          onChange={({ value }) => onChange({ ...data, bg: value })}
          style={{
            zIndex: 9999,
          }}
        />
      </Grid>

      <Grid item xs={12} mb={1}>
        <MDButton variant="gradient" color="info" onClick={onAddCard}>
          Aggiungi Card
        </MDButton>
      </Grid>

      <Grid item xs={12} mb={1}>
        {data?.cards?.map((item, index) => (
          <MDButton
            variant="gradient"
            color={data.activeTab === index ? "primary" : "secondary"}
            onClick={() => onChange({ ...data, activeTab: index })}
          >
            Card {index + 1}
          </MDButton>
        ))}
      </Grid>

      {card && (
        <Grid item xs={12} key={card.id}>
          <FormField
            type="text"
            label="Titolo"
            value={card.title}
            onChange={({ target }) => onEditCard(data.activeTab, "title", target.value)}
          />
          <FormField
            type="text"
            multiline
            rows={3}
            label="Descrizione"
            value={card.description}
            onChange={({ target }) => onEditCard(data.activeTab, "description", target.value)}
          />
          <MDButton variant="gradient" color="error" onClick={() => onDeleteCard(data.activeTab)}>
            Rimuovi
          </MDButton>
        </Grid>
      )}
    </Grid>
  );
};

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "faq",
  title: "FAQ",
  description: "Crea slot domande/risposte",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
