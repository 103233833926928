// Create new window and set each url to start the download without have the popup blocking issue on browsers
export default function downloadMultipleFiles(documents, delay = 700) {
  const newTab = window.open("", "_blank", "width=200,height=200");
  newTab.document.body.innerHTML = "<p>Download dei files in corso...</p>";
  documents?.forEach((url, index) =>
    setTimeout(() => {
      newTab.location.href = url;
      newTab.document.body.innerHTML = `<p>Download dei files in corso: ${(
        (index / documents.length) *
        100
      ).toFixed(2)}%</p>`;
    }, index * delay)
  );
  setTimeout(() => {
    newTab.document.body.innerHTML = "<p>Download dei files in corso: 100%</p>";
    setTimeout(() => newTab.close(), delay);
  }, documents.length * delay);
}
