import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useHttp from "hooks/use-http";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";

// import SidenavProduct from "pagensp/gestproduct/productarchive/componets/Sidenav";

function ZoomConfirm() {
  const { id: pId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const state = queryParams.get("state");
  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();
  const history = useNavigate();
  console.log("Parametri", code, state, pId);

  useEffect(() => {
    sendSaveRequest({
      methodH: "POST",
      url: `/orders/zoom/${pId}`,
      bodyH: { code, state },
      manageData: (e) => {
        history(`/dettagliopratica/${pId}`);
      },
    });
  }, []);

  // End Delete
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Zoom Confirm Elaborazione Attendere...</MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default ZoomConfirm;
