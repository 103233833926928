/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import usePagination from "hooks/use-pagination";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import Card from "@mui/material/Card";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Anaytics dashboard components
import DataTable from "commoncomponent/Tables/DataTable";
import Pagination from "components/Pagination";
import Preloader from "components/Preloader";
import useHttp from "hooks/use-http";

// Images
import { CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";
import Select from "components/Select";
import { platforms } from "config";
import AuthContext from "pagensp/store/auth-context";
import dataTableData from "./data/CountdataTableData";
import dataTableDataP from "./data/PraticesdataTableData";

/* eslint-disable no-restricted-globals */
function AnalyticsNSP() {
  const userData = useContext(AuthContext);
  const { sendRequest: sendTagRequest, isLoading: isLoadingCounter } = useHttp();

  // Creao il datatable con la formattazione della tab importata
  const [countTotal, setCountTotal] = useState({});
  const [count, setCount] = useState({});

  const [countData, setCountData] = useState([]);
  const [dataTable, setDataTable] = useState({
    ...dataTableData,
    rows: [...countData],
  });

  const [inizio, setInizio] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [fine, setFine] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [platform, setPlatform] = useState("");
  const [showCounters, setShowCounters] = useState(false);
  const dateLabel = useMemo(
    () => `Dal ${moment(inizio).format("DD/MM/YYYY")} al ${moment(fine).format("DD/MM/YYYY")}`,
    [inizio, fine]
  );

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleCountTotal = (inizioFx, fineFx, platformName) => {
    if (inizioFx && fineFx) {
      setShowCounters(false);
      setCountTotal({});
      setCount({});
      setCountData([]);
      sendTagRequest({
        url: `/orders/counters-backend?from=${inizioFx}&to=${fineFx}&platform=${encodeURI(
          platformName
        )}`,
        cache: false,
        manageData: (e) => {
          setCountTotal(e); // la funzione per la mappatura dei dati
          setCount(e); // la funzione per la mappatura dei dati
          manageCountData(e.products);
          setShowCounters(true);
        },
      });
    }
  };

  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...countData] });
  }, [countData]);

  // Riassegno le variabile da api
  const manageCountData = (list) => {
    setCountData(
      list.map((item) => ({
        ...item,
        totals: {
          ...item.totals,
          all_paid: {
            count:
              item.totals.pending_paid.count +
              item.totals.refund.count +
              item.totals.active.count +
              item.totals.done.count,
            amount:
              item.totals.pending_paid.amount +
              item.totals.refund.amount +
              item.totals.active.amount +
              item.totals.done.amount,
          },
        },
      }))
    );
  };

  const convertAmount = (amount) =>
    new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);

  // Inizio riepilogo pratiche in dasboard
  const [dataTableP, setDataTableP] = useState({
    ...dataTableData,
    rows: [...countData],
  });
  const [tagData, setTag] = useState([]);
  const [open, setOpen] = useState(false);
  const { sendRequest: sendTagRequestPratiche, isLoading: isTagLoading } = useHttp();
  const {
    currentPage: page,
    paginate: setPage,
    totalPages,
    // calculateTotalPages,
    setTotalPosts,
    nextPage,
    firstPage,
    lastPage,
    prevPage,
    selectPage,
  } = usePagination();
  const handleOpen = () => setOpen(true);
  const paginationCallback = (pag) => (e) => setPage(pag);
  useEffect(() => {
    handlePraticheLoadData();
  }, [page]);
  useEffect(() => {
    setDataTableP({ ...dataTableDataP, rows: [...tagData] });
  }, [tagData]);

  const handlePraticheLoadData = () => {
    sendTagRequestPratiche({
      url: `/orders?per_page=25&page=${page}&sort=[["id","DESC"]]&filters=[["deleted_at","=",null]]`,
      manageData: (e) => {
        manageTagData(e.list);
        setTotalPosts(e.tot);
      },
    });
  };

  const onDeleteOrder = (id) => {
    if (
      !confirm(
        `Sei sicuro di voler eliminare la pratica ${id} ? Se cancellato non sarà più visibile sia sulla dashboard utente che su gestionale. Non verrà neanche più contato nelle statistiche.`
      )
    )
      return;
    sendTagRequestPratiche({
      url: `/orders/${id}`,
      methodH: "PUT",
      bodyH: {
        data: { deleted_at: moment().format("YYYY-MM-DD HH:mm:ss") },
      },
      manageData: handlePraticheLoadData,
    });
  };

  const manageTagData = (list) => {
    const appData = list.map((item) => ({
      ...item,
      id: item?.id,
      product: item?.product.text,
      user: item?.user.text,
      created: item?.created,
      paid: item?.paid,
      refund: item?.refund,
      transaction: item?.transaction,
      paidmethod: item?.payment_method,
      signedfile: item?.signed_file,
      operator: item.operator !== null ? item.operator.text : null,
      expectedDate: item?.expected_date,
      order: item?.transaction,
      fattura: item?.invoice,
      statop: { ...item, list },
      statoSdi: item,
      clientProduct: {
        client: item?.user.text,
        product: item?.product.text,
        operator: item.operator !== null ? item.operator.text : null,
        platform: item.platform,
        payment_method: item.payment_method,
      },
      pratica: {
        idPratica: item?.id,
        idOrdine: item?.transaction,
      },
      date: {
        acquisto: item?.created,
        consegna: item?.expectedDate,
        updatedAt: item?.updated_at,
        idPratica: item?.id,
        idOrdine: item?.transaction,
      },
      actions: ["admin"].includes(userData.typeUser)
        ? {
            delete: {
              action: () => onDeleteOrder(item?.id),
            },
            checkPayment: {
              action: {
                stripe: () =>
                  window.open(
                    `https://dashboard.stripe.com/search?query=${item?.transaction}`,
                    "_blank"
                  ),
                paypal: false,
                bank_transfer: false,
              }[item?.payment_method],
            },
          }
        : {},
    }));
    setTag([...appData]);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {["admin"].includes(userData.typeUser) && (
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item>
              <MDTypography component="div" m={2}>
                <div>Data inizio</div>
                <input
                  type="date"
                  className="mt-3"
                  value={moment(inizio).format("YYYY-MM-DD")}
                  onChange={({ target }) => setInizio(moment(target.value).format("YYYY-MM-DD"))}
                />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDTypography component="div" m={2}>
                <div>Data fine</div>
                <input
                  type="date"
                  className="mt-3"
                  value={moment(fine).format("YYYY-MM-DD")}
                  onChange={({ target }) => setFine(moment(target.value).format("YYYY-MM-DD"))}
                />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDTypography component="div" m={2} style={{ width: 200 }}>
                <div>Piattaforma</div>
                <Select
                  options={[
                    {
                      label: "Tutti",
                      value: "",
                    },
                    ...platforms,
                  ]}
                  value={platform}
                  onChange={({ value }) => setPlatform(value)}
                />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDTypography component="div" m={2}>
                <Preloader
                  isLoading={isLoadingCounter}
                  fallback={<CircularProgress className="mt-5" />}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    className="mt-5"
                    onClick={() => handleCountTotal(inizio, fine, platform)}
                    fullWidth
                  >
                    Calcola
                  </MDButton>
                </Preloader>
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {showCounters && (
        <>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE TOTALI */}
                  <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title={`Pratiche Totali (${convertAmount(countTotal?.all?.amount || 0)})`}
                    count={`${countTotal?.all?.count || 0} `}
                    percentage={{
                      color: "success",
                      // amount: `${count?.all?.count || 0}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_all_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.all?.count
                              ? (
                                  (countTotal?.platform_all_wewelfare?.count /
                                    countTotal?.all?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_all_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_all_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.all?.count
                              ? (
                                  (countTotal?.platform_all_cafmilano?.count /
                                    countTotal?.all?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_all_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_all_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.all?.count
                              ? (
                                  (countTotal?.platform_all_cafroma?.count /
                                    countTotal?.all?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_all_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_all_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.all?.count
                              ? (
                                  (countTotal?.payment_all_stripe?.count / countTotal?.all?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_all_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_all_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.all?.count
                              ? (
                                  (countTotal?.payment_all_paypal?.count / countTotal?.all?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_all_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_all_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.all?.count
                              ? (
                                  (countTotal?.payment_all_bank_transfer?.count /
                                    countTotal?.all?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_all_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE SOSPESO */}
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title={`Pratiche in sospeso non pagate (${convertAmount(
                      countTotal?.pending_unpaid?.amount || 0
                    )})`}
                    count={countTotal?.pending_unpaid?.count || 0}
                    percentage={{
                      color: "success",
                      // amount: `${count?.pending?.count || 0}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_pending_unpaid_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_unpaid?.count
                              ? (
                                  (countTotal?.platform_pending_unpaid_wewelfare?.count /
                                    countTotal?.pending_unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_pending_unpaid_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_pending_unpaid_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_unpaid?.count
                              ? (
                                  (countTotal?.platform_pending_unpaid_cafmilano?.count /
                                    countTotal?.pending_unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_pending_unpaid_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_pending_unpaid_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_unpaid?.count
                              ? (
                                  (countTotal?.platform_pending_unpaid_cafroma?.count /
                                    countTotal?.pending_unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_pending_unpaid_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_pending_unpaid_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_unpaid?.count
                              ? (
                                  (countTotal?.payment_pending_unpaid_stripe?.count /
                                    countTotal?.pending_unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_pending_unpaid_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_pending_unpaid_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending?.count
                              ? (
                                  (countTotal?.payment_pending_unpaid_paypal?.count /
                                    countTotal?.pending_unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_pending_unpaid_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_pending_unpaid_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_unpaid?.count
                              ? (
                                  (countTotal?.payment_pending_unpaid_bank_transfer?.count /
                                    countTotal?.pending_unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_pending_unpaid_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE SOSPESO */}
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title={`Pratiche in sospeso pagate (${convertAmount(
                      countTotal?.pending_paid?.amount || 0
                    )})`}
                    count={countTotal?.pending_paid?.count || 0}
                    percentage={{
                      color: "success",
                      // amount: `${count?.pending?.count || 0}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_pending_paid_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_paid?.count
                              ? (
                                  (countTotal?.platform_pending_paid_wewelfare?.count /
                                    countTotal?.pending_paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_pending_paid_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_pending_paid_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_paid?.count
                              ? (
                                  (countTotal?.platform_pending_paid_cafmilano?.count /
                                    countTotal?.pending_paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_pending_paid_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_pending_paid_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_paid?.count
                              ? (
                                  (countTotal?.platform_pending_paid_cafroma?.count /
                                    countTotal?.pending_paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_pending_paid_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_pending_paid_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_paid?.count
                              ? (
                                  (countTotal?.payment_pending_paid_stripe?.count /
                                    countTotal?.pending_paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_pending_paid_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_pending_paid_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending?.count
                              ? (
                                  (countTotal?.payment_pending_paid_paypal?.count /
                                    countTotal?.pending_paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_pending_paid_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_pending_paid_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.pending_paid?.count
                              ? (
                                  (countTotal?.payment_pending_paid_bank_transfer?.count /
                                    countTotal?.pending_paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_pending_unpaid_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE IN CORSO */}
                  <ComplexStatisticsCard
                    color="success"
                    icon="store"
                    title={`Pratiche in corso (${convertAmount(countTotal?.active?.amount || 0)})`}
                    count={countTotal?.active?.count || 0}
                    percentage={{
                      color: "success",
                      // amount: `${count?.active?.count || 0}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_active_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.active?.count
                              ? (
                                  (countTotal?.platform_active_wewelfare?.count /
                                    countTotal?.active?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_active_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_active_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.active?.count
                              ? (
                                  (countTotal?.platform_active_cafmilano?.count /
                                    countTotal?.active?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_active_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_active_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.active?.count
                              ? (
                                  (countTotal?.platform_active_cafroma?.count /
                                    countTotal?.active?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_active_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_active_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.active?.count
                              ? (
                                  (countTotal?.payment_active_stripe?.count /
                                    countTotal?.active?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_active_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_active_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.active?.count
                              ? (
                                  (countTotal?.payment_active_paypal?.count /
                                    countTotal?.active?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_active_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_active_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.active?.count
                              ? (
                                  (countTotal?.payment_active_bank_transfer?.count /
                                    countTotal?.active?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_active_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE COMPLETE */}
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title={`Pratiche complete (${convertAmount(countTotal?.done?.amount || 0)})`}
                    count={countTotal?.done?.count || 0}
                    percentage={{
                      color: "success",
                      // amount: `${count?.done?.count || 0}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_done_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.done?.count
                              ? (
                                  (countTotal?.platform_done_wewelfare?.count /
                                    countTotal?.done?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_done_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_done_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.done?.count
                              ? (
                                  (countTotal?.platform_done_cafmilano?.count /
                                    countTotal?.done?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_done_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_done_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.done?.count
                              ? (
                                  (countTotal?.platform_done_cafroma?.count /
                                    countTotal?.done?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_done_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_done_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.done?.count
                              ? (
                                  (countTotal?.payment_done_stripe?.count /
                                    countTotal?.done?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_done_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_done_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.done?.count
                              ? (
                                  (countTotal?.payment_done_paypal?.count /
                                    countTotal?.done?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_done_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_done_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.done?.count
                              ? (
                                  (countTotal?.payment_done_bank_transfer?.count /
                                    countTotal?.done?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_done_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>

              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE PAGATE */}
                  <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title={`Pratiche pagate (${countTotal?.paid?.count || 0})`}
                    count={`${convertAmount(countTotal?.paid?.amount || 0)}`}
                    percentage={{
                      color: "success",
                      // amount: `${convertAmount(count?.paid?.amount || 0)}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_paid_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.paid?.count
                              ? (
                                  (countTotal?.platform_paid_wewelfare?.count /
                                    countTotal?.paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>

                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_paid_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_paid_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.paid?.count
                              ? (
                                  (countTotal?.platform_paid_cafmilano?.count /
                                    countTotal?.paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>

                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_paid_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_paid_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.paid?.count
                              ? (
                                  (countTotal?.platform_paid_cafroma?.count /
                                    countTotal?.paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_paid_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_paid_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.paid?.count
                              ? (
                                  (countTotal?.payment_paid_stripe?.count /
                                    countTotal?.paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_paid_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_paid_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.paid?.count
                              ? (
                                  (countTotal?.payment_paid_paypal?.count /
                                    countTotal?.paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_paid_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_paid_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.paid?.count
                              ? (
                                  (countTotal?.payment_paid_bank_transfer?.count /
                                    countTotal?.paid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_paid_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE NON PAGATE */}
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title={`Pratiche non pagate (${countTotal?.unpaid?.count || 0})`}
                    count={convertAmount(countTotal?.unpaid?.amount || 0)}
                    percentage={{
                      color: "success",
                      // amount: `${convertAmount(count?.unpaid?.amount || 0)}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_unpaid_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.unpaid?.count
                              ? (
                                  (countTotal?.platform_unpaid_wewelfare?.count /
                                    countTotal?.unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_unpaid_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_unpaid_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.unpaid?.count
                              ? (
                                  (countTotal?.platform_unpaid_cafmilano?.count /
                                    countTotal?.unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_unpaid_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_unpaid_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.unpaid?.count
                              ? (
                                  (countTotal?.platform_unpaid_cafroma?.count /
                                    countTotal?.unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_unpaid_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_unpaid_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.unpaid?.count
                              ? (
                                  (countTotal?.payment_unpaid_stripe?.count /
                                    countTotal?.unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_unpaid_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_unpaid_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.unpaid?.count
                              ? (
                                  (countTotal?.payment_unpaid_paypal?.count /
                                    countTotal?.unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_unpaid_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_unpaid_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.unpaid?.count
                              ? (
                                  (countTotal?.payment_unpaid_bank_transfer?.count /
                                    countTotal?.unpaid?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_unpaid_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <MDBox mb={1.5}>
                  {/* ============== PRATICHE RIMBORSATE */}
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title={`Pratiche rimborsate (${countTotal?.refund?.count || 0})`}
                    count={convertAmount(countTotal?.refund?.amount || 0)}
                    percentage={{
                      color: "success",
                      // amount: `${convertAmount(count?.unpaid?.amount || 0)}`,
                      label: dateLabel,
                    }}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      <div style={{ textAlign: "left" }}>
                        <strong>Verticali: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            WeWelfare:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_refund_wewelfare?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.refund?.count
                              ? (
                                  (countTotal?.platform_refund_wewelfare?.count /
                                    countTotal?.refund?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_refund_wewelfare?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Milano:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_refund_cafmilano?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.refund?.count
                              ? (
                                  (countTotal?.platform_refund_cafmilano?.count /
                                    countTotal?.refund?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_refund_cafmilano?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            CAF Roma:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.platform_refund_cafroma?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.refund?.count
                              ? (
                                  (countTotal?.platform_refund_cafroma?.count /
                                    countTotal?.refund?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.platform_refund_cafroma?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                      <div style={{ textAlign: "left" }}>
                        <strong>Pagamenti: </strong>
                      </div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Stripe:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_refund_stripe?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.refund?.count
                              ? (
                                  (countTotal?.payment_refund_stripe?.count /
                                    countTotal?.refund?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_refund_stripe?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Paypal:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_refund_paypal?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.refund?.count
                              ? (
                                  (countTotal?.payment_refund_paypal?.count /
                                    countTotal?.refund?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_refund_paypal?.amount || 0} €)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="px-0 text-nowrap"
                            style={{ textAlign: "left", width: "60%" }}
                          >
                            Bonifico:
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.payment_refund_bank_transfer?.count}
                          </td>
                          <td className="px-3 text-nowrap">
                            {countTotal?.refund?.count
                              ? (
                                  (countTotal?.payment_refund_bank_transfer?.count /
                                    countTotal?.refund?.count) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </td>
                          <td className="px-3 text-nowrap">
                            ({countTotal?.payment_refund_bank_transfer?.amount || 0} €)
                          </td>
                        </tr>
                      </table>
                    </MDTypography>
                  </ComplexStatisticsCard>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={2}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} lg={12}>
                <Card>
                  <DataTable table={dataTable} canSearch />
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}

      <MDBox pt={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <Preloader isLoading={isTagLoading}>
                <MDTypography component="div" m={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => handlePraticheLoadData()}
                  >
                    Aggiorna
                  </MDButton>
                </MDTypography>
                {tagData.length ? (
                  <DataTable
                    handleOpen={handleOpen}
                    table={dataTableP}
                    entriesPerPage={{ defaultValue: 25 }}
                  />
                ) : (
                  <p> Non ci sono record disponibili al momento</p>
                )}
              </Preloader>
              <Pagination
                activePage={page}
                onNext={nextPage}
                onPrev={prevPage}
                numbersOfPage={totalPages}
                callback={paginationCallback}
                onLast={lastPage}
                onFirst={firstPage}
                selectPage={selectPage}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AnalyticsNSP;
