import styled from "styled-components";

export const Container = styled.section`
  .row, col-12 {
    width: 100%:
  }

  .titolo, titolosezione {
    text-align: center;
  }
`;

export const SwiperContainer = styled.div`
  position: relative;
  width: 100%;

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-slide img {
    max-height: 60px;
    margin: auto;
  }

  .swiper-slide:hover img {
    filter: grayscale(20%);
    transform: scale(1.1);
    transition: all 0.5s ease-in;
  }
`;

export const Button = styled.button`
  position: absolute;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  z-index: 2;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0;

  ${({ direction }) => (direction === "left" ? "left: 0;" : "right: 0;")}

  svg {
    fill: var(--bs-primary);
    touch-action: none;
    vertical-align: middle;
  }
`;

export default {};
