/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";
import ModalForm from "./components/ModalForm";

// Data
import dataTableData from "./data/AdsdataTableData";

// import SidenavProduct from "pagensp/gestproduct/productarchive/components/Sidenav";

const API_ENDPOINT = `/ads`;
function AdsBlogNsp() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenNew = () => setOpen(true) && setId(0);

  // Creao il datatable con la formattazione della tab importata
  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...data] });
  const { sendRequest: sendAdsRequest } = useHttp();
  const handleLoadData = () => {
    sendAdsRequest({ url: API_ENDPOINT, manageData: ({ list }) => manageDataList(list) });
  };

  // Riassegno le variabile da api
  const manageDataList = (list) => {
    setData([
      ...list.map((item) => ({
        ...item,
        delete: {
          action: () => handleDelData(item.id),
        },
        edit: {
          action: () => {
            setId(item.id);
            handleOpen();
          },
        },
      })),
    ]);
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  useEffect(() => setDataTable({ ...dataTableData, rows: [...data] }), [data]);
  useEffect(() => handleLoadData(), []);
  // End Load data

  // Delete
  const { sendRequest: sendDelAdsRequest } = useHttp();
  const handleDelData = (idApp) => {
    if (window.confirm(`Sei sicuro di voler eliminare questo elemento?`)) {
      sendDelAdsRequest({
        methodH: "DELETE",
        url: `${API_ENDPOINT}/${idApp}`,
      }).then(handleLoadData);
    }
  };
  // End Delete
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              Elenco Ads
              <MDButton variant="gradient" color="info" sx={{ m: 2 }} onClick={handleOpenNew}>
                <Icon>add</Icon>&nbsp; Nuovo Ads
              </MDButton>
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <DataTable handleOpen={handleOpen} table={dataTable} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ModalForm
        open={open}
        handleClose={handleClose}
        handleLoadData={handleLoadData}
        idElement={id}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default AdsBlogNsp;
