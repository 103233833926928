import { useEffect, useState } from "react";

const useTrustBox = () => {
  const getConfig = () =>
    window.innerWidth > 767
      ? {
          width: "100%",
          height: "350px",
          theme: "light",
          font: "monserrat",
          template: "53aa8912dec7e10d38f59f36",
        }
      : {
          width: "100%",
          height: "350px",
          font: "monserrat",
          theme: "light",
          template: "539ad0ffdec7e10e686debd7",
        };
  const [config, setConfig] = useState(() => getConfig());

  useEffect(() => {
    setConfig(getConfig());
  }, [window.innerWidth]);

  return { config };
};

export default useTrustBox;
