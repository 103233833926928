/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ErrContextProvider } from "pagensp/store/error-context";
import { AuthContextProvider } from "pagensp/store/auth-context";
// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],
  debug: false,
  environment: `${process.env.REACT_APP_SENTRY_ENV}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <ErrContextProvider>
      <AuthContextProvider>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </AuthContextProvider>
    </ErrContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
