import { Alert, CircularProgress, Snackbar, Typography } from "@mui/material";
import Select from "components/Select";
import useBlockScroll from "hooks/use-block-scroll";
import useHttp from "hooks/use-http";
import useWindowDimensions from "hooks/use-window-dimensions";
import { useEffect, useMemo, useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  Button,
  Buttons,
  Container,
  Image,
  LoaderContainer,
  SelectContainer,
  View,
  Wrapper,
} from "./style";

/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
const TEMP_CACHE_FILE = {};
function PreviewFile({ item, categories, onSubmit, onConfirm, onReject, onPrev, onNext, onClose }) {
  useBlockScroll();
  const { width } = useWindowDimensions();
  const [data, setData] = useState(null);
  const [extension, setExtension] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [category, setCategory] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [openNotification, setOpenNotification] = useState(null);
  const { sendRequest } = useHttp();
  const pageWidth = width - 100 < 900 ? 900 : width - 100;
  const optionsCategories = useMemo(
    () =>
      categories.map((item) => ({
        ...item,
        label: item.document.text,
        value: item.id,
      })),
    [categories]
  );

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onClickPrevPage = () =>
    pageNumber !== 1 && setPageNumber((page) => (page > 0 ? page - 1 : page));
  const onClickNextPage = () =>
    pageNumber !== numPages && setPageNumber((page) => (page < numPages ? page + 1 : page));
  const onClickCloseNotification = () => setOpenNotification(null);
  const onAssociateCategory = async () => {
    await onSubmit(item, category);
    setOpenNotification("Categoria assegnata con successo al documento!");
  };

  // Load data file from url
  useEffect(() => {
    // Reset params when switch document
    setData(null);
    setPageNumber(1);

    // Retrieve document
    const url = new URL(item.url);
    const path = url.pathname;
    const fileName = path.substring(path.lastIndexOf("/") + 1);
    const fileSplitted = fileName.split(".");
    const extension = fileSplitted[fileSplitted.length - 1].toLowerCase();
    const name = fileSplitted.slice(0, fileSplitted.length - 1).join("");
    setExtension(extension);
    if (!TEMP_CACHE_FILE[item.id]) {
      if (extension === "pdf") {
        sendRequest({
          url: `/users/preview-file`,
          methodH: "POST",
          bodyH: {
            url: item.url,
            name,
            extension,
          },
          manageData: ({ data }) => {
            TEMP_CACHE_FILE[item.id] = data;
            setData(data);
          },
        });
      } else {
        setData(item.url);
      }
    } else {
      setTimeout(() => setData(TEMP_CACHE_FILE[item.id] || null), 100);
    }
  }, [item.url]);

  // When document is loading
  if (!data) {
    return (
      <Wrapper>
        <Container>
          <View>
            <LoaderContainer>
              <CircularProgress size={80} />
            </LoaderContainer>
            <Typography variant="h6">Caricamento anteprima documento...</Typography>
            <Buttons>
              <div />
              <div>
                <Buttons>
                  <Button onClick={onClose}>Chiudi</Button>
                </Buttons>
              </div>
            </Buttons>
          </View>
        </Container>
      </Wrapper>
    );
  }

  // When document is ready
  return (
    <Wrapper>
      <Container>
        <View>
          {extension === "pdf" && ( // If document is pdf
            <>
              <iframe
                title="preview"
                src={data}
                style={{
                  width: "100%",
                  height: "calc(100dvh - 180px)",
                }}
              />
              {/* <Document
                file={data}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <LoaderContainer>
                    <CircularProgress size={100} />
                  </LoaderContainer>
                }
                error="Caricamento anteprima documento non riuscito!"
                noData="Nessun documento specificato per l'anteprima"
              >
                <Page pageNumber={pageNumber} width={pageWidth} />
              </Document> */}
            </>
          )}

          {["jpg", "jpeg", "png"].includes(extension) && ( // If document is image
            <>
              <Image src={data} alt="preview" />
            </>
          )}

          {/* Bottom bar with buttons */}
          <Typography variant="h6">
            {item.document.text}
            {item.approved === true && <> - CONFERMATO</>}
            {item.approved === false && <> - RIFIUTATO</>}
            {item.approved === null && <> - DA VALIDARE</>}
          </Typography>
          <Buttons>
            <div>
              {!item.loading ? (
                <>
                  {!item?.user_document ? (
                    <Buttons>
                      <SelectContainer>
                        <Select
                          label="Scegli la categoria"
                          options={optionsCategories}
                          value={category}
                          onChange={(value) => setCategory(value)}
                        />
                      </SelectContainer>
                      <Button disabled={categories?.length === 0} onClick={onAssociateCategory}>
                        Associa alla pratica
                      </Button>
                    </Buttons>
                  ) : (
                    <Buttons>
                      <Button
                        disabled={item.approved}
                        onClick={() => item.approved !== true && onConfirm()}
                      >
                        Conferma
                      </Button>
                      <Button
                        disabled={!item.approved}
                        type="secondary"
                        onClick={() => item.approved !== false && onReject()}
                      >
                        Rifiuta
                      </Button>
                    </Buttons>
                  )}
                </>
              ) : (
                <CircularProgress size={50} />
              )}
            </div>
            <div>
              <Buttons>
                {/* {extension === "pdf" && (
                  <>
                    <Button disabled={pageNumber === 1} onClick={onClickPrevPage}>
                      Pagina precedente
                    </Button>
                    <Button disabled={pageNumber === numPages} onClick={onClickNextPage}>
                      Pagina successiva
                    </Button>
                  </>
                )} */}
                <Button onClick={onClose}>Chiudi</Button>
              </Buttons>
            </div>
          </Buttons>
        </View>

        <ArrowLeft fontSize="large" onClick={onPrev} />
        <ArrowRight fontSize="large" onClick={onNext} />
        <Snackbar
          open={openNotification}
          autoHideDuration={5000}
          onClose={onClickCloseNotification}
        >
          <Alert onClose={onClickCloseNotification} sx={{ width: "100%" }}>
            {openNotification}
          </Alert>
        </Snackbar>
      </Container>
    </Wrapper>
  );
}

PreviewFile.defaultProps = {
  categories: [],
  onSubmit: () => {},
  onConfirm: () => {},
  onReject: () => {},
  onClose: () => {},
  onPrev: () => {},
  onNext: () => {},
};

export default PreviewFile;
