/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import useHttp from "hooks/use-http";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import LiveChat from "components/LiveChat";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Wizard page components
import {
  Alert,
  Backdrop,
  Badge,
  Box,
  Chip,
  Fade,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Preloader from "components/Preloader/Preloader";
import Select from "components/Select";
import moment from "moment";
import AuthContext from "pagensp/store/auth-context";
import About from "./components/About";
import UploadPratices from "./components/UploadPratices";

// import Address from "./components/Address";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const statusOptions = ["RIMBORSATA", "IN CORSO", "COMPLETA"].map((item) => ({
  label: item,
  value: item,
}));

function getSteps() {
  return [{ name: "Approvazione Documenti" }];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <About />;
    case 1:
      return <UploadPratices />;
    default:
      return null;
  }
}

/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
function PraticesDetail() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id: pId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [pratices, setPratices] = useState();
  const [user, setUser] = useState();
  const [steps, setSteps] = useState([]);
  const [docFile, setDocFile] = useState();
  const [docFileName, setDocFileName] = useState();
  const [docSignedFile, setDocSignedFile] = useState();
  const [docSignedFileName, setDocSignedFileName] = useState();
  const [signDocumentLink, setSignDocumentLink] = useState("");
  const isLastStep = activeStep === steps?.length - 1;
  const [paymentSendOk, setPaymentSendOk] = useState(false);

  const [snackType, setSnackType] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const {
    sendRequest: sendPraticesRequest,
    isLoading: isPraticesLoading,
    error: PraticesError,
  } = useHttp();
  const { sendRequest: sendUserRequest, isLoading: isUserLoading, error: UserError } = useHttp();

  const handleLoadManageData = (e) => {
    setPratices({
      ...e,
      status: (() => {
        if (e.refund) return "RIMBORSATA";
        if (!e.refund && e.done) return "COMPLETA";
        if (!e.refund && !e.done && !e.operator) return "SOSPESO";
        return "IN CORSO";
      })(),
    });
    setSteps([...getSteps(), ...e.steps.map((data) => ({ name: data.description, data }))]);
    if (e.current_step === null && e.done === null) {
      setActiveStep(0);
    } else if (e.done === null && e.current_step !== null) {
      setActiveStep(e.steps.findIndex((el) => el.id === e.current_step) + 1);
    } else if (e.done !== null) {
      setActiveStep(e.steps?.length);
    }

    sendUserRequest({
      url: `/users/${e.user.id}`,
      manageData: (eUser) => {
        setUser(eUser.data);
      },
    });
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadData = async () => {
    await sendPraticesRequest({
      url: `/orders/${pId}`,
      manageData: (e) => {
        handleLoadManageData(e.data);
      },
    });
  };

  const handlePay = async () => {
    await sendPraticesRequest({
      url: `/payments/pay/${pratices.transaction}`,
      manageData: (e) => {
        console.log("Payment confirm", e);
        setPaymentSendOk(e.success);
        if (e.success) alert("ordine impostato come pagato");
      },
    });
  };

  const handleConfirmData = async () => {
    await sendPraticesRequest({
      url: `/orders/confirm/${pId}`,
      methodH: "POST",
      manageData: (e) => {
        console.log("alex conferma pratica ----->", e.redirect);
        // handleLoadData();
        if (e.redirect) {
          setTimeout(() => {
            window.location.href = e.redirect;
          }, 1000);
        }
      },
    }).then(handleNext);
  };

  const handleRejectedData = async () => {
    await sendPraticesRequest({
      url: `/orders/reject/${pId}`,
      methodH: "POST",
      manageData: (e) => {
        console.log("alex conferma pratica ----->", e);
        // handleLoadData();
      },
    }).then(handleNext);
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  useEffect(() => {
    handleLoadData();
  }, []);

  const {
    sendRequest: sendPraticesDocRequest,
    isLoading: isPraticesDocLoading,
    error: PraticesDocError,
  } = useHttp();

  // 22214400

  const convertBlobToBase64 = async (blob, type = "file") => {
    if (blob.size > 22214400) {
      setSnackType("error");
      setSnackMessage(
        "Il file che stai cercando di caricare é troppo pesante, il limite massimo é di 25mb"
      );
    }
    if (blob.size < 22214400) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => resolve(reader.result);
        if (type === "file") {
          setDocFileName(blob.name);
        } else if (type === "signed_file") {
          setDocSignedFileName(blob.name);
        }
        reader.readAsDataURL(blob);
      });
    }
    return false;
  };

  const missingDocumentsHandler = async () => {
    // Allegato da scaricare per il cliente
    const attachment =
      docFile && docFileName
        ? {
            name: docFileName,
            data: docFile.replace(`data:${docFile.match(/^data:(.*);/)[1]};base64,`, ""),
          }
        : undefined;

    // Link per far firmare un documento al cliente
    const sign_document_link = signDocumentLink || undefined;

    // Documento firmato scaricabile dal cliente
    const signed_file =
      docSignedFile && docSignedFileName
        ? {
            name: docSignedFileName,
            data: docSignedFile.replace(
              `data:${docSignedFile.match(/^data:(.*);/)[1]};base64,`,
              ""
            ),
          }
        : undefined;

    if (
      signed_file &&
      !confirm(
        "Confermi di voler concludere questo step della pratica? " +
          "Questa conferma farà passare allo step successivo e non sarà possibile tornare indietro"
      )
    ) {
      return;
    }

    await sendPraticesDocRequest({
      url: `/orders-steps/done/${steps[activeStep].data.id}`,
      methodH: "POST",
      bodyH: { attachment, sign_document_link, signed_file },
      manageData: (e) => {
        handleLoadData();
        setDocFileName(null);
        setDocSignedFileName(null);
      },
    }).then(isLastStep && handleNext);
  };

  const onUploadDocumentStep = ({
    attachment,
    sign_document_link,
    signed_file,
    confirm_signed_file,
  }) =>
    new Promise((resolve) => {
      sendPraticesDocRequest({
        url: `/orders-steps/done/${steps[activeStep].data.id}`,
        methodH: "POST",
        bodyH: { attachment, sign_document_link, signed_file, confirm_signed_file },
        manageData: (e) => {
          handleLoadData();
          resolve();
        },
      });
    });

  const { sendRequest: sendDeleteRequest } = useHttp();
  const approveRefound = async () => {
    await sendDeleteRequest({ url: `/orders/refund/${pId}/approve`, methodH: "POST" });
    alert("Richiesta di Rimborso approvata");
  };

  const rejectRefound = async () => {
    await sendDeleteRequest({ url: `/orders/refund/${pId}/reject`, methodH: "POST" });
    alert("Richiesta di Rimborso rifiutata");
  };

  const onSelectStatus = async ({ value }) => {
    if (!confirm(`Sicuro di voler impostare questa pratica come ${value}?`)) return;
    const data = {
      RIMBORSATA: {
        done: moment().format("YYYY-MM-DD HH:mm:ss"),
        refund: "approved",
      },
      COMPLETA: {
        done: moment().format("YYYY-MM-DD HH:mm:ss"),
        refund: null,
      },
      "IN CORSO": {
        done: null,
        refund: null,
      },
    }[value];

    if (Object.keys(data).length > 0) {
      await sendPraticesRequest({
        url: `/orders/${pId}`,
        methodH: "PUT",
        bodyH: {
          data,
        },
        manageData: (e) => {
          handleLoadManageData(e.data);
        },
      });
      await handleLoadData();
    }
  };

  const onRestore = () => {
    if (
      !confirm(
        "Sei sicuro di voler ripristinare l'ordine? Verrà nuovamente impostato come non eliminato, vuoi procedere?"
      )
    )
      return;
    sendPraticesRequest({
      url: `/orders/${pId}`,
      methodH: "PUT",
      bodyH: {
        data: {
          deleted_at: null,
        },
      },
      manageData: (e) => {
        handleLoadManageData(e.data);
      },
    });
  };

  return (
    <DashboardLayout>
      <Snackbar
        open={snackMessage}
        onClose={() => {
          setSnackMessage("");
          setSnackType("success");
        }}
        sx={{ width: "100%", position: "fixed" }}
        autoHideDuration={3000}
      >
        <Alert sx={{ width: "100%" }} variant="filled" color={snackType}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={12}>
            <MDBox mt={1} mb={3} textAlign="center">
              <MDBox mb={5}>
                <MDTypography variant="h3" fontWeight="bold">
                  Gestione Pratica &nbsp;
                  <Chip
                    label={pratices?.status}
                    color={
                      {
                        SOSPESO: pratices?.paid ? "primary" : "default",
                        RIMBORSATA: "error",
                        COMPLETA: "success",
                        "IN CORSO": "warning",
                      }[pratices?.status]
                    }
                  />
                </MDTypography>
              </MDBox>
              {["admin", "project_manager"].includes(authCtx.typeUser) && (
                <Grid item xs={12}>
                  <MDBox mt={1} mb={3} textAlign="center">
                    <Grid container alignItems="center" gap={2}>
                      <div>
                        <Badge
                          badgeContent={
                            (pratices?.notifications_operator?.list?.length || 0) +
                            (pratices?.notifications_user?.list?.length || 0)
                          }
                          color="error"
                          showZero
                        >
                          <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                            Notifiche Pratica
                          </MDButton>
                        </Badge>
                      </div>
                      Imposta stato
                      <div style={{ width: 300 }}>
                        <Select
                          value={pratices?.status}
                          name="status"
                          options={statusOptions}
                          onChange={onSelectStatus}
                        />
                      </div>
                    </Grid>
                  </MDBox>
                </Grid>
              )}
            </MDBox>
            <Card>
              <Preloader isLoading={isPraticesLoading}>
                <MDBox mt={-3} mx={2}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((data) => (
                      <Step key={data.name}>
                        <StepLabel>{data.name}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </MDBox>
                <MDBox p={2}>
                  <MDBox>
                    {activeStep === 0 ? (
                      <About
                        pratices={pratices}
                        user={user}
                        handleLoadData={handleLoadData}
                        handlePay={handlePay}
                        paymentSendOk={paymentSendOk}
                        approveRefound={approveRefound}
                        rejectRefound={rejectRefound}
                        onRestore={onRestore}
                      />
                    ) : (
                      <UploadPratices
                        pratices={pratices}
                        steps={steps}
                        activeStep={activeStep}
                        convertBlobToBase64={convertBlobToBase64}
                        missingDocumentsHandler={missingDocumentsHandler}
                        setDocFile={setDocFile}
                        setDocSignedFile={setDocSignedFile}
                        signDocumentLink={signDocumentLink}
                        setSignDocumentLink={setSignDocumentLink}
                        user={user}
                        onUploadDocumentStep={onUploadDocumentStep}
                      />
                    )}
                    <Grid container justifyContent="end" style={{ display: "flex", gap: "10px" }}>
                      <MDBox justifyContent="end" style={{ display: "flex", gap: "10px" }}>
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton variant="outlined" color="dark" onClick={handleBack}>
                            Indietro
                          </MDButton>
                        )}
                        {activeStep === 0 && pratices?.operator_confirmed === false && (
                          <>
                            <MDButton
                              color="primary"
                              disabled={
                                pratices.documents.filter((elem) => elem.approved === false)
                                  .length !== 0 ||
                                pratices.fields.filter((elem) => elem.approved === false).length !==
                                  0
                              }
                              onClick={handleConfirmData}
                            >
                              {pratices?.consulting_slot ? (
                                <>Conferma Appuntamento del {pratices?.consulting_slot.start}</>
                              ) : (
                                <>Conferma Pratica</>
                              )}
                            </MDButton>
                          </>
                        )}
                        {pratices?.consulting_slot && !pratices?.operator_confirmed ? (
                          <>
                            <MDButton
                              color="primary"
                              style={{ opacity: ".4" }}
                              disabled={
                                pratices.documents.filter((elem) => elem.approved === false)
                                  .length !== 0 ||
                                pratices.fields.filter((elem) => elem.approved === false).length !==
                                  0
                              }
                              onClick={handleRejectedData}
                            >
                              Rifiuta Appuntamento del {pratices?.consulting_slot.start}
                            </MDButton>
                          </>
                        ) : (
                          <></>
                        )}
                        {activeStep === 0 && pratices?.operator_confirmed === true && (
                          <>
                            <MDButton
                              color="primary"
                              onClick={!isLastStep ? handleNext : undefined}
                              style={{ opacity: "1" }}
                            >
                              {isLastStep ? "CONFERMATA" : "Continua"}
                            </MDButton>
                          </>
                        )}
                        {activeStep !== 0 && steps[activeStep]?.data.done !== null && (
                          <>
                            <MDButton
                              color="primary"
                              onClick={!isLastStep ? handleNext : () => navigate("/praticheattive")}
                              style={{ opacity: "1" }}
                            >
                              {isLastStep ? "Pratica Completata" : "Continua"}
                            </MDButton>
                          </>
                        )}
                        {activeStep !== 0 &&
                          steps[activeStep]?.data.done === null &&
                          (docFileName || signDocumentLink || docSignedFileName) && (
                            <MDButton
                              color="primary"
                              style={{ opacity: "1" }}
                              onClick={missingDocumentsHandler}
                            >
                              Salva
                            </MDButton>
                          )}
                      </MDBox>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Preloader>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 900,
            backgroundColor: "white",
            border: "2px solid #000",
            boxShadow: 24,
            padding: 15,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Notifiche pratica
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} id="modal-modal-description" aria-label="simple table">
              <TableBody>
                {[
                  ...(pratices?.notifications_operator?.list
                    ? pratices?.notifications_operator?.list?.map((row) => ({
                        ...row,
                        type: "OPERATORE",
                      }))
                    : []),
                  ...(pratices?.notifications_user?.list
                    ? pratices?.notifications_user?.list?.map((row) => ({
                        ...row,
                        type: "CLIENTE",
                      }))
                    : []),
                ]
                  ?.toSorted((a, b) => (a.date < b.date ? 1 : -1))
                  ?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="button"
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {
                            {
                              info: <InfoTwoToneIcon fontSize="large" sx={{ color: "blue" }} />,
                              alert: <ErrorTwoToneIcon fontSize="large" sx={{ color: "red" }} />,
                            }[row.meta.severity]
                          }
                          <strong>{row.type}</strong>
                        </Typography>
                        <Typography variant="caption">{moment(row.date).fromNow()}</Typography>
                      </TableCell>
                      <TableCell align="right">{row.text}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default PraticesDetail;
