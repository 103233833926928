import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";

import ModalTag from "./componets/ModalTag";

// Data
import dataTableData from "./data/TagdataTableData";

// import SidenavProduct from "pagensp/gestproduct/productarchive/componets/Sidenav";

function TagConfigNsp() {
  const [tagData, setTag] = useState([]);
  const [open, setOpen] = useState(false);
  const [idTag, setIdTag] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleOpenNew = () => {
    setOpen(true);
    setIdTag(0);
  };
  const handleClose = () => setOpen(false);

  // Load data
  // hook per chiamare API
  const { sendRequest: sendTagRequest, isLoading: isFaqLoading, error: FaqError } = useHttp();

  // Creao il datatable con la formattazione della tab importata
  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...tagData] });

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadData = () => {
    sendTagRequest({
      url: "/tags",
      manageData: (e) => {
        manageTagData(e.list); // la funzione per la mappatura dei dati
      },
    });
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...tagData] });
  }, [tagData]);

  // Riassegno le variabile da api
  const manageTagData = (list) => {
    const appData = list.map((item) => ({
      tag: item.name,
      delete: {
        action: () => {
          handleDelData(item.id);
        },
      },
      edit: {
        action: () => {
          setIdTag(item.id);
          handleOpen();
        },
      },
    }));
    setTag([...appData]);
  };

  useEffect(() => {
    handleLoadData();
  }, []);
  // End Load data

  // Delete
  const {
    sendRequest: sendDelTagRequest,
    isLoading: isDelFaqLoading,
    error: delDelFaqError,
  } = useHttp();

  const handleDelData = (idApp) => {
    if (window.confirm("Sei sicuro di voler eliminare questa Faq")) {
      sendDelTagRequest({
        methodH: "DELETE",
        url: `/tags/${idApp}`,
      }).then(() => {
        handleLoadData();
      });
    }
  };
  // End Delete
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              Elenco Campi Anagrafici Ammessi
              <MDButton variant="gradient" color="info" sx={{ m: 2 }} onClick={handleOpenNew}>
                <Icon>add</Icon>&nbsp; Nuovo campo anagrafico
              </MDButton>
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <DataTable handleOpen={handleOpen} table={dataTable} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ModalTag
        open={open}
        handleClose={handleClose}
        handleLoadData={handleLoadData}
        idTag={idTag}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default TagConfigNsp;
