import styled from "styled-components";

const MOBILE_BREAKPOINT = 800;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  ${({ bg }) => (bg ? `background: #ebebff;` : ``)}
  padding: 10px;
  border-radius: 20px;
  margin: 20px 0;
`;

export const Title = styled.h3`
  font-size: 32px !important;
  font-weight: 900;
  color: var(--bs-primary);
`;

export const Description = styled.h4`
  font-size: 20px;
  font-weight: 400;
  color: black;
  text-align: center;
`;

export const CardsContainer = styled.section`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-wrap: wrap;
  }
`;

export const Card = styled.article`
  max-width: 350px;
  width: 100%;
  min-height: 250px;
  padding: 10px;
  margin-top: 55px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 10px;
    min-height: auto;
  }
`;

export const ProgressBar = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(var(--bs-primary) 75%, #d9d9d9 0);

  &::before {
    content: "${({ value }) => value || 0}%";
    font-size: 20px;
    font-weight: 700;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : `auto`)};
  object-fit: ${({ size }) => size || `cover`};
`;

export const Video = styled.iframe`
  width: 100%;
  border-radius: 40px;
  background: #d9d9d9;
  aspect-ratio: 16 / 9;
`;

export default {};
