import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

import styleModal from "assets/theme/base/modalconfig";

import Grid from "@mui/material/Grid";

function ModalPersonalField({ open, handleClose, handleLoadData, idPersonalField }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState({ label: "", value: "" });

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();
  const { sendRequest: sendSinglePersonalFieldRequest, isLoading: isSinglePersonalFieldLoading } =
    useHttp();

  const typeData = [
    { label: "text", value: "text" },
    { label: "number", value: "number" },
    { label: "email", value: "email" },
    { label: "tel", value: "tel" },
    { label: "date", value: "date" },
    { label: "datetime", value: "datetime" },
    { label: "time", value: "time" },
  ];

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e);
  };

  const typeHandler = (e) => {
    setType(e.value);
  };

  const managePersonalFieldData = ({ name: nameF, description: textF, type: typeF }) => {
    setName(nameF);
    setDescription(textF);
    setType({ value: typeF, label: typeF });
  };

  useEffect(() => {
    if (open && idPersonalField) {
      sendSinglePersonalFieldRequest({
        methodH: "GET",
        url: `/fields/${idPersonalField}`,
        manageData: (e) => {
          managePersonalFieldData(e.data); // la funzione per la mappatura dei dati
        },
      });
    } else if (open && idPersonalField === 0) {
      setName("");
      setDescription("");
      setType({ value: "", label: "" });
    }
  }, [idPersonalField, open]);

  const saveHandler = () => {
    if (open && idPersonalField === 0) {
      const data = {
        data: {
          name,
          description,
          type,
        },
      };
      sendSaveRequest({
        methodH: "POST",
        url: `/fields`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    } else if (open && idPersonalField) {
      const data = {
        data: {
          name,
          description,
          type,
        },
      };
      sendSaveRequest({
        methodH: "PUT",
        url: `/fields/${idPersonalField}`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    }
  };

  const {
    sendRequest: sendCategoryRequest,
    isLoading: isProductsLoading,
    error: productsError,
  } = useHttp();

  // useEffect in cui chiamo la funzione per mappare i dati
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={styleModal} pt={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <FormField
                type="text"
                label="Nome Campo"
                defaultValue=""
                value={name}
                onChange={nameHandler}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Autocomplete
                onChange={(event, value) => {
                  typeHandler(value);
                }}
                value={type}
                options={typeData}
                renderInput={(params) => (
                  <FormField {...params} label="Tipo" InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Testo Faq
                </MDTypography>
              </MDBox>
              <MDEditor
                value={description}
                onChange={(e) => {
                  descriptionHandler(e);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                Inserisci
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Fade>
    </Modal>
  );
}
export default ModalPersonalField;
