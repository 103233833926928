import styled from "styled-components";
import stepNumberBackground from "assets/images/step_number_bg.png";

const MOBILE_BREAKPOINT = 800;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background: #ebebff;
  padding: 10px;
  border-radius: 20px;
  margin: 20px 0;
`;

export const Title = styled.h3`
  font-size: 32px !important;
  font-weight: 900;
  color: var(--bs-primary);
`;

export const Description = styled.h4`
  font-size: 20px;
  font-weight: 400;
  color: black;
`;

export const CardsContainer = styled.section`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-wrap: wrap;
  }
`;

export const ImageNumber = styled.div`
  background-image: url(${stepNumberBackground});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  color: white;
  padding: 20px;
  translate: 0px -55px;
  margin-bottom: -35px;
`;

export const Card = styled.article`
  max-width: 330px;
  width: 100%;
  min-height: 250px;
  padding: 10px;
  background: white;
  border-radius: 40px;
  margin-top: 55px;

  > h4 {
    font-size: 20px;
    color: #1a1a1a;
    margin-bottom: 5px;
    text-align: center;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      text-align: center;
    }
  }

  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #1a1a1a;
    text-align: center;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      text-align: center;
    }
  }
`;

export default {};
