import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 0px;

  .MuiInputBase-root {
    padding-bottom: 3px !important;
  }
`;

export default {};
