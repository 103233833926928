/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import styled from "styled-components";

const Text = styled.h5`
  text-align: left;
  word-wrap: break-word;
  box-sizing: inherit;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  line-height: 1.375;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: -0.125px;
  font-weight: 700;
`;

const Button = styled.div`
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  letter-spacing: 0;
  background-size: 150%;
  background-position-x: 25%;
  position: relative;
  overflow: hidden;
  box-shadow: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.6ch 2ch;
  line-height: 1.3;
  font-weight: bold;
  border-radius: 36rem !important;
  text-decoration: none;
  color: #0a1f44 !important;
  border: 3px solid #0524dd !important;
  background-color: white !important;
  -webkit-box-sizing: border-box;
  transition: all 0.5s ease-in;
  text-transform: none;
  font-size: 1.1rem;

  &:hover {
    color: white !important;
    background-color: #0524dd !important;
    border-color: #0524dd !important;
    opacity: 1 !important;
  }
`;

function Component({ content, buttonLink, buttonText }) {
  return (
    <Grid container alignItems="center" p={2} className="w-100 bg-primary border">
      <Grid item xs={12} lg={7}>
        <Text>{content}</Text>
      </Grid>
      <Grid
        item
        container
        xs={12}
        lg={5}
        sx={{ ml: "auto", textAlign: "center" }}
        justifyContent="flex-end"
      >
        <a href={buttonLink}>
          <Button>{buttonText}</Button>
        </a>
      </Grid>
    </Grid>
  );
}

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "cta-inline",
  title: "CTA Inline",
  description: "CTA con testo e bottone",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        content: {
          type: "string",
          default: "Contenuto",
        },
        buttonLink: {
          type: "string",
          default: "https://www.google.com/",
        },
        buttonText: {
          type: "string",
          default: "Testo bottone",
        },
      },
      required: ["content", "buttonLink", "buttonText"],
    },
  },
};
