import { useState, useEffect, useMemo } from "react";
import useHttp from "hooks/use-http";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";

import { Dropzone, FileItem } from "@dropzone-ui/react";

import styleModal from "assets/theme/base/modalconfig";

import Grid from "@mui/material/Grid";
import { Autocomplete, Switch } from "@mui/material";
import Select from "components/Select";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import UploadSingle from "components/UploadSingle";

const API_ENDPOINT = `/ads`;
const POSITIONS = [
  {
    label: "Verticale",
    value: "vertical",
  },
  {
    label: "Orizzontale",
    value: "horizontal",
  },
];
function ModalForm({ open, handleClose, handleLoadData, idElement }) {
  const MODE = useMemo(() => (idElement ? "UPDATE" : "CREATE"), [idElement]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);
  const [fields, setFields] = useState({
    title: "",
    meta_description: "",
    content: "",
    position: null,
    category: null,
    product: null,
    link: null,
    active: false,
    date_start: null,
    date_end: null,
    tags: [],
    image: null,
    clicks: 0,
    impressions: 0,
  });

  const onChange = (key, value) => setFields((prev) => ({ ...prev, [key]: value }));
  const onChangeSelect = (select, name) => onChange(name, select?.value);
  const onChangeSwitch = ({ target }, name) => onChange(name, target.checked);

  // SAVE
  const { isLoading, sendRequest: saveRequest } = useHttp();
  const onSave = () => {
    saveRequest({
      url: `${API_ENDPOINT}${MODE === "UPDATE" ? `/${idElement}` : ""}`,
      methodH: MODE === "CREATE" ? "POST" : "PUT",
      bodyH: {
        data: {
          ...fields,
          // If there is new upload then save the image
          image: typeof fields.image === "object" ? fields.image : undefined,
          tags: fields.tags.map(({ value }) => ({ tag: value })),
        },
      },
      manageData: () => {
        handleLoadData();
        handleClose();
      },
    });
  };

  // CATEGORIES
  const { sendRequest: sendCategoriesRequest } = useHttp();
  useEffect(() => {
    sendCategoriesRequest({
      url: "/categories-blog",
      manageData: ({ list }) =>
        setCategories(
          list.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    });
  }, []);

  // TAGS
  const { sendRequest: sendTagsRequest } = useHttp();
  useEffect(() => {
    sendTagsRequest({
      url: "/tags",
      manageData: ({ list }) =>
        setTags(
          list.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    });
  }, []);

  // PRODUCTS
  const { sendRequest: sendProductsRequest } = useHttp();
  useEffect(() => {
    sendProductsRequest({
      url: "/products",
      manageData: ({ list }) =>
        setProducts(
          list.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    });
  }, []);

  // UPDATE
  const { sendRequest: getElement } = useHttp();
  useEffect(() => {
    if (idElement && MODE === "UPDATE")
      getElement({
        url: `${API_ENDPOINT}/${idElement}`,
        manageData: ({ data }) =>
          setFields({
            ...data,
            category: data.category?.id,
            product: data.product?.id,
            tags: data.tags.map(({ tag }) => ({ label: tag.text, value: tag.id })),
            id: undefined,
          }),
      });
  }, [idElement, MODE]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={styleModal} pt={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <MDTypography variant="h6">Dati</MDTypography>
              <FormField
                type="text"
                label="Titolo"
                value={fields.title}
                onChange={(e) => onChange("title", e.target.value)}
              />
              <FormField
                type="text"
                multiline
                rows={5}
                label="Meta Description"
                value={fields.meta_description}
                onChange={(e) => onChange("meta_description", e.target.value)}
              />
              <MDTypography variant="button">Contenuto</MDTypography>
              <MDEditor value={fields.content} onChange={(value) => onChange("content", value)} />
              <Select
                label="Posizione"
                options={POSITIONS}
                value={fields.position}
                onChange={(e) => onChangeSelect(e, "position")}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6">Upload immagine</MDTypography>
              <UploadSingle
                item={fields.image}
                onUpdate={(value) => onChange("image", value)}
                accept=".jpg,.jpeg,image/jpg,image/jpeg"
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6">Pubblicazione</MDTypography>
              <MDTypography variant="button">Data inizio</MDTypography>
              <MDDatePicker
                input={{
                  variant: "standard",
                  placeholder: "Seleziona data di inizio",
                  style: { width: "100%" },
                }}
                value={fields.date_start}
                onChange={([value]) => onChange("date_start", value)}
              />

              <MDTypography variant="button">Data fine</MDTypography>
              <MDDatePicker
                input={{
                  variant: "standard",
                  placeholder: "Seleziona data di fine",
                  style: { width: "100%" },
                }}
                value={fields.date_end}
                onChange={([value]) => onChange("date_end", value)}
              />

              <MDTypography variant="button">Pubblica</MDTypography>
              <Switch
                checked={fields.active}
                onChange={(e) => onChangeSwitch(e, "active")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6">Associazioni</MDTypography>
              <Select
                label="Categoria"
                options={categories}
                value={fields.category}
                onChange={(e) => onChangeSelect(e, "category")}
              />

              <MDTypography variant="button">Tags</MDTypography>
              <Autocomplete
                multiple
                value={fields.tags}
                options={tags}
                onChange={(event, data) => onChange("tags", data)}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="button">
                Associa un prodotto o un link a cui rimandare
              </MDTypography>
              <Select
                label="Redirect Prodotto"
                options={products}
                value={fields.product}
                onChange={(e) => onChangeSelect(e, "product")}
              />
              <FormField
                type="text"
                label="Redirect link"
                value={fields.link || ""}
                onChange={(e) => onChange("link", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              {isLoading ? (
                <MDButton variant="gradient" color="info" fullWidth>
                  Salvataggio in corso
                </MDButton>
              ) : (
                <MDButton variant="gradient" color="info" onClick={onSave} fullWidth>
                  Salva
                </MDButton>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Fade>
    </Modal>
  );
}
export default ModalForm;
