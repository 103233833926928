import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";
import ModalSubCategory from "./componets/ModalSubCategory";

// Data
import dataTableData from "./data/SubcategorydataTableData";
// import SidenavProduct from "pagensp/gestproduct/productarchive/componets/Sidenav";

function SubcategoryConfigNsp() {
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [idSubCat, setIdSubCat] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleOpenNew = () => {
    setOpen(true);
    setIdSubCat(0);
  };
  const handleClose = () => setOpen(false);

  // Load data
  // hook per chiamare API
  const {
    sendRequest: sendSubCategoryRequest,
    isLoading: isSubCategoryLoading,
    error: SubCategoryError,
  } = useHttp();

  // Creao il datatable con la formattazione della tab importata
  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...subCategoryData] });

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadData = () => {
    sendSubCategoryRequest({
      url: "/subcategories",
      manageData: (e) => {
        manageSubCategoryData(e.list); // la funzione per la mappatura dei dati
      },
    });
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...subCategoryData] });
  }, [subCategoryData]);

  // Riassegno le variabile da api
  const manageSubCategoryData = (list) => {
    const appData = list.map((item) => ({
      nome: item.name,
      category: item.category.text,
      delete: {
        action: () => {
          handleDelData(item.id);
        },
      },
      edit: {
        action: () => {
          setIdSubCat(item.id);
          handleOpen();
        },
      },
    }));
    setSubCategoryData([...appData]);
  };

  useEffect(() => {
    handleLoadData();
  }, []);
  // End Load data

  // Delete
  const {
    sendRequest: sendDelSubCategoryRequest,
    isLoading: isDelSubCategoryLoading,
    error: delDelSubCategoryError,
  } = useHttp();

  const handleDelData = (idApp) => {
    if (window.confirm("Sei sicuro di voler eliminare questa Categoria")) {
      sendDelSubCategoryRequest({
        methodH: "DELETE",
        url: `/subcategories/${idApp}`,
      }).then(() => {
        handleLoadData();
      });
    }
  };
  // End Delete

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              Elenco Sotto Categorie
              <MDButton variant="gradient" color="info" sx={{ m: 2 }} onClick={handleOpenNew}>
                <Icon>add</Icon>&nbsp; Nuova sotto categoria
              </MDButton>
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <DataTable handleOpen={handleOpen} table={dataTable} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ModalSubCategory
        open={open}
        handleClose={handleClose}
        handleLoadData={handleLoadData}
        idSubCat={idSubCat}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default SubcategoryConfigNsp;
