import { useEffect, useRef, useContext } from "react";
import autosize from "autosize";
import AuthContext from "pagensp/store/auth-context";
import SendIcon from "@mui/icons-material/Send";
import "./Chat.css";

export default function Chat({
  sendMessage,
  type,
  selectedRoom,
  handleSelectedRoom,
  room,
  messages,
  sendReadMessage,
}) {
  const { userData } = useContext(AuthContext);
  const { id: MyID = 0 } = userData || { id: 0 };
  const formatDateFromMessage = (date) => {
    const data = new Date(date);
    return data.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  return (
    <div className="chat">
      <section>
        {messages
          ?.filter((fi) => fi.room === selectedRoom)
          .map((message) =>
            message?.user === MyID ? (
              <PersonalText>
                {" "}
                {message?.text}
                <TimeDisplayer>{formatDateFromMessage(message?.date)}</TimeDisplayer>
              </PersonalText>
            ) : (
              <UserText>
                {" "}
                {message?.text}
                <TimeDisplayer>{formatDateFromMessage(message?.date)}</TimeDisplayer>
              </UserText>
            )
          )}
      </section>
      <footer>
        <AutosizeTextarea {...{ sendMessage }} />
      </footer>
      <div>
        <button
          className="mb-2 text-white"
          onClick={() => sendReadMessage(messages[messages.length - 1]?.id)}
          type="button"
        >
          Archivia Chat
        </button>
      </div>
    </div>
  );
}

const TimeDisplayer = ({ children }) => (
  <b style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>{children}</b>
);

function PersonalText({ children, id }) {
  return (
    <div id={id} className="user-msg" style={{ display: "flex", justifyContent: "space-between" }}>
      {children}
    </div>
  );
}

function UserText({ children, id }) {
  return (
    <div
      id={id}
      className="personal-msg"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      {children}
    </div>
  );
}

function AutosizeTextarea({ sendMessage }) {
  const textareaRef = useRef(null);
  useEffect(() => autosize(textareaRef.current), []);
  const handleSend = () => {
    sendMessage(textareaRef.current.value);
    textareaRef.current.value = "";
  };

  return (
    <>
      <section className="w-100 position-relative">
        <textarea
          ref={textareaRef}
          rows={1}
          maxLength={500}
          placeholder="Scrivi al cliente"
          onKeyPress={(e) => e.key === "Enter" && handleSend(e.target.value)}
        />
        <button onClick={handleSend} type="button" className="inputBtn">
          <SendIcon fontSize="large" />
        </button>
      </section>
    </>
  );
}
