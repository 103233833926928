import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import useHttp from "hooks/use-http";
import "flatpickr/dist/themes/material_green.css";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Card, Box } from "@mui/material";

const TimeSlots = ({ operator }) => {
  const convertDate = (date, situation) => {
    const monthsTextToNumber = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const dateArray = date.toString().split(" ");

    const [textDay, textMonth, day, year, hour] = dateArray;

    return situation === "date" ? `${year}-${monthsTextToNumber[textMonth]}-${day}` : `${hour}`;
  };

  const manageSlots = ({ list }) => {
    const toSet = list.filter((x) => new Date(x.start).getTime() > new Date().getTime());
    const finalSet = toSet.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );
    setSlots(finalSet);
  };

  const editSlot = ({ slot, situation, event }) => {
    const { start, end } = slot;
    const [startDate, startHour] = start.split(" ");
    const [endDate, endHour] = end.split(" ");

    if (situation === "date") {
      const editedSlot = {
        ...slot,
        start: `${convertDate(event, "date")} ${startHour}`,
        end: `${convertDate(event, "date")} ${endHour}`,
      };
      const { operator: x, ...rest } = editedSlot;
      return rest;
    }

    if (situation === "hour-start") {
      const editedSlot = {
        ...slot,
        start: `${startDate} ${convertDate(event, "hour")}`,
      };
      const { operator: x, ...rest } = editedSlot;
      return rest;
    }

    if (situation === "hour-end") {
      const editedSlot = {
        ...slot,
        end: `${endDate} ${convertDate(event, "hour")}`,
      };
      const { operator: x, ...rest } = editedSlot;

      return rest;
    }

    return slot;
  };

  const [slots, setSlots] = useState(["ciao"]);
  const { sendRequest: sendGetSlotsRequest } = useHttp();
  const { sendRequest: sendCreateSlotRequest } = useHttp();
  const { sendRequest: sendDeleteSlotRequest } = useHttp();
  const { sendRequest: sendUpdateSlotRequest } = useHttp();

  const deleteSlots = async (id) => {
    await sendDeleteSlotRequest({ url: `/consulting-slots/${id}`, methodH: "DELETE" });
  };

  const getSlots = async () => {
    console.log("getSlots");
    await sendGetSlotsRequest({
      url: "/consulting-slots",
      manageData: manageSlots,
    });
  };

  const editMySlot = ({ event, id, situation }) => {
    const mySlot = slots.find((slot) => slot.id === id);
    const editedSlot = editSlot({ slot: mySlot, situation, event });
    setSlots((old) => old.map((slot) => (slot.id === id ? editedSlot : slot)));
  };
  const handleDateStartChange = async ({ id }) => {
    const mySlot = slots.find((slot) => slot.id === id);
    const { id: idx, ...rest } = mySlot;

    await sendUpdateSlotRequest({
      url: `/consulting-slots/${idx}`,
      bodyH: { data: { ...rest, operator: +operator } },
      methodH: "PUT",
    });
  };

  const createSlot = async () => {
    const today = new Date();
    const start = `${today.getFullYear()}-${today.getMonth() + 1}-${
      today.getDate() + 1
    } ${today.getHours()}:${today.getMinutes()}:00`;
    const end = `${today.getFullYear()}-${today.getMonth() + 1}-${
      today.getDate() + 1
    } ${today.getHours()}:${today.getMinutes() + 1}:00`;
    const data = {
      start,
      end,
      operator: +operator,
      order: null,
    };

    await sendCreateSlotRequest({ url: "/consulting-slots", methodH: "POST", bodyH: { data } });
  };
  useEffect(() => {
    getSlots();
  }, []);
  // convert Tue Jul 26 2022 21:00:00 GMT+0200  to 2022-07-2022

  return (
    <>
      <Card>
        <MDBox p={3}>
          <h3>Time Slots</h3>
          {slots
            ?.filter((elem) => elem.order === null)
            .map((slot, i) => {
              const startSplit = slot?.start?.split(" ");
              const endSplit = slot?.end?.split(" ");
              return (
                <>
                  {startSplit?.length && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <Flatpickr
                        labels="Giorno"
                        value={startSplit[0]}
                        onChange={(event) => editMySlot({ event, id: slot.id, situation: "date" })}
                      />
                      <Flatpickr
                        labels="Ora inizio"
                        value={startSplit[1]}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                        onChange={(event) =>
                          editMySlot({ event, id: slot.id, situation: "hour-start" })
                        }
                      />
                      <Flatpickr
                        labels="Ora fine"
                        value={endSplit[1]}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                        onChange={(event) =>
                          editMySlot({ event, id: slot.id, situation: "hour-end" })
                        }
                      />
                      <MDButton
                        type="button"
                        color="primary"
                        onClick={() => {
                          handleDateStartChange({ id: slot.id });
                        }}
                      >
                        SALVA
                      </MDButton>
                      <MDButton
                        type="button"
                        color="error"
                        onClick={() =>
                          deleteSlots(slot.id).then(() => {
                            getSlots();
                          })
                        }
                      >
                        x
                      </MDButton>
                    </div>
                  )}
                </>
              );
            })}
          <MDButton
            variant="gradient"
            color="info"
            type="button"
            onClick={() => createSlot().then(() => getSlots())}
          >
            NUOVO
          </MDButton>
        </MDBox>
      </Card>
    </>
  );
};
export default TimeSlots;
