import MDTypography from "components/MDTypography";

// ProductsList page components
import DeleteIcon from "@mui/icons-material/Delete";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Chip, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import moment from "moment";
import "moment/locale/it";

moment.locale("it");

export default {
  columns: [
    {
      Header: "Date",
      accessor: "date",
      sortable: false,
      Cell: ({ value }) => (
        <div>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              N. Pratica:{" "}
            </MDTypography>
            <MDTypography variant="p">{value.idPratica}</MDTypography>
          </div>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              N. Ordine:{" "}
            </MDTypography>
            <MDTypography variant="p">{value.idOrdine}</MDTypography>
          </div>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              Acquisto:{" "}
            </MDTypography>
            <MDTypography variant="p">
              {moment(value.acquisto).format("DD/MM/YYYY HH:mm")}
            </MDTypography>
          </div>
          {value.updatedAt && (
            <div>
              <MDTypography variant="p" fontWeight="bold">
                Aggiornamento:{" "}
              </MDTypography>
              <MDTypography variant="p">
                {value.updatedAt ? `${moment(value.updatedAt).fromNow(true)} fa` : ""}
              </MDTypography>
            </div>
          )}
        </div>
      ),
    },
    {
      Header: "Dati Pratica",
      accessor: "clientProduct",
      sortable: false,
      Cell: ({ value }) => (
        <div style={{ width: 200 }}>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              {value.product}
            </MDTypography>
          </div>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              Cliente:{" "}
            </MDTypography>
            <MDTypography variant="p">{value.client}</MDTypography>
          </div>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              Pagamento:{" "}
            </MDTypography>
            <MDTypography variant="p">{value.payment_method}</MDTypography>
          </div>
          <div>
            <MDTypography variant="p" fontWeight="bold">
              Operatore:{" "}
            </MDTypography>
            <MDTypography variant="p">{value.operator}</MDTypography>
          </div>
        </div>
      ),
    },
    {
      Header: "Stato Pratica",
      accessor: "statop",
      sortable: false,
      Cell: ({ value }) => {
        const similar = value.list.find(
          (item) =>
            item.product.id === value.product.id &&
            item.user.id === value.user.id &&
            item.platform === value.platform &&
            item.id !== value.id &&
            item.id > value.id &&
            item.created > value.created
        );
        let status = null;
        if (value?.operator === null) {
          status = "SOSPESO";
        }
        if (value?.operator !== null && value?.done === null) {
          status = "IN CORSO";
        }
        if (value?.done !== null) {
          status = "COMPLETA";
        }
        if (value?.refund === "approved") {
          status = "RIMBORSATA";
        }
        if (value?.refund === "requested") {
          status = "RIMBORSO RICHIESTO";
        }
        if (value?.refund === "rejected") {
          status = "RIMBORSO RIFIUTATO";
        }
        return (
          <>
            <Grid container gap={1}>
              <Chip
                label={status}
                color={
                  {
                    SOSPESO: value.paid ? "primary" : "default",
                    RIMBORSATA: "error",
                    "RIMBORSO RICHIESTO": "warning",
                    "RIMBORSO RIFIUTATO": "success",
                    COMPLETA: "success",
                    "IN CORSO": "warning",
                  }[status]
                }
              />
              {status === "SOSPESO" && similar && (
                <Chip
                  label="?"
                  color="secondary"
                  onClick={() =>
                    alert(
                      `Pratica simile al numero ${similar.id}. Questa pratica potrebbe essere da rimuovere, perchè probabilmente duplicata. Verificare e in caso eliminare per avere un corretto conteggio nelle statistiche.`
                    )
                  }
                />
              )}
            </Grid>
            <div>
              {{
                FIRST_REMINDER_SENT: "1° Email Reminder",
                SECOND_REMINDER_SENT: "2° Email Reminder",
              }[value.email_status] || ""}
            </div>
          </>
        );
      },
    },
    {
      Header: "Pagato",
      accessor: "paid",
      sortable: false,
      width: 70,
      Cell: ({ value }) => {
        if (value) {
          return <StatusCell icon="done" color="success" status="Pagato" />;
        }
        return <StatusCell icon="close" color="error" status="Non Pagato" />;
      },
    },
    {
      Header: "Mandato",
      accessor: "signedfile",
      sortable: false,
      width: 70,
      Cell: ({ value }) =>
        value ? (
          <StatusCell
            icon="done"
            color="success"
            status="Firmata - Clicca per scaricare"
            onClick={() => {
              window.open(value, "_blank");
            }}
          />
        ) : (
          <StatusCell icon="close" color="error" status="Non firmato" />
        ),
    },
    {
      Header: "Fattura creata",
      accessor: "fattura",
      sortable: false,
      width: 70,
      Cell: ({ value }) => {
        if (value) {
          return <StatusCell icon="done" color="success" status="Pagato" />;
        }
        return <StatusCell icon="close" color="error" status="Non Pagato" />;
      },
    },
    {
      Header: "Invio SDI",
      accessor: "statoSdi",
      sortable: false,
      width: 70,
      Cell: ({ value }) => {
        if (value?.xml_sent) {
          return <StatusCell icon="done" color="success" status="Inviata" />;
        }
        if (value?.xml_error) {
          const onClick = () => alert(value.xml_error_message);
          return <StatusCell icon="close" color="error" status="Errore invio" onClick={onClick} />;
        }
        return <StatusCell icon="more_horiz" color="warning" status="In elaborazione" />;
      },
    },
    {
      Header: "Piattaforma",
      accessor: "platform",
      sortable: false,
      Cell: ({ value }) => (
        <Chip
          label={value}
          color={
            {
              "CAF Milano": "error",
              "CAF Roma": "success",
              WeWelfare: "primary",
            }[value]
          }
        />
      ),
    },
    {
      Header: "Azione",
      width: "1%",
      disableSortBy: true,
      manualSortBy: false,
      accessor: "actions",
      Cell: ({ value }) => (
        <Grid container justifyContent="end" gap={1}>
          <MDButton
            color="info"
            style={{ visibility: value?.checkPayment?.action ? "visible" : "hidden" }}
            onClick={() => value?.checkPayment?.action()}
          >
            <QueryStatsIcon />
          </MDButton>
          {value?.delete?.action && (
            <MDButton color="error" onClick={() => value?.delete?.action()}>
              <DeleteIcon />
            </MDButton>
          )}
        </Grid>
      ),
    },
  ],
};
