/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { platforms } from "config";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function SidenavProduct({ action, rows }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const sidenavItems = [
    { icon: "person", label: `Tutti i Prodotti: ${rows.length}`, href: "profile", type: "" },
    {
      icon: "receipt_long",
      label: `Pratiche: ${rows.filter((elem) => elem.tipologia === "paperwork").length}`,
      href: "basic-info",
      type: "paperwork",
    },
    {
      icon: "lock",
      label: `Consulenza: ${rows.filter((elem) => elem.tipologia === "consulting").length}`,
      href: "change-password",
      type: "consulting",
    },
    {
      icon: "security",
      label: `Abbonamenti: ${rows.filter((elem) => elem.tipologia === "subscription").length}`,
      href: "2fa",
      type: "subscription",
    },
    {
      icon: "badge",
      label: `Pacchetti: ${rows.filter((elem) => elem.tipologia === "package").length}`,
      href: "accounts",
      type: "package",
    },
    ...platforms.map((item) => ({
      icon: "numbers",
      label: `Prodotti ${item.label}: ${
        rows.filter((elem) => elem.platforms.includes(item.value)).length
      }`,
      href: "accounts",
      type: "package",
    })),
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href, type }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          onClick={() => {
            action(type);
          }}
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default SidenavProduct;
