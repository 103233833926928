/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";

import Pagination from "components/Pagination";
import usePagination from "hooks/use-pagination";

// Data
import dataTableData from "./data/dataTableData";

const SECTION_URL = `/landings/pagine`;
function PageLandingNsp() {
  const history = useNavigate();
  const {
    currentPage: page,
    paginate: setPage,
    totalPages,
    // calculateTotalPages,
    setTotalPosts,
    nextPage,
    firstPage,
    lastPage,
    prevPage,
    selectPage,
  } = usePagination();
  const [data, setData] = useState([]);

  const paginationCallback = (pag) => (e) => setPage(pag);
  const handleOpen = (id) => history(`${SECTION_URL}/${id}`);
  // Load data
  // hook per chiamare API
  const { sendRequest: sendLandingsRequest, isLoading } = useHttp();

  // Creao il datatable con la formattazione della tab importata
  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...data] });

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadData = () => {
    sendLandingsRequest({
      url: `/landings?sort=[["id","DESC"]]&per_page=25&page=${page}`,
      manageData: ({ list, tot }) => {
        manageData(list);
        setTotalPosts(tot);
      },
    });
  };

  // useEffect in cui chiamo la funzione per mappare i dati
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...data] });
  }, [data]);

  // Riassegno le variabile da api
  const manageData = (list) => {
    const appData = list.map((item) => ({
      ...item,
      data: {
        type: item.type,
        product: item.product,
        wizard: item.wizard,
      },
      delete: {
        action: () => handleDelData(item.id),
      },
      edit: {
        action: () => handleOpen(item.id),
      },
    }));
    setData([...appData]);
  };

  useEffect(() => handleLoadData(), [page]);
  // End Load data

  // Delete
  const { sendRequest: sendDelLandingRequest } = useHttp();
  const handleDelData = (idApp) => {
    if (window.confirm(`Sei sicuro di voler eliminare questa Landing?`)) {
      sendDelLandingRequest({
        methodH: "DELETE",
        url: `/landings/${idApp}`,
      }).then(() => handleLoadData());
    }
  };
  // End Delete
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              Elenco Pagine Landing
              <MDButton
                variant="gradient"
                color="info"
                sx={{ m: 2 }}
                onClick={() => handleOpen("new")}
              >
                <Icon>add</Icon>&nbsp; Nuova landing
              </MDButton>
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              {isLoading ? (
                <Grid container p={4} justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  <DataTable handleOpen={handleOpen} table={dataTable} />
                  <Pagination
                    activePage={page}
                    onNext={nextPage}
                    onPrev={prevPage}
                    numbersOfPage={totalPages}
                    callback={paginationCallback}
                    onLast={lastPage}
                    onFirst={firstPage}
                    selectPage={selectPage}
                  />
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PageLandingNsp;
