/* eslint-disable react/jsx-no-target-blank */

import { Grid } from "@mui/material";
import Select from "components/Select";
import useHttp from "hooks/use-http";
import moment from "moment";
import FormField from "pagensp/CustomComponents/FormField";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CardsContainer, Container, Label, Number, Timer, Title } from "./style";

function Component({ title, expire, bg, buttonText, product }) {
  const navigate = useNavigate();
  const { sendRequest } = useHttp();
  const [countDown, setCountDown] = useState(null);
  const onClick = () => {
    if (window.location.origin.includes("gestionale")) {
      alert(`Prodotto associato ${product}`);
      return;
    }
    const secret = localStorage.getItem("cartSecret");
    const id = localStorage.getItem("cartID");

    if (!secret || !id) return;
    const token = localStorage.getItem("token");
    sendRequest({
      url: `/cart/${id}?secret=${secret}`,
      methodH: "PUT",
      bodyH: {
        data: {
          items: [
            {
              product,
              qty: 1,
              reference_year: new Date().getFullYear(),
            },
          ],
        },
        delete_items: true,
      },
    }).then(() => {
      if (token) {
        navigate("/checkout");
      } else {
        navigate("/authentication");
      }
    });
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const diff = moment.duration(moment(expire).valueOf() - moment().valueOf(), "milliseconds");
      setCountDown({
        days: `${diff.days() < 10 ? "0" : ""}${diff.days()}`,
        hours: `${diff.hours() < 10 ? "0" : ""}${diff.hours()}`,
        minutes: `${diff.minutes() < 10 ? "0" : ""}${diff.minutes()}`,
        seconds: `${diff.seconds() < 10 ? "0" : ""}${diff.seconds()}`,
      });
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!countDown) return <></>;
  return (
    <>
      <Container bg={bg}>
        <Title>{title}</Title>
        <Timer>
          <Number>
            {countDown?.days}
            <Label>GIORNI</Label>
          </Number>
          <Number>
            {countDown?.hours}
            <Label>ORE</Label>
          </Number>
          <Number>
            {countDown?.minutes}
            <Label>MINUTI</Label>
          </Number>
          <Number>
            {countDown?.seconds}
            <Label>SECONDI</Label>
          </Number>
        </Timer>
      </Container>
      {buttonText && (
        <CardsContainer>
          <Grid container alignItems="center" justifyContent="center" p={2} className="w-100">
            <Button onClick={onClick}>{buttonText}</Button>
          </Grid>
        </CardsContainer>
      )}
    </>
  );
}

const Form = ({ onChange, data }) => {
  const [products, setProducts] = useState([]);
  const { sendRequest } = useHttp();
  useEffect(() => {
    sendRequest({
      url: `/products`,
      manageData: ({ list }) => {
        setProducts(
          list.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      },
    });
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <FormField
          type="text"
          label="Titolo"
          value={data.title}
          onChange={({ target }) => onChange({ ...data, title: target.value })}
        />
        <FormField
          type="datetime-local"
          label="Data di scadenza (sui cui basare il conto alla rovescia)"
          value={data.expire}
          onChange={({ target }) => onChange({ ...data, expire: target.value })}
        />
        <Select
          label="Sfondo azzurro"
          options={[
            { label: "Si", value: true },
            { label: "No", value: false },
          ]}
          value={data.bg}
          onChange={({ value }) => onChange({ ...data, bg: value })}
          style={{
            zIndex: 9999,
          }}
        />

        <FormField
          type="text"
          label="Testo bottone"
          value={data.buttonText}
          onChange={({ target }) => onChange({ ...data, buttonText: target.value })}
        />
        <Select
          label="Prodotto da aggiungere al carrello"
          options={products}
          value={data.product}
          onChange={({ value }) => onChange({ ...data, product: value })}
          style={{
            zIndex: 9999,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "timer-landing",
  title: "Landing Timer",
  description: "Conto alla rovescia",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
