import video from "@react-page/plugins-video";
import { Video } from "./style";

function Component({ src }) {
  if (!src) return <Video />;
  return <Video src={src.replace("watch?v=", "embed/")}>!</Video>;
}

export default {
  ...video,
  id: "landing-video",
  title: "Landing Video",
  Renderer: ({ data }) => <Component {...data} />,
  description: "Landing Video Youtube",
};
