import useHttp from "hooks/use-http";
import usePagination from "hooks/use-pagination";
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LiveChat from "components/LiveChat/LiveChat";
import FormField from "pagensp/CustomComponents/FormField";
import AuthContext from "pagensp/store/auth-context";

import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React example components
import Footer from "commoncomponent/Footer";
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import DataTable from "commoncomponent/Tables/DataTable";
import Select from "components/Select";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "components/Preloader";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Pagination from "components/Pagination";
import ModalPraticesdataTableData from "./componets/ModalPraticesdataTableData";

// Data
import dataTableData from "./data/PraticesdataTableData";

import Notification from "./componets/Notification";

const orderByOptions = [
  {
    label: "N.Pratica",
    value: `id`,
  },
  {
    label: "Data di firma mandato",
    value: `mandate_signed`,
  },
  {
    label: "Data di completamento pratica",
    value: `done`,
  },
  {
    label: "Ultimo aggiornamento",
    value: `updated_at`,
  },
];

const orderDirectionOptions = [
  {
    label: "Decrescente (Da più recente a più vecchio)",
    value: "DESC",
  },
  {
    label: "Crescente (Da più vecchio a più recente)",
    value: "ASC",
  },
];

const getSorting = () => {
  try {
    const data = JSON.parse(localStorage.getItem("wewelfare_practices_sort"));
    if (!data || data?.length !== 2) throw new Error();
    return data;
  } catch (e) {
    return ["id", "DESC"];
  }
};

// import SidenavProduct from "pagensp/gestproduct/productarchive/componets/Sidenav";
const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function PraticesConfigNsp({ tipo: tipoP }) {
  const {
    currentPage: page,
    paginate: setPage,
    totalPages,
    // calculateTotalPages,
    setTotalPosts,
    nextPage,
    firstPage,
    lastPage,
    prevPage,
    selectPage,
  } = usePagination();
  const authCtx = useContext(AuthContext);
  const [tipo, setTipo] = useState(tipoP);
  const [openChat, setOpenChat] = useState(0);
  const [shouldBeOpen] = useState(false);
  const handleChat = (e) => setOpenChat(e);
  const [roomCustomer, setRoomCustomer] = useState({ name: "ciao" });
  const history = useNavigate();
  const [tagData, setTag] = useState([]);
  const [notification, setNotification] = useState([]);
  const [open, setOpen] = useState(false);
  const [idTag, setIdTag] = useState(0);
  const { isLoggedIn } = useContext(AuthContext);
  const [isSnack, setIsSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState("");
  const [roomSnack, setRoomSnack] = useState("");
  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...tagData] || [] });
  const [numOrder, setNumOrder] = useState(null);
  const [numTransaction, setNumTransaction] = useState(null);
  const [order, setOrder] = useState(getSorting());
  const [user, setUser] = useState(null);
  const [filterProduct, setFilterProduct] = useState(
    localStorage.getItem("wewelfare_practices_product")
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const jwt = localStorage.getItem("token");

  const [room, setRoom] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const handleSelectedRoom = (sRoom) => {
    setOpenChat(1);
    setSelectedRoom(sRoom);
  };

  const [messages, setMessages] = useState([]);
  const { id: pId, nome: pNome } = useParams();

  const { sendRequest: sendRoomRequest, isLoading: isRoomLoading } = useHttp();
  const { sendRequest: sendMessageRequest } = useHttp();
  const { sendRequest: sendTagRequest, isLoading: isTagLoading } = useHttp();
  const { sendRequest: sendNotificationRequest, isLoading: isNotificationLoading } = useHttp();
  const { sendRequest: sendUserRequest, isLoading: isUserLoading } = useHttp();

  const WSS_ENDPOINT = `shared-worker.js?server=${process.env.REACT_APP_WSS_ENDPOINT}`;
  const websocketWorker = new SharedWorker(WSS_ENDPOINT); // da controllare questo - const websocketWorker = useMemo(() => getSocket(), [isShared()]);
  useEffect(() => {
    websocketWorker.port.addEventListener("message", (e) => {
      const { data } = e;

      if (typeof data !== "object") {
        console.log(data);
        return;
      }

      switch (data.type) {
        case "console":
          console.log(data.message);
          break;

        case "keep-alive":
          websocketWorker.port.postMessage(data);
          break;

        case "alert":
          alert(data.message);
          break;
        case "notifications:new":
          setMsgSnack(data && data?.text);
          setRoomSnack("");
          setIsSnack(true);
          setNotification((old) => [...old, data]);
          break;

        case "chat:messages:new":
          setMsgSnack(data && `Messaggio Da utente per ordine ${data?.room}: ${data?.text}`);
          setRoomSnack(data && data?.room);
          setIsSnack(true);

          setMessages((old) => [...old, data]);
          break;

        default:
          console.log("Unrecognized message type:");
          console.log(data);
          break;
      }
    });
    websocketWorker.port.start();
    websocketWorker.onerror = (event) => {
      throw new Error(`${event.message} (${event.filename}: ${event.lineno})`);
    };

    websocketWorker.port.postMessage({ type: "init", token: jwt });
    // setInterval(() => {
    //   websocketWorker.port.postMessage({ type: "ping" });
    // }, 30000);
  }, []);
  const sendMessage = (text) => {
    websocketWorker.port.postMessage({
      type: "chat:messages:new",
      room: selectedRoom,
      text,
    });
  };

  const sendReadMessage = (idMsg) => {
    websocketWorker.port.postMessage({
      type: "chat:messages:read",
      room: selectedRoom,
      message: idMsg,
    });
    setOpenChat(0);
    handleLoadData();
  };

  const handleRooms = ({ list }) => {
    setRoom(list.filter((dataApp) => dataApp?.identifier.includes("order")));
    setRoomCustomer(false);
  };

  const numorderHandler = (e) => setNumOrder(e.target.value);
  const numtransactionHandler = (e) => setNumTransaction(e.target.value);
  const paginationCallback = (pag) => (e) => setPage(pag);
  const search = () => {
    firstPage();
    setTotalPosts(0);
    handleLoadData();
  };

  const handleLoadData = () => {
    const filtersType = {
      miepratiche: `["operator","=",${authCtx.uid}],["deleted_at","=",null]`,
      sospeso: `["operator","=",null],["done","=",null],["refund","=",null],["deleted_at","=",null]`,
      corso: `["operator","!=",null],["done","=",null],["deleted_at","=",null]`,
      complete: `["done","!=",null],["deleted_at","=",null]`,
      richiestarimborso: `["refund","=","requested"],["deleted_at","=",null]`,
      richiestaapprovata: `["refund","=","approved"],["deleted_at","=",null]`,
      richiestarifiutata: `["refund","=","rejected"],["deleted_at","=",null]`,
      praticheeliminate: `["deleted_at","!=",null]`,
    };
    const filters = `filters=[${filtersType[tipoP] || ""}${pId ? `,["user", "=", "${pId}"]` : ""}${
      numOrder ? `,["id", "=", "${numOrder}"]` : ""
    }${numTransaction ? `,["transaction", "=", "${numTransaction}"]` : ""}${
      filterProduct ? `,["product", "=", "${filterProduct}"]` : ""
    }]`;

    // Salvo in cache per tenere traccia dell'aggiornamento selezionato
    localStorage.setItem("wewelfare_practices_sort", JSON.stringify(order));
    if (filterProduct) {
      localStorage.setItem("wewelfare_practices_product", filterProduct);
    } else {
      localStorage.removeItem("wewelfare_practices_product");
    }

    // Ottengo la pagina ordini
    sendTagRequest({
      url: `/orders?${filters}&per_page=25&page=${page}&sort=[${JSON.stringify(order)}]`,
      manageData: ({ list, tot }) => {
        if (list.length === 0) {
          manageTagData({
            list,
            rooms: [],
          });
          setTotalPosts(tot);
          return;
        }
        // Prendo le chatrooms di questi ordini e aggiorno lo stato
        sendRoomRequest({
          url: `/chatrooms?identifier=${list.map(({ id }) => `order-${id}`).join(",")}`,
          methodH: "GET",
          manageData: (e) => {
            handleRooms(e);
            manageTagData({
              list,
              rooms: e.list,
            });
            setTotalPosts(tot);
          },
        });
      },
    });

    sendNotificationRequest({
      url: `/notifications`,
      manageData: ({ list }) => {
        if (list.filter(({ read }) => read === null).length > 0) {
          setMsgSnack("Hai nuove notifiche da leggere!");
          setIsSnack(true);
        }
        setNotification([...list]);
      },
    });
  };

  const readNotification = (nId) => {
    websocketWorker.port.postMessage({
      type: "notifications:read",
      notification: nId,
    });
    sendNotificationRequest({
      url: "/notifications",
      manageData: ({ list }) => setNotification(list),
    });
  };

  const manageTagData = ({ list, rooms }) => {
    setTag([
      ...list.map((item) => ({
        id: item?.id,
        product: item?.product.text,
        user: item?.user.text,
        created: item?.created,
        paid: item?.paid,
        refund: item?.refund,
        transaction: item?.transaction,
        paidmethod: item?.payment_method,
        signedfile: item?.signed_file,
        operator: item.operator !== null ? item.operator.text : null,
        expectedDate: {
          expected: item?.expected_date,
          type: item?.product_type,
          subscriptiondate: item?.subscription_ref_date,
          subscriptionnum: item?.subscription_num,
          subscriptiontot: item?.subscription_tot,
        },
        clientProduct: {
          product: item?.product.text,
          client: item?.user.text,
          operator: item.operator !== null ? item.operator.text : null,
          payment_method: item?.payment_method,
        },
        date: {
          acquisto: item?.created,
          mandato: item?.mandate_signed,
          consegnaStimata: item?.expected_date,
          done: item?.done,
          updatedAt: item?.updated_at,
          deletedAt: item?.deleted_at,
          statoPratica: (() => {
            if (item.refund) return "RIMBORSATA";
            if (!item.refund && item.done) return "COMPLETA";
            if (!item.refund && !item.done && !item.operator) return "SOSPESO";
            return "IN CORSO";
          })(),
          consegna: {
            expected: item?.expected_date,
            type: item?.product_type,
            subscriptiondate: item?.subscription_ref_date,
            subscriptionnum: item?.subscription_num,
            subscriptiontot: item?.subscription_tot,
          },
        },
        pratica: {
          idPratica: item?.id,
          idOrdine: item?.transaction,
        },
        stato: {
          statoPratica: (() => {
            if (item.refund) return "RIMBORSATA";
            if (!item.refund && item.done) return "COMPLETA";
            if (!item.refund && !item.done && !item.operator) return "SOSPESO";
            return "IN CORSO";
          })(),
          pagato: item.paid,
          stepCorrente: item?.current_step
            ? item?.steps?.find((e) => e.id === item?.current_step)
            : null,
          stepCompletati: item?.current_step
            ? item?.steps?.findIndex((e) => e.id === item?.current_step) + 1
            : 0,
          stepTotali: item?.steps?.length,
          emailStatus: item?.email_status,
        },
        actions: {
          edit: {
            action: () => {
              history(`/dettagliopratica/${item?.id}`);
            },
          },
          editblank: {
            link: `/dettagliopratica/${item?.id}`,
            blank: true,
          },
          chat: {
            action: () => {
              handleSelectedRoom(`order-${item?.id}`);
            },
            number: rooms?.find((e) => e.identifier === `order-${item?.id}`)?.unread || 0,
            operator: item.operator !== null ? item.operator.text : null,
          },
        },
      })),
    ]);
  };

  useEffect(() => {
    firstPage();
    setTotalPosts(0);
    setTipo(tipoP);
  }, [tipoP]);

  useEffect(() => {
    handleLoadData();
  }, [page, tipoP]);

  useEffect(() => {
    if (selectedRoom && isLoggedIn) {
      sendMessageRequest({
        url: `/chat/${selectedRoom}`,
        manageData: ({ list }) => setMessages(list),
      });
    }
  }, [selectedRoom]);

  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...tagData] });
  }, [tagData]);

  useEffect(() => {
    sendUserRequest({
      url: `/users/${authCtx.uid}`,
      manageData: (eUser) => {
        setUser(eUser.data);
      },
    });
  }, []);
  const productsOptions = useMemo(
    () => [
      { label: "Tutti", value: "" },
      ...(user?.products?.length > 0
        ? user?.products
            ?.map(({ product }) => ({
              label: product.text,
              value: product.id,
            }))
            ?.filter((item, index, list) =>
              list.findIndex((e, i) => e.value === item.value && index !== i)
            )
            ?.sort((a, b) => (a.label < b.label ? -1 : 1))
        : []),
    ],
    user
  );

  return (
    <DashboardLayout>
      <LiveChat
        {...{
          selectedRoom,
          sendMessage,
          handleSelectedRoom,
          room,
          roomCustomer,
          messages,
          sendReadMessage,
        }}
        index={1}
        indexChat={openChat}
        handleChat={handleChat}
        shouldBeOpen={shouldBeOpen}
      />
      <Snackbar
        variant="error"
        open={isSnack}
        autoHideDuration={20000}
        onClose={() => setIsSnack(false)}
        onClick={() => {
          if (roomSnack === "") setTipo("notifiche");
          if (roomSnack !== "") handleSelectedRoom(roomSnack);

          setIsSnack(false);
        }}
      >
        <Alert onClose={() => setIsSnack(false)} severity="error" sx={{ width: "100%" }}>
          {msgSnack}
        </Alert>
      </Snackbar>
      <DashboardNavbar />
      {tipo !== "notifiche" ? (
        <>
          <Grid container alignItems="center">
            <Grid item xs={12} md={7}>
              <MDBox mb={1}>
                <MDTypography variant="h5">
                  {tipo === "miepratiche" && <>Le mie pratiche</>}
                  {tipo !== "miepratiche" && (
                    <>
                      Elenco pratiche {tipo} {pNome}
                    </>
                  )}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormField onChange={numorderHandler} type="text" label="Ricerca numero pratica" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormField
                  onChange={numtransactionHandler}
                  type="text"
                  label="Ricerca numero Ordine"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <Select
                  label="Filtra prodotto"
                  value={filterProduct}
                  name="product"
                  options={productsOptions}
                  onChange={({ value }) => setFilterProduct(value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  label="Ordina per campo"
                  value={order[0]}
                  name="orderBy"
                  options={orderByOptions}
                  onChange={({ value }) => setOrder((prev) => [value, prev[1]])}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  label="Ordinamento"
                  value={order[1]}
                  name="direction"
                  options={orderDirectionOptions}
                  onChange={({ value }) => setOrder((prev) => [prev[0], value])}
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton color="primary" style={{ opacity: "1" }} onClick={search}>
                  Ricerca
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <MDBox pt={2}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} lg={12}>
                <Card>
                  <Preloader isLoading={isTagLoading || isRoomLoading}>
                    {tagData.length ? (
                      <DataTable
                        handleOpen={handleOpen}
                        table={dataTable}
                        entriesPerPage={{ entries: [25], defaultValue: 25 }}
                        isSorted={false}
                      />
                    ) : (
                      <p className="text-center py-2"> Non ci sono record disponibili al momento</p>
                    )}
                  </Preloader>
                  <Pagination
                    activePage={page}
                    onNext={nextPage}
                    onPrev={prevPage}
                    numbersOfPage={totalPages}
                    callback={paginationCallback}
                    onLast={lastPage}
                    onFirst={firstPage}
                    selectPage={selectPage}
                  />
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <ModalPraticesdataTableData
            open={open}
            handleClose={handleClose}
            handleLoadData={handleLoadData}
            idTag={idTag}
          />
        </>
      ) : (
        <>
          {notification.length ? (
            <Notification
              notification={notification}
              isNotificationLoading={isNotificationLoading}
              readNotification={readNotification}
            />
          ) : (
            <p className="text-center py-2"> Non ci sono record disponibili al momento</p>
          )}
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default PraticesConfigNsp;
