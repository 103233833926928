import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const AuthContext = React.createContext({
  token: "",
  session_token: "",
  profiletype: "",
  configurations: [{}],
  isLoggedIn: false,
  userData: {},
  // eslint-disable-next-line
  login: (token, sessionToken) => {},
  // eslint-disable-next-line
  setprofile: (profiletype) => {},
  setidoperatore: () => {},
  setidtouroperator: () => {},
  setslugcampo: () => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const initialToken = localStorage.getItem("token");
  const initialTypeUser = localStorage.getItem("type_user");
  const initialsessionToken = localStorage.getItem("session_token");
  const initialUserData = JSON.parse(localStorage.getItem("userData") || "{}");
  const [token, setToken] = useState(initialToken);
  const history = useNavigate();

  const [uid, setUid] = useState(localStorage.getItem("uid"));
  // eslint-disable-next-line
  const [sessiontoken, setSessionToken] = useState(initialsessionToken);
  // eslint-disable-next-line
  const [profiletype, setProfiletype] = useState();
  const [typeUser, setTypeUser] = useState(initialTypeUser);
  const [userData, setUserData] = useState(initialUserData || {});
  const userIsLoggedIn = !!token;

  const loginHandler = (tokenL, sessionToken) => {
    const decode = jwtDecode(tokenL);
    setToken(tokenL);
    setSessionToken(sessionToken);
    localStorage.setItem("token", tokenL);
    localStorage.setItem("session_token", sessionToken);
    localStorage.setItem("type_user", decode.type);
    setUid(decode.id);
    localStorage.setItem("uid", decode.id);
    setTypeUser(decode.type);
    setUserData(decode);
    localStorage.setItem("userData", JSON.stringify(decode));
  };
  const profileHandler = (idprofile) => {
    setProfiletype(idprofile);
    localStorage.setItem("idprofile", idprofile);
  };

  const logoutHandler = () => {
    setToken(null);
    setSessionToken(null);
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    localStorage.removeItem("type_user");
    localStorage.removeItem("session_token");
    localStorage.removeItem("uid");
    history(`/login`);
  };
  const contextValue = {
    token,
    typeUser,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    setProfile: profileHandler,
    logout: logoutHandler,
    uid,
    userData,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
export default AuthContext;
