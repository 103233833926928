/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "components/Select";
import useHttp from "hooks/use-http";
import FormField from "pagensp/CustomComponents/FormField";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardsContainer, Container, Title, Description, Button } from "./style";

function Component({ title, description, buttonText, bg, product }) {
  const navigate = useNavigate();
  const { sendRequest } = useHttp();
  const onClick = () => {
    if (window.location.origin.includes("gestionale")) {
      alert(`Prodotto associato ${product}`);
      return;
    }
    const secret = localStorage.getItem("cartSecret");
    const id = localStorage.getItem("cartID");

    if (!secret || !id) return;
    const token = localStorage.getItem("token");
    sendRequest({
      url: `/cart/${id}?secret=${secret}`,
      methodH: "PUT",
      bodyH: {
        data: {
          items: [
            {
              product,
              qty: 1,
              reference_year: new Date().getFullYear(),
            },
          ],
        },
        delete_items: true,
      },
    }).then(() => {
      if (token) {
        navigate("/checkout");
      } else {
        navigate("/authentication");
      }
    });
  };

  return (
    <Container bg={bg}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      <CardsContainer>
        <Grid container alignItems="center" justifyContent="center" p={2} className="w-100">
          <Button onClick={onClick}>{buttonText}</Button>
        </Grid>
      </CardsContainer>
    </Container>
  );
}

const Form = ({ onChange, data }) => {
  const [products, setProducts] = useState([]);
  const { sendRequest } = useHttp();
  useEffect(() => {
    sendRequest({
      url: `/products`,
      manageData: ({ list }) => {
        setProducts(
          list.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      },
    });
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} mb={1}>
        <FormField
          type="text"
          label="Titolo"
          value={data.title}
          onChange={({ target }) => onChange({ ...data, title: target.value })}
        />
        <FormField
          type="text"
          label="Descrizione"
          value={data.description}
          onChange={({ target }) => onChange({ ...data, description: target.value })}
        />
        <Select
          label="Sfondo azzurro"
          options={[
            { label: "Si", value: true },
            { label: "No", value: false },
          ]}
          value={data.bg}
          onChange={({ value }) => onChange({ ...data, bg: value })}
          style={{
            zIndex: 9999,
          }}
        />
        <FormField
          type="text"
          label="Testo bottone"
          value={data.buttonText}
          onChange={({ target }) => onChange({ ...data, buttonText: target.value })}
        />
        <Select
          label="Prodotto da aggiungere al carrello"
          options={products}
          value={data.product}
          onChange={({ value }) => onChange({ ...data, product: value })}
          style={{
            zIndex: 9999,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "cta-product",
  title: "CTA Acquista Prodotto",
  description: "CTA per pulsante acquista prodotto",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
