import { useState, useEffect } from "react";
import useHttp from "hooks/use-http";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

import styleModal from "assets/theme/base/modalconfig";

import Grid from "@mui/material/Grid";

function ModalPersonalDocumentation({ open, handleClose, handleLoadData, idDocument }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [validity, setValidity] = useState();
  const [idProduct, setIdProduct] = useState();
  const [idCat, setIdCat] = useState();
  const [productData, setProductData] = useState([]);
  const [categoryDoc, setCategoryDoc] = useState([]);
  const [sigleProductData, setSigleProductData] = useState({ label: "", value: null });
  const [sigleCategoryDocData, setSingleCategoryDocData] = useState({ label: "", value: "" });

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();
  const { sendRequest: sendSingleProductRequest, isLoading: isSingleCatLoading } = useHttp();

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e);
  };

  const validitynHandler = (e) => {
    setValidity(e.value);
  };

  const idSubHandler = (e) => {
    if (e) {
      setIdProduct(e.value);
    } else {
      setIdProduct(0);
    }
  };

  const idSubCatHandler = (e) => {
    if (e) {
      setIdCat(e.value);
    } else {
      setIdCat(0);
    }
  };

  const manageSubCategoryData = ({
    name: nameF,
    product: idProductF,
    description: textF,
    validity: validityG,
    category: categoryG,
  }) => {
    setName(nameF);
    setDescription(textF);
    setValidity(validityG);
    if (idProductF) {
      setSigleProductData({ value: idProductF.id, label: idProductF.text });
    } else {
      setSigleProductData({ value: "", label: "" });
    }
    if (categoryG) {
      setSingleCategoryDocData({ value: categoryG.id, label: categoryG.text });
    } else {
      setSingleCategoryDocData({ value: "", label: "" });
    }
    setIdProduct(idProductF.id);
    setIdCat(categoryG.id);
  };

  useEffect(() => {
    if (open && idDocument) {
      sendSingleProductRequest({
        methodH: "GET",
        url: `/documents/${idDocument}`,
        manageData: (e) => {
          manageSubCategoryData(e.data); // la funzione per la mappatura dei dati
        },
      })
        .then(() => {
          handleLoadDataCat();
        })
        .then(() => {
          handleLoadDataCatDoc();
        });
    } else if (open && idDocument === 0) {
      setName("");
      setIdProduct(0);
      setIdCat(0);
      setDescription("");
      handleLoadDataCat();
      handleLoadDataCatDoc();
      setSigleProductData({ value: "", label: "" });
      setSingleCategoryDocData({ value: "", label: "" });
    }
  }, [idDocument, open]);

  const saveHandler = () => {
    if (open && idDocument === 0) {
      const data = {
        data: {
          name,
          description,
          product: idProduct === 0 ? null : idProduct,
          validity,
          category: idCat === 0 ? null : idCat,
        },
      };
      sendSaveRequest({
        methodH: "POST",
        url: `/documents`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    } else if (open && idDocument) {
      const data = {
        data: {
          name,
          description,
          product: idProduct === 0 ? null : idProduct,
          validity,
          category: idCat === 0 ? null : idCat,
        },
      };
      sendSaveRequest({
        methodH: "PUT",
        url: `/documents/${idDocument}`,
        bodyH: data,
      }).then(() => {
        handleLoadData();
      });
      handleClose();
    }
  };

  const {
    sendRequest: sendCategoryRequest,
    isLoading: isProductsLoading,
    error: productsError,
  } = useHttp();

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadDataCat = () => {
    sendCategoryRequest({
      url: "/products",
      manageData: (e) => {
        manageCategoryData(e.list); // la funzione per la mappatura dei dati
      },
    });
  };

  // Riassegno le variabile da api
  const manageCategoryData = (list) => {
    const appData = list.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setProductData([...appData]);
  };

  const {
    sendRequest: sendCategoryDocRequest,
    isLoading: isCategoryDocLoading,
    error: categoryDocError,
  } = useHttp();

  // useEffect in cui chiamo la funzione per mappare i dati
  const handleLoadDataCatDoc = () => {
    sendCategoryRequest({
      url: "/documents-categories",
      manageData: (e) => {
        manageCategoryDocData(e.list); // la funzione per la mappatura dei dati
      },
    });
  };

  // Riassegno le variabile da api
  const manageCategoryDocData = (list) => {
    const appData = list.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setCategoryDoc([...appData]);
  };

  function SwitchCaseValidity(dataApp) {
    switch (dataApp) {
      case "always":
        return "Non Scade";
      case "expiration":
        return "Scade";
      case "yearly":
        return "Validità annuale";
      default:
        return "";
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={styleModal} pt={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <FormField
                type="text"
                label="Documento"
                defaultValue=""
                value={name}
                onChange={nameHandler}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Autocomplete
                onChange={(event, value) => {
                  idSubCatHandler(value);
                }}
                options={categoryDoc}
                value={sigleCategoryDocData}
                renderInput={(params) => (
                  <FormField {...params} label="Categoria" InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Descrizione
                </MDTypography>
              </MDBox>
              <MDEditor
                value={description}
                onChange={(e) => {
                  descriptionHandler(e);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Autocomplete
                onChange={(event, value) => {
                  idSubHandler(value);
                }}
                value={sigleProductData}
                options={productData}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Prodotto Acquistabile"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Autocomplete
                value={{ label: SwitchCaseValidity(validity), value: validity }}
                options={[
                  { label: "Non scade", value: "always" },
                  { label: "Scade", value: "expiration" },
                  { label: "Validità annuale", value: "yearly" },
                ]}
                onChange={(event, data) => {
                  validitynHandler(data);
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Seleziona validità documento"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                Inserisci
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Fade>
    </Modal>
  );
}
export default ModalPersonalDocumentation;
