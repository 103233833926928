import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import Footer from "commoncomponent/Footer";
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";

// Custom Hooks
import useHttp from "hooks/use-http";
import { useNavigate, useParams } from "react-router-dom";
// EditProduct page components
import { platforms } from "config";
import CustomFaq from "pagensp/gestproduct/productdetail/components/CustomFaq/CustomFaq";
import ProductDxSidebar from "pagensp/gestproduct/productdetail/components/ProductDxSidebar/ProductDxSidebar";
import ProductImage from "pagensp/gestproduct/productdetail/components/ProductImage/ProductImage";
import ProductInfo from "pagensp/gestproduct/productdetail/components/ProductInfo/ProductInfo";
import SelectPrersonalInformation from "pagensp/gestproduct/productdetail/components/SelectPrersonalInformation/SelectPrersonalInformation";
import SelectPrersonaldocumentation from "pagensp/gestproduct/productdetail/components/SelectPrersonaldocumentation/SelectPrersonaldocumentation";
import SelectProduct from "pagensp/gestproduct/productdetail/components/SelectProduct/SelectProduct";
import AiDataPreavvisoDimissioni from "./components/AiData/AiDataPreavvisoDimissioni";
import AiDataScopriBonus from "./components/AiData/AiDataScopriBonus";

function EditProductNsp() {
  const { id: pId } = useParams();

  const [attachments, setAttachments] = useState([]);
  const [attachmentsSend, setAttachmentsSend] = useState([]);
  const [category, setCategory] = useState({ text: "", id: "" });
  const [categorySend, setCategorySend] = useState();
  const [consultingAllowed, setConsultingAllowed] = useState(false);
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentsSend, setDocumentsSend] = useState([]);
  const [personalFields, setPersonalFields] = useState([]);
  const [personalFieldsSend, setPersonalFieldsSend] = useState([]);
  const [faq, setFaq] = useState([]);
  const [fixedMonthDay, setFixedMonthDay] = useState([]);
  const [home, setHome] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [images, setImages] = useState([]);
  const [imgCat, setImgCat] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileImageGallery, setFileImageGallery] = useState([]);
  const [name, setName] = useState("");
  const [numIterations, setNumIterations] = useState(0);
  const [products, setProducts] = useState([]);
  const [productsSend, setProductsSend] = useState([]);
  const [steps, setSteps] = useState([]);
  const [subCategory, setSubcategory] = useState({ text: "", id: "" });
  const [subCategorySend, setSubcategorySend] = useState();
  const [tags, setTags] = useState([]);
  const [activePlatforms, setActivePlatforms] = useState([]);
  const [tagsSend, setTagsSend] = useState([]);
  const [type, setType] = useState("");
  const [visible, setVisible] = useState(false);
  const [price, setPrice] = useState(0);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [aiData, setAiData] = useState(null);
  const navigate = useNavigate();

  const {
    // isLoading: isProductLoading,
    sendRequest: sendProductRequest,
    // error: productError,
  } = useHttp();

  const {
    // isLoading: isDocumentsLoading,
    sendRequest: sendDocumentsRequest,
    // eror: documentsError,
  } = useHttp();

  const [allDocuments, setAllDocuments] = useState([]);

  const manageDocumentsData = (doc) => {
    setAllDocuments(doc.list);
  };

  const addDocument = (newDoc) => {
    setDocuments((old) => [...old, newDoc]);
  };

  const replaceDocument = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      if (element.document.text) {
        oldApp[i] = { document: "", required: "" };
        oldApp[i].document = element.document.id;
        oldApp[i].required = element.required;
      } else {
        oldApp[i] = element;
      }
    });
    setDocumentsSend([...oldApp]);
  };

  const editDocumentValue = (value, index) => {
    const old = [...documents];
    if (value === "del") {
      old.splice(index, 1);
    } else {
      old[index].document = value;
    }
    replaceDocument(old);
    setDocuments([...old]);
  };

  const editRequiredDocumentValue = (index) => {
    const old = [...documents];
    old[index].required = !old[index].required;
    replaceDocument(old);
    setDocuments([...old]);
  };

  useEffect(() => {
    sendDocumentsRequest({ url: "/documents", manageData: manageDocumentsData });
  }, []);

  // Package
  const [allPackage, setAllPackage] = useState([]);

  useEffect(() => {
    sendPersonalFieldsRequest({ url: "/products", manageData: managePackage, cache: false });
  }, []);

  const managePackage = (doc) => {
    setAllPackage(doc.list);
  };

  const addPackage = (newPackage) => {
    setProducts((old) => [...old, newPackage]);
  };

  const replacePackage = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      if (element.product.text) {
        oldApp[i] = { product: "" };
        oldApp[i].product = element.product.id;
      } else {
        oldApp[i] = element;
      }
    });
    setProductsSend([...oldApp]);
  };

  const editPackageValue = (controllerApp, value, index) => {
    const old = [...products];
    if (value === "del") {
      old.splice(index, 1);
    } else {
      old[index].product = value;
    }
    replacePackage(old);
    setProducts([...old]);
  };
  // Fine Package

  // Category e Sub Category
  const {
    isLoading: isCategoryLoading,
    sendRequest: sendCategoryRequest,
    eror: categoryError,
  } = useHttp();

  const {
    isLoading: isSubCategoryLoading,
    sendRequest: sendSubCategoryRequest,
    eror: subCategoryError,
  } = useHttp();

  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);

  useEffect(() => {
    sendCategoryRequest({ url: "/categories", manageData: manageCategory });
  }, []);

  const manageCategory = (doc) => {
    setAllCategory(doc.list);
  };
  const replaceCategory = (data) => {
    if (data && data.text) {
      setCategorySend(data.id);
    }
  };
  const editCategory = (data, op) => {
    if (op === "del") {
      setCategory(null);
      setSubcategory(null);
      setCategorySend(null);
      setSubcategorySend(null);
    } else {
      setCategorySend(data.value);
      setCategory({ value: data.value, label: data.label });
      sendSubCategoryRequest({
        url: `/subcategories?filters=[["category","=",${data.value}]]`,
        manageData: manageSubCategory,
      });
    }
  };
  const replaceSubCategory = (data) => {
    if (data && data.text) {
      setSubcategorySend(data.id);
    }
  };
  const editSubCategory = (data, op) => {
    if (op === "del") {
      setSubcategory(null);
      setSubcategorySend(null);
    } else {
      setSubcategorySend(data.value);
      setSubcategory({ value: data.value, label: data.label });
    }
  };
  const manageSubCategory = (doc) => {
    setAllSubCategory(doc.list);
  };

  useEffect(() => {
    if (categorySend) {
      sendSubCategoryRequest({
        url: `/subcategories?filters=[["category","=",${categorySend}]]`,
        manageData: manageSubCategory,
      });
    }
  }, [categorySend]);
  // Fine Category e Sub Category

  // Personal info
  const {
    isLoading: isPersonalFieldsLoading,
    sendRequest: sendPersonalFieldsRequest,
    eror: personalFieldsError,
  } = useHttp();

  const [allPersonalFields, setAllPersonalFields] = useState([]);

  const handleMetaTitle = (e) => {
    setMetaTitle(e.target.value);
  };

  const handleMetaDescription = (e) => {
    setMetaDescription(e.target.value);
  };

  const managePersonalFieldsData = (doc) => {
    setAllPersonalFields(doc.list);
  };

  const addPersonalFields = (newPersonalFields) => {
    setPersonalFields((old) => [...old, newPersonalFields]);
  };

  const replaceField = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      if (element.field.text) {
        oldApp[i] = { field: "", required: false };
        oldApp[i].field = element.field.id;
        oldApp[i].required = element.required;
      } else {
        oldApp[i] = element;
      }
    });
    setPersonalFieldsSend([...oldApp]);
  };

  const editPersonalFieldsValue = (controllerApp, value, index) => {
    const old = [...personalFields];
    if (value === "del") {
      old.splice(index, 1);
    } else {
      old[index].field = value;
    }
    replaceField(old);
    setPersonalFields([...old]);
  };

  const editRequiredPersonalFieldsValue = (index) => {
    const old = [...personalFields];
    old[index].required = !old[index].required;
    replaceField(old);
    setPersonalFields([...old]);
  };

  useEffect(() => {
    sendPersonalFieldsRequest({ url: "/fields", manageData: managePersonalFieldsData });
  }, []);
  // Fine Personal Info

  // Steps
  const addSteps = (newSteps) => {
    setSteps((old) => [...old, newSteps]);
  };

  const editStepsDescriptionValue = (value, index) => {
    const old = [...steps];
    old[index].description = value;
    setSteps([...old]);
  };

  const editStepsDaysValue = (value, index) => {
    const old = [...steps];
    old[index].days = Number(value);
    setSteps([...old]);
  };

  const editMakeBusyValue = (index) => {
    const old = [...steps];
    old[index].makes_busy = !old[index].makes_busy;
    setSteps([...old]);
  };

  const editToSigneValue = (index) => {
    const old = [...steps];
    old[index].to_sign = !old[index].to_sign;
    setSteps([...old]);
  };

  const editToUploadValue = (index) => {
    const old = [...steps];
    old[index].requires_file = !old[index].requires_file;
    setSteps([...old]);
  };

  const deleteSteps = (index) => {
    const old = [...steps];
    old.splice(index, 1);
    setSteps([...old]);
  };
  // Fine steps

  // Images
  const replaceImage = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      oldApp[i] = { id: "" };
      oldApp[i].id = element.id;
      oldApp[i].main = element.main;
    });
    setImgCat([...oldApp]);
  };

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
    const filesx = incommingFiles[0].file;
    const reader = new FileReader();
    reader.readAsDataURL(filesx);
    reader.onload = (e) => {
      console.log(e, "immagine main");
      const mime = e.target.result.match(/^data:(.*);/)[1];
      const image = e.target.result.replace(`data:${mime};base64,`, "");
      const mainimage = {
        img: {
          name: filesx.name,
          data: image,
        },
        description: "",
        main: true,
      };
      setImgCat((old) => [...old, mainimage]);
    };
  };

  const updateFilesGallery = (incommingFiles) => {
    setFileImageGallery(incommingFiles);
    const filesx = incommingFiles[0].file;
    const reader = new FileReader();
    reader.readAsDataURL(filesx);
    reader.onload = (e) => {
      const mime = e.target.result.match(/^data:(.*);/)[1];
      const image = e.target.result.replace(`data:${mime};base64,`, "");
      const mainimage = {
        img: {
          name: filesx.name,
          data: image,
        },
        description: "",
        main: false,
      };
      setImgCat((old) => [...old, mainimage]);
    };
  };

  const imgHandlerRemove = () => {
    setImgCat((old) => old.filter((data) => data.main !== true));
    setImages((old) => old.filter((data) => data.main !== true));
  };

  const imgGalleryHandlerRemove = (index) => {
    setImgCat((old) => old.filter((data) => data.id !== index));
    setImages((old) => old.filter((data) => data.id !== index));
  };
  // Fine images

  // Documentazione prodotto
  const replaceAttachments = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      oldApp[i] = { id: "" };
      oldApp[i].id = element.id;
    });
    setAttachmentsSend([...oldApp]);
  };

  const updateAttachments = (incommingFiles) => {
    const filesx = incommingFiles.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(filesx);
    reader.onload = (e) => {
      const mainAttachments = {
        file: {
          name: filesx.name,
          data: e.target.result.replace("data:image/jpeg;base64,", ""),
        },
      };
      setAttachments((old) => [...old, mainAttachments]);
      setAttachmentsSend((old) => [...old, mainAttachments]);
    };
  };

  const attachmentsHandlerRemove = (index) => {
    setAttachments((old) => old.filter((data) => data.id !== index));
    setAttachmentsSend((old) => old.filter((data) => data.id !== index));
  };
  // Fine Documentazione prodotto

  // Tag
  const [allTag, setAllTag] = useState([]);
  const { sendRequest: sendTagRequest, isLoading: isTagLoading } = useHttp();

  useEffect(() => {
    sendTagRequest({ url: "/tags", manageData: manageTag });
  }, []);

  const manageTag = (doc) => {
    setAllTag(doc.list);
  };
  const replaceTag = (data) => {
    const oldApp = [];
    data.forEach((element, i) => {
      oldApp[i] = { value: "", label: "" };
      oldApp[i].value = element.tag.id;
      oldApp[i].label = element.tag.text;
    });
    setTags([...oldApp]);
  };

  const editTag = (value) => {
    setTags(value);
    const oldApp = [];
    value.forEach((element, i) => {
      oldApp[i] = { tag: "" };
      oldApp[i].tag = element.value;
    });
    setTagsSend([...oldApp]);
  };

  const faqHandlerRemove = (index) => {
    setFaq((old) => [
      ...old.filter((_, i) => {
        if (i === index) {
          return false;
        }
        return true;
      }),
    ]);
  };
  // fine Tag

  // Faq
  const addFaqSteps = (newFaq) => {
    setFaq((old) => [...old, newFaq]);
  };

  const editFaqTitleValue = (value, index) => {
    setFaq((old) => [
      ...old.map((faqApp, inde) => {
        if (inde === index) {
          return { ...faqApp, title: value };
        }
        return { ...faqApp };
      }),
    ]);
    // const old = [...faq];
    // old[index].title = value;
    // setFaq([...old]);
  };

  const editFaqTextValue = (value, index) => {
    setFaq((old) => [
      ...old.map((faqApp, inde) => {
        if (inde === index) {
          return { ...faqApp, text: value };
        }
        return { ...faqApp };
      }),
    ]);
    // const old = [...faq];
    // old[index].text = value;
    // setFaq([...old]);
  };
  // Fine Faq

  const {
    sendRequest: sendDelProductRequest,
    isLoading: isDelProductLoading,
    error: delDelProductError,
  } = useHttp();

  const removeProduct = () => {
    if (window.confirm("Sei sicuro di voler eliminare questa Categoria")) {
      sendDelProductRequest({
        methodH: "DELETE",
        url: `/products/${pId}`,
        cache: false,
      }).then(() => {});
    }
  };

  const { sendRequest: sendSaveRequest, isLoading: isSaveLoading } = useHttp();

  const manageProductGet = ({
    attachments: attachmentsG,
    category: categoryG,
    consulting_allowed: consultingAllowedG,
    description: descriptionG,
    short_description: shortDescriptionG,
    documents: documentsG,
    faq: faqG,
    fields: fieldsG,
    fixed_month_day: fixedMonthDayG,
    home: homeG,
    is_yearly: isYearlyG,
    // id: idG,
    images: imagesG,
    name: nameG,
    num_iterations: numIterationsG,
    price: priceG,
    products: productsG,
    steps: stepsG,
    subcategory: subCategoryG,
    tags: tagsG,
    type: typeG,
    visible: visibleG,
    meta_description: metaG,
    meta_title: metaT,
    platforms: savedPlatforms,
    ai_data: aiDataP,
  }) => {
    setAttachments(attachmentsG);
    replaceAttachments(attachmentsG);
    setCategory(categoryG);
    replaceCategory(categoryG);
    setConsultingAllowed(consultingAllowedG);
    setDescription(descriptionG);
    setShortDescription(shortDescriptionG);
    setDocuments(documentsG);
    replaceDocument(documentsG);
    setFaq(faqG);
    setPersonalFields(fieldsG);
    replaceField(fieldsG);
    setFixedMonthDay(fixedMonthDayG);
    setHome(homeG);
    setIsYearly(isYearlyG);
    setImages(imagesG);
    replaceImage(imagesG);
    setName(nameG);
    setNumIterations(numIterationsG);
    setProducts(productsG);
    replacePackage(productsG);
    setSteps(stepsG);
    replaceSubCategory(subCategoryG);
    setSubcategory(subCategoryG);
    // setTags(tagsG);
    replaceTag(tagsG);
    setActivePlatforms(
      platforms.filter((item) => savedPlatforms?.split(",").includes(item.value)) || []
    );
    setType(typeG);
    setVisible(visibleG);
    setPrice(priceG);
    setMetaDescription(metaG);
    setMetaTitle(metaT);
    try {
      setAiData([...aiDataP.map((item) => ({ ...item, data: JSON.parse(item.data) }))]);
    } catch (e) {
      setAiData(aiDataP);
    }
  };
  useEffect(() => {
    if (pId) {
      sendProductRequest({
        url: `/products/${pId}`,
        cache: false,
        manageData: ({ data }) => {
          manageProductGet(data);
        },
      });
    }
  }, [pId]);

  const typeHandler = (e) => {
    setType(e);
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e);
  };

  const shortDescriptionHandler = (e) => {
    setShortDescription(e.target.value);
  };

  const visibleHandler = () => {
    setVisible((old) => !old);
  };
  const homeHandler = () => {
    setHome((old) => !old);
  };
  const isYarlyHandler = () => {
    setIsYearly((old) => !old);
  };

  const consultingHandler = () => {
    setConsultingAllowed((old) => !old);
  };
  const priceHandler = (e) => {
    const appPrice = e.target.value;
    setPrice(parseFloat(appPrice));
  };

  const fixedMonthDayHandler = (e) => {
    setFixedMonthDay(Number(e));
  };

  const setNumIterationsHendler = (e) => {
    setNumIterations(Number(e));
  };

  // name: string
  // price: float
  // type: enum(package, paperwork, consulting, subscription)
  // description: html

  // Nota Alex - da definire e provare come si comporta in caso di consulenza
  const saveHandler = () => {
    const data = {
      data: {
        name,
        price,
        type,
        description,
        num_iterations: type === "subscription" ? numIterations : 1,
        documents: documentsSend,
        fields: personalFieldsSend,
        steps:
          type === "paperwork" || type === "consulting" || type === "subscription" ? steps : [],
        fixed_month_day: type === "subscription" ? fixedMonthDay : null,
        home,
        consulting_allowed: consultingAllowed,
        visible,
        products: type === "package" ? productsSend : [],
        category: categorySend,
        subcategory: subCategorySend,
        images: imgCat,
        tags: tagsSend,
        attachments: attachmentsSend,
        faq,
        short_description: shortDescription,
        is_yearly: isYearly,
        meta_title: metaTitle,
        meta_description: metaDescription,
        platforms: activePlatforms.map((item) => item.value).join(","),
        ai_data: aiData?.map((item) => ({
          ...item,
          document: typeof item.document !== "string" ? item.document : undefined,
          data: JSON.stringify(item.data),
        })),
      },
    };
    sendSaveRequest({
      methodH: pId ? "PUT" : "POST",
      url: pId ? `/products/${pId}` : `/products/`,
      cache: false,
      bodyH: data,
    }).then(() => {
      setTimeout(() => navigate(`/gestione/prodottie`), 500);
    });
  };

  const onProcessAi = async () => {
    await sendSaveRequest({
      methodH: "PUT",
      url: `/products/${pId}`,
      cache: false,
      bodyH: {
        data: {
          name,
          price,
          type,
          description,
          num_iterations: type === "subscription" ? numIterations : 1,
          documents: documentsSend,
          fields: personalFieldsSend,
          steps:
            type === "paperwork" || type === "consulting" || type === "subscription" ? steps : [],
          fixed_month_day: type === "subscription" ? fixedMonthDay : null,
          home,
          consulting_allowed: consultingAllowed,
          visible,
          products: type === "package" ? productsSend : [],
          category: categorySend,
          subcategory: subCategorySend,
          images: imgCat,
          tags: tagsSend,
          attachments: attachmentsSend,
          faq,
          short_description: shortDescription,
          is_yearly: isYearly,
          meta_title: metaTitle,
          meta_description: metaDescription,
          platforms: activePlatforms.map((item) => item.value).join(","),
          ai_data: aiData.map((item) => ({
            ...item,
            document: typeof item.document !== "string" ? item.document : undefined,
            data: JSON.stringify(item.data),
          })),
        },
      },
    });
    await sendProductRequest({
      url: `/products/aiprocess`,
      methodH: "POST",
      cache: false,
    });
    await sendProductRequest({
      url: `/products/${pId}`,
      cache: false,
      manageData: ({ data }) => {
        manageProductGet(data);
      },
    });
    return true;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectProduct
                    tabHandler={typeHandler}
                    tabValue={type}
                    allPackageOption={allPackage.map((val) => ({
                      label: val.name,
                      value: val.id,
                    }))}
                    addPackage={addPackage}
                    products={products}
                    editPackageValue={editPackageValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProductInfo
                    metaTitle={metaTitle}
                    metaTitleHandler={handleMetaTitle}
                    metaDescription={metaDescription}
                    metaDescriptionHandler={handleMetaDescription}
                    name={name}
                    nameHandler={nameHandler}
                    description={description}
                    descriptionHandler={descriptionHandler}
                    tabHandler={typeHandler}
                    tabValue={type}
                    steps={steps}
                    addSteps={addSteps}
                    editStepsDescriptionValue={editStepsDescriptionValue}
                    editStepsDaysValue={editStepsDaysValue}
                    editMakeBusyValue={editMakeBusyValue}
                    editToSigneValue={editToSigneValue}
                    editToUploadValue={editToUploadValue}
                    deleteSteps={deleteSteps}
                    fixedMonthDay={fixedMonthDay}
                    numIterations={numIterations}
                    fixedMonthDayHandler={fixedMonthDayHandler}
                    setNumIterationsHendler={setNumIterationsHendler}
                    shortDescription={shortDescription}
                    shortDescriptionHandler={shortDescriptionHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProductImage
                    images={images}
                    updateFilesGallery={updateFilesGallery}
                    filesGallery={fileImageGallery}
                    imgGalleryHandlerRemove={imgGalleryHandlerRemove}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFaq
                    addFaqSteps={addFaqSteps}
                    faq={faq}
                    editFaqTitleValue={editFaqTitleValue}
                    editFaqTextValue={editFaqTextValue}
                    faqHandlerRemove={faqHandlerRemove}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <SelectPrersonalInformation
                    personalFieldsOption={allPersonalFields.map((val) => ({
                      label: val.name,
                      value: val.id,
                    }))}
                    personalFields={personalFields}
                    addPersonalFields={addPersonalFields}
                    editPersonalFieldsValue={editPersonalFieldsValue}
                    editRequiredPersonalFieldsValue={editRequiredPersonalFieldsValue}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <SelectPrersonaldocumentation
                    documentsOption={allDocuments.map((val) => ({
                      label: val.name,
                      value: val.id,
                      addvalue: val.validity,
                    }))}
                    documents={documents}
                    addDocument={addDocument}
                    editDocumentValue={editDocumentValue}
                    editRequiredDocumentValue={editRequiredDocumentValue}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <ProductDxSidebar
              home={home}
              homeHandler={homeHandler}
              visible={visible}
              visibleHandler={visibleHandler}
              tags={tags}
              isSaveLoading={isSaveLoading}
              consultingAllowed={consultingAllowed}
              consultingHandler={consultingHandler}
              price={price}
              priceHandler={priceHandler}
              saveHandler={saveHandler}
              categoryOption={allCategory.map((val) => ({
                label: val.name,
                value: val.id,
              }))}
              editCategory={editCategory}
              category={category}
              subCategoryOption={allSubCategory.map((val) => ({
                label: val.name,
                value: val.id,
              }))}
              editSubCategory={editSubCategory}
              subCategory={subCategory}
              updateFiles={updateFiles}
              files={files}
              images={images}
              imgHandlerRemove={imgHandlerRemove}
              allTag={allTag.map((val) => ({
                label: val.name,
                value: val.id,
              }))}
              editTag={editTag}
              removeProduct={removeProduct}
              updateAttachments={updateAttachments}
              attachments={attachments}
              attachmentsHandlerRemove={attachmentsHandlerRemove}
              isYearly={isYearly}
              isYarlyHandler={isYarlyHandler}
              activePlatforms={activePlatforms}
              setActivePlatforms={setActivePlatforms}
            />
          </Grid>
          {Number(pId) === 164 && (
            <Grid item xs={12}>
              <AiDataPreavvisoDimissioni
                items={aiData}
                setItems={setAiData}
                onProcessAi={onProcessAi}
              />
            </Grid>
          )}
          {Number(pId) === 168 && (
            <Grid item xs={12}>
              <AiDataScopriBonus items={aiData} setItems={setAiData} onProcessAi={onProcessAi} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProductNsp;
