// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function SelectPrersonalInformation({
  personalFields,
  addPersonalFields,
  personalFieldsOption,
  editPersonalFieldsValue,
  editRequiredPersonalFieldsValue,
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Dati anagrafici necessari</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={2} pt={2}>
            {personalFields.map((doc, index) => (
              <>
                <Grid item xs={8} lg={8}>
                  <Autocomplete
                    defaultValue=""
                    value={
                      doc.field.text ? { label: doc.field.text, value: doc.field.id } : doc.value
                    }
                    options={personalFieldsOption}
                    onChange={(event, data) => {
                      if (data) {
                        editPersonalFieldsValue("fileds", data.value, index);
                      } else {
                        editPersonalFieldsValue("fileds", "del", index);
                      }
                    }}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    control={<Switch checked={doc.required} />}
                    label="Richiesto"
                    onChange={() => {
                      editRequiredPersonalFieldsValue(index);
                    }}
                  />
                </Grid>
              </>
            ))}
            <Grid item xs={12} lg={12} pt={2}>
              <MDButton
                onClick={() => {
                  addPersonalFields({ field: "", required: false });
                }}
                variant="gradient"
                color="info"
              >
                + Elemento
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default SelectPrersonalInformation;
