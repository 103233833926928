import AddIcon from "@mui/icons-material/Add";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import Select from "components/Select";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import moment from "moment";
import { useState } from "react";

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
function AiDataPreavvisoDimissioni({ items, setItems, onProcessAi }) {
  const [activeContract, setActiveContract] = useState(0);
  const [loading, setLoading] = useState(false);
  const item = items?.length > 0 ? items[activeContract] : null;
  const onMultipleUpload = (e) => {
    [...e.target.files].forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (loadFile) => {
        setItems((prev) => [
          ...prev,
          {
            name: file.name,
            description: "",
            active: false,
            document: {
              name: file.name,
              data: loadFile.target.result.replace("data:application/pdf;base64,", ""),
            },
            data: { items: [] },
          },
        ]);
      };
    });
  };
  const onChangeSelect = (select) => setActiveContract(select?.value);
  const updateItem = (target, index) =>
    setItems((prev) =>
      prev.map((e, i) => (i === index ? { ...e, [target.name]: target.value } : e))
    );
  const updateDataItem = ({ target }, index, indexData) =>
    setItems((prev) =>
      prev.map((iItem, iIndex) =>
        iIndex === index
          ? {
              ...iItem,
              data: {
                items: [
                  ...iItem?.data?.items?.map((dItem, dIndex) =>
                    dIndex === indexData ? { ...dItem, [target.name]: target.value } : dItem
                  ),
                ],
              },
            }
          : iItem
      )
    );

  const removeDataItem = (index, indexData) =>
    setItems((prev) =>
      prev.map((iItem, iIndex) =>
        iIndex === index
          ? {
              ...iItem,
              data: {
                items: [...iItem?.data?.items?.filter((dItem, dIndex) => dIndex !== indexData)],
              },
            }
          : iItem
      )
    );

  const onProcessAiCallback = async () => {
    setLoading(true);
    try {
      await onProcessAi();
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  };

  return (
    <>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">AI Dati Contratti</MDTypography>
          <Grid container align="center">
            <Grid item xs={12} sm={9}>
              <Select
                label="Contratti inseriti"
                options={items?.map((e, value) => ({
                  label: `${e?.name} [${e?.active ? "Pubblico" : "Privato"}]`,
                  value,
                }))}
                value={activeContract}
                onChange={(e) => onChangeSelect(e)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDButton
                variant="gradient"
                color="info"
                style={{ marginTop: 25 }}
                onClick={() => {
                  setItems([
                    ...items,
                    {
                      name: "",
                      description: "",
                      active: false,
                      document: null,
                      data: { items: [] },
                    },
                  ]);
                }}
              >
                <AddIcon /> Aggiungi Contratto
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                style={{ marginTop: 25 }}
                onClick={() => {
                  if (
                    !loading &&
                    !confirm(
                      "Sei sicuro di voler lanciare l'elaborazione AI? Questo comporterà un elaborazione di tutti i documenti non ancora elaborati, richiamando api che avranno dei costi, prosegui solo se necessario"
                    )
                  )
                    return;
                  onProcessAiCallback();
                }}
              >
                {loading ? <CircularProgress /> : <>Avvia Elaborazione AI</>}
              </MDButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MDTypography component="label" variant="h6" fontWeight="bold">
              Carica più documenti in una volta (una volta selezionati li troverai nel menu a
              tendina qui sopra)
            </MDTypography>
            <div>
              <input type="file" multiple onChange={onMultipleUpload} />
            </div>
          </Grid>
        </MDBox>
      </Card>

      <Card style={{ marginTop: 25 }}>
        <MDBox p={3}>
          {item && (
            <div key={activeContract}>
              <MDTypography variant="h5">Modifica dati contratto selezionato</MDTypography>
              <MDBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      onChange={({ target }) => updateItem(target, activeContract)}
                      type="text"
                      label="Nome contratto"
                      defaultValue=""
                      name="name"
                      value={item.name}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <MDBox ml={0.5} lineHeight={0} display="inline-block">
                      <MDTypography component="label" variant="h6" fontWeight="bold">
                        Descrizione
                      </MDTypography>
                    </MDBox>
                    <br />
                    <MDEditor
                      value={item.description}
                      onChange={(value) =>
                        updateItem({ name: "description", value }, activeContract)
                      }
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <br />
              <Grid container spacing={2}>
                {item?.document && (
                  <>
                    <Grid item xs={12} lg={9}>
                      {item?.document?.name || "Documento caricato"}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      {typeof item?.document === "string" && (
                        <MDButton
                          variant="gradient"
                          color="info"
                          style={{ marginRight: 10 }}
                          onClick={() => window.open(item?.document, "_blank")}
                        >
                          Mostra
                        </MDButton>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Grid container gap={1} wrap>
                    <Chip
                      label="File caricato su AI"
                      color={item?.ai_file_id ? "success" : "warning"}
                    />
                    <Chip
                      label="File vettorizzato"
                      color={item?.ai_vector_store_id ? "success" : "warning"}
                    />
                    <Chip
                      label="Assistant configurato"
                      color={item?.ai_assistant_id ? "success" : "warning"}
                    />
                    <Chip
                      label="Thread creato"
                      color={item?.ai_thread_id ? "success" : "warning"}
                    />
                    <Chip
                      label="Richiesta avviata"
                      color={item?.ai_run_id ? "success" : "warning"}
                    />
                    <Chip
                      label="Richiesta processata"
                      color={item?.processed_at ? "success" : "warning"}
                    />
                    <Divider />
                    {item?.assistant_created_at && (
                      <Grid item xs={12}>
                        <p>
                          <strong>
                            Assistente creato il:{" "}
                            {moment(item?.assistant_created_at).format("DD/MM/YYYY HH:mm")}
                          </strong>
                        </p>
                        {!item?.run_at && (
                          <p>
                            Il thread verrà creato lanciando l&apos;Elaborazione AI 5 Minuti dopo,
                            alle{" "}
                            <Chip
                              label={moment(item?.assistant_created_at)
                                .add(5, "minutes")
                                .format("HH:mm")}
                            />
                          </p>
                        )}
                        <Divider />
                      </Grid>
                    )}
                    {item?.run_at && (
                      <Grid item xs={12}>
                        <p>
                          <strong>
                            Richiesta avviata il: {moment(item?.run_at).format("DD/MM/YYYY HH:mm")}
                          </strong>
                        </p>
                        {!item?.processed_at && (
                          <p>
                            Il processo riceverà la risposta lanciando l&apos;Elaborazione AI 5
                            Minuti dopo, alle{" "}
                            <Chip label={moment(item?.run_at).add(5, "minutes").format("HH:mm")} />
                          </p>
                        )}
                        <Divider />
                      </Grid>
                    )}
                    {item?.processed_at && (
                      <Grid item xs={12}>
                        <strong>
                          Richiesta processata il:{" "}
                          {moment(item?.processed_at).format("DD/MM/YYYY HH:mm")}
                        </strong>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <FormControlLabel
                    control={<Switch checked={item?.active} />}
                    label="Pubblico"
                    onChange={() =>
                      updateItem({ name: "active", value: !item?.active }, activeContract)
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{ marginRight: 15 }}
                    onClick={() =>
                      setItems((prev) =>
                        prev.map((e, i) =>
                          i === activeContract
                            ? {
                                ...e,
                                data: {
                                  items: [...e?.data?.items, { age: "", days: "", level: "" }],
                                },
                              }
                            : e
                        )
                      )
                    }
                  >
                    <AddIcon /> Aggiungi elemento
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{ marginRight: 15 }}
                    onClick={() => {
                      if (
                        !confirm(
                          "Sei sicuro di voler forzare nuovamente la richiesta all'AI? " +
                            "I tuoi elementi verranno sostituiti con quelli calcolati dall'AI, " +
                            "vuoi procedere?"
                        )
                      ) {
                        return;
                      }
                      updateItem({ name: "active", value: false }, activeContract);
                      updateItem({ name: "ai_thread_id", value: null }, activeContract);
                      updateItem({ name: "ai_run_id", value: null }, activeContract);
                      updateItem({ name: "run_at", value: null }, activeContract);
                      updateItem({ name: "processed_at", value: null }, activeContract);
                      updateItem({ name: "data", value: { items: [] } }, activeContract);
                    }}
                  >
                    <CancelPresentationIcon /> Forza richiesta AI
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      if (!confirm("Sei sicuro di voler eliminare questo contratto?")) return;
                      setItems((prev) => prev.filter((e, i) => i !== activeContract));
                      setActiveContract(0);
                    }}
                  >
                    <DeleteIcon /> Rimuovi contratto selezionato
                  </MDButton>
                </Grid>

                {item?.data?.length > 0 && (
                  <MDTypography component="label" variant="h6" fontWeight="bold">
                    Elementi estratti
                  </MDTypography>
                )}
                {item?.data?.items?.map((itemData, indexData) => (
                  <Grid item xs={12} key={indexData}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={12} md={3}>
                        <FormField
                          onChange={(e) => updateDataItem(e, activeContract, indexData)}
                          type="text"
                          label="Esperienza lavorativa"
                          defaultValue=""
                          name="age"
                          value={itemData?.age}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormField
                          onChange={(e) => updateDataItem(e, activeContract, indexData)}
                          type="text"
                          label="Livello contratto"
                          defaultValue=""
                          name="level"
                          value={itemData?.level}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormField
                          onChange={(e) => updateDataItem(e, activeContract, indexData)}
                          type="text"
                          label="Giorni di preavviso"
                          defaultValue=""
                          name="days"
                          value={itemData?.days}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => removeDataItem(activeContract, indexData)}
                        >
                          <DeleteIcon />
                        </MDButton>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox ml={0.5} lineHeight={0} display="inline-block">
                          <MDTypography component="label" variant="h6" fontWeight="bold">
                            Note da riportare nel documento
                          </MDTypography>
                        </MDBox>
                        <br />
                        <MDEditor
                          value={itemData?.note || ""}
                          onChange={(value) =>
                            updateDataItem(
                              { target: { name: "note", value } },
                              activeContract,
                              indexData
                            )
                          }
                        />
                        <hr />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </MDBox>
      </Card>
    </>
  );
}

export default AiDataPreavvisoDimissioni;
