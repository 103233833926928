// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewProduct page components
import FormField from "pagensp/CustomComponents/FormField";

function UserOperatorsProducts({
  products,
  allProductOption,
  editProductsValue,
  editProductsMaxValue,
  addProducts,
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Prodotti Gestiti</MDTypography>
        <Grid container spacing={2} pt={2}>
          {products.map((prod, index) => (
            <>
              <Grid item xs={9} lg={8}>
                <Autocomplete
                  defaultValue=""
                  value={
                    prod.product.text
                      ? { label: prod.product.text, value: prod.product.id }
                      : prod.value
                  }
                  options={allProductOption}
                  onChange={(event, data) => {
                    if (data) {
                      editProductsValue(data.value, index);
                    } else {
                      editProductsValue("del", index);
                    }
                  }}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                  style={{ marginTop: "16px" }}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <FormField
                  onChange={(e) => {
                    editProductsMaxValue(e.target.value, index);
                  }}
                  type="number"
                  label="N. max"
                  defaultValue=""
                  value={prod.max}
                />
              </Grid>
            </>
          ))}
          <br />
          <Grid item xs={9} lg={8} pt={2}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                addProducts({ product: "", max: "" });
              }}
            >
              + Prodotto
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default UserOperatorsProducts;
