// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useContext } from "react";
import AuthContext from "pagensp/store/auth-context";

function ProductInfo({
  tabValue,
  name,
  nameHandler,
  description,
  descriptionHandler,
  steps,
  addSteps,
  editStepsDescriptionValue,
  editStepsDaysValue,
  editMakeBusyValue,
  editToSigneValue,
  editToUploadValue,
  deleteSteps,
  fixedMonthDay,
  numIterations,
  fixedMonthDayHandler,
  setNumIterationsHendler,
  shortDescription,
  shortDescriptionHandler,
  metaTitleHandler,
  metaTitle,
  metaDescriptionHandler,
  metaDescription,
}) {
  const { typeUser } = useContext(AuthContext);
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informazioni generali - {name}</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                onChange={nameHandler}
                type="text"
                label="Nome Prodotto"
                defaultValue=""
                value={name}
              />
            </Grid>
          </Grid>
        </MDBox>
        {typeUser === "admin" && (
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Titolo meta"
                  onChange={metaTitleHandler}
                  value={metaTitle}
                  multiline
                  rows={2}
                  size="large"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Descrizione meta"
                  onChange={metaDescriptionHandler}
                  value={metaDescription}
                  multiline
                  rows={2}
                  size="large"
                />
              </Grid>
            </Grid>
          </MDBox>
        )}

        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                label="Descrizione breve"
                onChange={shortDescriptionHandler}
                value={shortDescription}
                multiline
                rows={2}
                size="large"
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Descrivi il prodotto
                </MDTypography>
              </MDBox>
              <br />
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Questo è importante perche un prodotto possa aver successo!
                </MDTypography>
              </MDBox>
              <MDEditor
                value={description}
                onChange={(e) => {
                  descriptionHandler(e);
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField type="text" label="Link video Youtube" defaultValue="" />
            </Grid>
          </Grid>
        </MDBox>
        {tabValue === "paperwork" || tabValue === "consulting" || tabValue === "subscription" ? (
          <>
            {steps.map((doc, index) => (
              <>
                <MDBox mt={4} style={{ marginLeft: "20px" }}>
                  <Grid container spacing={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <FormField
                          value={doc.description}
                          type="text"
                          label="Nome Step"
                          onChange={(e) => {
                            editStepsDescriptionValue(e.target.value, index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <FormField
                          value={doc.days}
                          type="number"
                          label="N. giorni"
                          onChange={(e) => {
                            editStepsDaysValue(e.target.value, index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sm={2}>
                        <FormControlLabel
                          control={<Switch checked={doc.makes_busy} />}
                          label="Occupato"
                          onChange={() => {
                            editMakeBusyValue(index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sm={2}>
                        <FormControlLabel
                          control={<Switch checked={doc.to_sign} />}
                          label="Firmare"
                          onChange={() => {
                            editToSigneValue(index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sm={2}>
                        <FormControlLabel
                          control={<Switch checked={doc.requires_file} />}
                          label="Caricare"
                          onChange={() => {
                            editToUploadValue(index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={1}>
                        <MDButton
                          variant="gradient"
                          color="error"
                          size="small"
                          onClick={() => {
                            deleteSteps(index);
                          }}
                        >
                          <DeleteIcon />
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </>
            ))}

            <Grid item xs={12} sm={3}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  addSteps({ days: "", description: "", makes_busy: true });
                }}
              >
                + Aggiungi Step
              </MDButton>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {tabValue === "subscription" ? (
          <>
            <MDBox mt={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <FormField
                    value={numIterations}
                    type="number"
                    label="N. mesi abbonamento"
                    onChange={(e) => {
                      setNumIterationsHendler(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    value={fixedMonthDay}
                    type="number"
                    label="Giorno del mese ricorrente"
                    onChange={(e) => {
                      fixedMonthDayHandler(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </>
        ) : (
          <></>
        )}
      </MDBox>
    </Card>
  );
}

export default ProductInfo;
