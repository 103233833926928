/* eslint-disable */
const scripts = [
  {
    name: "pdfjsLib",
    src: "/react-pdf-editor/pdf.min.js",
  },
  {
    name: "PDFLib",
    src: "/react-pdf-editor/pdf-lib.min.js",
  },
  {
    name: "download",
    src: "/react-pdf-editor/download.js",
  },
  {
    name: "makeTextPDF",
    src: "/react-pdf-editor/makeTextPDF.js",
  },
  { name: "w3Color", src: "/react-pdf-editor/w3color.js" },
  { name: "Worker", src: "/react-pdf-editor/pdf.worker.min.js" },
];

const assets = {};
export const getAsset = (scriptName) => assets[scriptName];

export const prepareAssets = () => {
  // prepare scripts
  scripts.forEach(({ name, src }) => {
    assets[name] = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(window[name]);
        console.log(`${name} is loaded.`);
      };
      script.onerror = () => reject(`The script ${name} didn't load correctly.`);
      document.body.appendChild(script);
    });
  });
};

const fonts = {
  Courier: {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 6;
    },
  },
  Helvetica: {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 10;
    },
  },
  "Times-Roman": {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  },
};

// Available fonts
export const Fonts = {
  ...fonts,
};

export const fetchFont = (name) => {
  if (fonts[name]) return fonts[name];

  const font = Fonts[name];
  if (!font) throw new Error(`Font '${name}' not exists.`);

  fonts[name] = fetch(font.src)
    .then((r) => r.arrayBuffer())
    .then((fontBuffer) => {
      const fontFace = new window.FontFace(name, fontBuffer);
      fontFace.display = "swap";
      fontFace.load().then(() => document.fonts.add(fontFace));
      return {
        ...font,
        buffer: fontBuffer,
      };
    });
};
