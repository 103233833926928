import HeaderLiveChat from "./HeaderLiveChat";
import BodyLiveChat from "./BodyLiveChat";
import Contacts from "../component/Contacts";

// Se e solo se l'utente è entrato nella chat con un operatore,
// visualizzerà la tabella dei contatti
const ChatActived = "chat";

export default function CardChat({
  open,
  room,
  sendMessage,
  selectedRoom,
  handleSelectedRoom,
  messages,
  products,
  roomCustomer,
  handleChatOpening,
  userData,
  sendReadMessage,
}) {
  return (
    <>
      <div className={`${!open && "d-none"}`}>
        <div className="chatIntera">
          <div className="card-liveChat startConversation">
            {/* </div>aggiungere se è inizio conversazione e non è operatore aggiungi classse startConversation <div className={`"card-liveChat" ${ChatActived === "chat" && "startConversation"}`}> */}
            <header>
              <HeaderLiveChat
                products={products}
                selectedRoom={selectedRoom}
                handleSelectedRoom={handleSelectedRoom}
                handleChatOpening={handleChatOpening}
                userData={userData}
              />
            </header>
            <section>
              <BodyLiveChat
                type={ChatActived}
                products={products}
                {...{
                  open,
                  room,
                  selectedRoom,
                  handleSelectedRoom,
                  sendMessage,
                  messages,
                  roomCustomer,
                  sendReadMessage,
                }}
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
