import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  ${({ bg }) => (bg ? `background: #ebebff;` : ``)}
  padding: 10px;
  border-radius: 20px;
  margin: 20px 0;
`;

export const Title = styled.h3`
  font-size: 32px !important;
  font-weight: 900;
  color: var(--bs-primary);
`;

export const Description = styled.h4`
  font-size: 20px;
  font-weight: 400;
  color: black;
  text-align: center;
`;

export const CardsContainer = styled.section`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const Card = styled.article`
  max-width: 800px;
  width: 100%;
  padding: 10px 10px;
  border-radius: 40px;
  border: 4px solid var(--bs-primary);
  font-size: 16px;
  font-weight: 900;
  color: var(--bs-primary);
  transition: 0.5s all;

  ${({ selected }) =>
    !selected &&
    `
      &:hover {
        color: white !important;
        background-color: #0524dd !important;
      }
  `}
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Answer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-top: 10px;
`;

export default {};
