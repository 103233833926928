/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */

import MDTypography from "components/MDTypography";

// ProductsList page components
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default {
  columns: [
    {
      Header: "Sotto Categoria",
      accessor: "nome",
      Cell: ({ value }) => <MDTypography variant="button">{value}</MDTypography>,
    },
    {
      Header: "Categoria",
      accessor: "category",
      Cell: ({ value }) => <MDTypography variant="button">{value}</MDTypography>,
    },
    {
      Header: "Azione",
      width: "1%",
      disableSortBy: true,
      manualSortBy: false,
      accessor: "delete",
      Cell: ({ value }) => (
        <>
          <MDButton
            color="error"
            onClick={() => {
              value.action();
            }}
          >
            <DeleteIcon />
          </MDButton>{" "}
        </>
      ),
    },
    {
      Header: "",
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ value }) => (
        <>
          <MDButton
            color="info"
            onClick={() => {
              value.action();
            }}
          >
            <EditIcon />
          </MDButton>
        </>
      ),
    },
  ],
};
