import Avatar from "@mui/material/Avatar";
import IMGConsulenteEsempio from "assets/images/team-4.jpg";
import GhostButton from "pagensp/Common/GhostButton";
import CloseIcon from "@mui/icons-material/Close";

// 0 Client - 1 Customer
const TypeUser = 0;

export default function HeaderLiveChat({
  products,
  selectedRoom,
  handleSelectedRoom,
  handleChatOpening,
  userData,
}) {
  return (
    <div className="headerLiveChat">
      {!selectedRoom ? (
        <Client products={products} userData={userData} handleChatOpening={handleChatOpening} />
      ) : (
        <CustomerCare
          handleSelectedRoom={handleSelectedRoom}
          handleChatOpening={handleChatOpening}
          products={products}
          selectedRoom={selectedRoom}
        />
      )}
    </div>
  );
}

function CustomerCare({ handleSelectedRoom, handleChatOpening, selectedRoom }) {
  // caricare informazioni customer
  const square = 50;

  return (
    <section className="customerCare-header w-100">
      <div className="nomeHeader d-flex align-items-center">
        <GhostButton
          onClick={() => {
            handleSelectedRoom(null);
          }}
        >
          <i className="fa text-white fa-angle-left me-2 fs-3" />
        </GhostButton>
        <div className="px-2 flex-grow-1">
          <div
            className="font-weight-bold text-truncate text-uppercase"
            style={{ fontSize: "80%" }}
          >
            {selectedRoom}
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          style={{ width: "30px", height: "30px", border: "1px" }}
          className="btn-liveChat position-static d-block position-absolute end-2 top-2"
          onClick={() => handleChatOpening()}
        >
          <div className="position-relative">
            <CloseIcon fontSize="medium" />
          </div>
        </button>
      </div>
    </section>
  );
}

function Client({ userData, handleChatOpening }) {
  //  caricare informazioni client
  return (
    <section className="client-header">
      <div className="nomeHeader d-flex align-items-between justify-content-between">
        <div style={{ maxWidth: "calc(100% - 35px)", flexGrow: "2" }} className="saluto ">
          <h3 className="fs-4 fw-normal mt-2 mb-0 text-white text-start text-truncate">
            Ciao {userData.name} {userData.surname}👋
          </h3>
          <p className="lead text-white fs-7">Siamo qui per aiutarti</p>
        </div>
        <button
          type="button"
          className="btn-liveChat position-static d-block position-absolute end-2 top-2"
          style={{ width: "30px", height: "30px", border: "1px" }}
          onClick={() => handleChatOpening()}
        >
          <div className="position-relative">
            <CloseIcon fontSize="medium" />
          </div>
        </button>
      </div>
    </section>
  );
}
