import { imagePlugin } from "@react-page/plugins-image";

function uploadImage(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const mime = e.target.result.match(/^data:(.*);/)[1];
      const img = e.target.result.replace(`data:${mime};base64,`, "");
      resolve({
        url: e.target.result,
      });
    };
  });
}

export default {
  ...imagePlugin({
    imageUpload: uploadImage,
  }),
  title: "Immagine",
  description: "Inserisci un immagine",
};
