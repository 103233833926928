import styled from "styled-components";

const MOBILE_BREAKPOINT = 800;

export const CardsContainer = styled.section`
  padding: 10px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-wrap: wrap;
  }
`;

export const Card = styled.article`
  width: 100%;
  padding: 10px;

  > h3 {
    font-size: 20px;
    color: #1a1a1a;
    margin-bottom: 5px;
    text-align: center;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      text-align: center;
    }
  }

  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #1a1a1a;
    text-align: center;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      text-align: center;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : `auto`)};
  object-fit: ${({ size }) => size || `cover`};
`;

export default {};
