import MDTypography from "components/MDTypography";

// ProductsList page components
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import { Chip } from "@mui/material";
import MDButton from "components/MDButton";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import moment from "moment";

export default {
  columns: [
    {
      Header: "Pratica",
      accessor: "pratica",
      sortable: false,
      Cell: ({ value }) => (
        <div>
          <MDTypography variant="p" fontWeight="bold">
            N. Pratica:{" "}
          </MDTypography>
          <MDTypography variant="p">{value.idPratica}</MDTypography>
          <br />
          <MDTypography variant="p" fontWeight="bold">
            N. Ordine:{" "}
          </MDTypography>
          <MDTypography variant="p">{value.idOrdine}</MDTypography>
        </div>
      ),
    },
    {
      Header: "",
      accessor: "actions",
      disableSortBy: true,
      width: 65,
      Cell: ({ value }) => (
        <>
          {value?.edit && (
            <MDButton
              style={{ margin: 5 }}
              color="primary"
              onClick={() => {
                value.edit.action();
              }}
            >
              <EditIcon />
            </MDButton>
          )}

          {value?.editblank && (
            <a
              rel="noreferrer"
              href={value.editblank.link}
              target={value.editblank.blank ? "_blank" : ""}
            >
              <MDButton style={{ margin: 5 }} color="primary">
                <LaunchIcon />
              </MDButton>
            </a>
          )}

          {value?.chat?.operator && (
            <MDButton
              style={{ margin: 5 }}
              color="primary"
              onClick={() => {
                value.chat.action();
              }}
            >
              <ChatIcon sx={{ marginRight: 1 }} />
              {value?.chat?.number}
            </MDButton>
          )}
        </>
      ),
    },
    {
      Header: "Prodotto / Cliente / Operatore",
      accessor: "clientProduct",
      sortable: false,
      Cell: ({ value }) => (
        <div style={{ width: 300 }}>
          <MDTypography variant="p">
            <strong>{value.product}</strong>
          </MDTypography>
          <br />
          <MDTypography variant="p">
            <strong>Cliente</strong>: {value.client}
          </MDTypography>
          <br />
          <MDTypography variant="p">
            <strong>Metodo pagamento</strong>: {value.payment_method}
          </MDTypography>
          <br />
          <MDTypography variant="p">
            <strong>Operatore</strong>: {value.operator}
          </MDTypography>
        </div>
      ),
    },
    {
      Header: "Stato pratica",
      accessor: "stato",
      sortable: false,
      Cell: ({ value }) => (
        <div style={{ width: 160 }}>
          <MDTypography variant="p" fontWeight="bold">
            Stato:&nbsp;
          </MDTypography>
          <MDTypography variant="p">
            <Chip
              label={value.statoPratica}
              size="small"
              color={
                {
                  SOSPESO: value.pagato ? "primary" : "default",
                  RIMBORSATA: "error",
                  COMPLETA: "success",
                  "IN CORSO": "warning",
                }[value.statoPratica]
              }
            />
          </MDTypography>
          <br />
          {value?.stepCorrente && (
            <>
              <MDTypography variant="p" fontWeight="bold">
                Step:&nbsp;
              </MDTypography>
              <MDTypography variant="p">{value.stepCorrente.description}</MDTypography>
              <br />
            </>
          )}
          <MDTypography variant="p" fontWeight="bold">
            Completamento:&nbsp;
          </MDTypography>
          <MDTypography variant="p">
            {value.stepCompletati} / {value.stepTotali}
          </MDTypography>
          <br />
          {value?.stepTotali && (
            <>
              <MDTypography variant="p" fontWeight="bold">
                Percentuale:&nbsp;
              </MDTypography>
              <MDTypography variant="p">
                {((value.stepCompletati / value.stepTotali) * 100).toFixed(0)}%
              </MDTypography>
              <br />
            </>
          )}
          <div>
            {{
              FIRST_REMINDER_SENT: "1° Email Reminder",
              SECOND_REMINDER_SENT: "2° Email Reminder",
            }[value.emailStatus] || ""}
          </div>
        </div>
      ),
    },
    {
      Header: "Date",
      accessor: "date",
      sortable: false,
      Cell: ({ value }) => (
        <div style={{ width: 250 }}>
          <MDTypography variant="p" fontWeight="bold">
            Acquisto pratica:{" "}
          </MDTypography>
          <MDTypography variant="p">
            {moment(value.acquisto).format("DD/MM/YYYY HH:mm")}
          </MDTypography>
          <br />
          <MDTypography variant="p" fontWeight="bold">
            Firma Mandato:{" "}
          </MDTypography>
          <MDTypography variant="p">
            {value.mandato ? moment(value.mandato).format("DD/MM/YYYY HH:mm") : ""}
          </MDTypography>
          <br />
          <MDTypography variant="p" fontWeight="bold">
            Consegna stimata:{" "}
          </MDTypography>
          <MDTypography variant="p">
            {value.consegna.type !== "subscription" ? (
              <>
                {!value.consegna.expected
                  ? ""
                  : moment(value.consegna.expected).format("DD/MM/YYYY")}
              </>
            ) : (
              <>
                {!value.consegna.subscriptiondate
                  ? ""
                  : `${moment(value.consegna.subscriptiondate).format("DD/MM/YYYY")} (${
                      value.consegna.subscriptionnum
                    } di ${value.consegna.subscriptiontot})`}
              </>
            )}
          </MDTypography>
          {value.statoPratica === "COMPLETA" && (
            <>
              <br />
              <MDTypography variant="p" fontWeight="bold">
                Consegna pratica:{" "}
              </MDTypography>
              {value.done && (
                <MDTypography variant="p">
                  {moment(value.done).format("DD/MM/YYYY HH:mm")}
                </MDTypography>
              )}
            </>
          )}
          {value.statoPratica === "IN CORSO" && (
            <div>
              <MDTypography variant="p" fontWeight="bold">
                Aggiornamento:{" "}
              </MDTypography>
              <MDTypography variant="p">
                {value.updatedAt ? `${moment(value.updatedAt).fromNow(true)} fa` : ""}
              </MDTypography>
            </div>
          )}
          {value.deletedAt && (
            <div>
              <MDTypography variant="p" fontWeight="bold">
                Eliminato:{" "}
              </MDTypography>
              <MDTypography variant="p">
                {value.deletedAt ? `${moment(value.deletedAt).fromNow(true)} fa` : ""}
              </MDTypography>
            </div>
          )}
        </div>
      ),
    },
    {
      Header: "Pagato",
      accessor: "paid",
      width: 70,
      Cell: ({ value }) => {
        if (value) {
          return <StatusCell icon="done" color="success" status="Pagato" />;
        }
        return <StatusCell icon="close" color="error" status="Non Pagato" />;
      },
    },
    {
      Header: "Mandato",
      accessor: "signedfile",
      width: 80,
      Cell: ({ value }) =>
        value ? (
          <MDTypography variant="button">
            <StatusCell
              onClick={() => {
                window.open(value, "_blank");
              }}
              icon="done"
              color="success"
              status="Firmato"
            />
          </MDTypography>
        ) : (
          <StatusCell icon="close" color="error" status="Non firmato" />
        ),
    },
  ],
};
