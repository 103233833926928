import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import FormField from "pagensp/CustomComponents/FormField";
import Select from "components/Select";
import MDTypography from "components/MDTypography";
import UploadSingle from "components/UploadSingle";
import { CardsContainer, Card, Container, Title, Description, Video, Image } from "./style";

/* eslint-disable no-restricted-globals */
function Component({ title, description, cards, bg }) {
  return (
    <Container bg={bg}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <CardsContainer>
        {cards?.map((card) => (
          <Card key={card.id}>
            {card.mode === "IMAGE" && card.image && (
              <Image
                src={card.image}
                alt={card.title}
                height={card.imageHeight}
                size={card.imageSize}
              />
            )}
            {card.mode === "VIDEO" && card.video && (
              <Video src={card.video.replace("watch?v=", "embed/")} />
            )}
          </Card>
        ))}
      </CardsContainer>
    </Container>
  );
}

const Form = ({ onChange, data }) => {
  const onAddCard = () => {
    const newCards = data.cards ? [...data.cards] : [];
    newCards.push({
      id: Math.floor(Math.random() * 99999),
      mode: "IMAGE",
      image: null,
      imageSize: "cover",
      imageHeight: 300,
      video: null,
      videoHeight: 300,
    });
    onChange({ ...data, cards: newCards, activeTab: 0 });
  };

  const onEditCard = (index, key, value) => {
    if (key === "percentage" && isNaN(Number(value))) return;
    const newCards = [...data.cards];
    newCards[index][key] = value;
    onChange({ ...data, cards: [...newCards] });
  };

  const onDeleteCard = (index) =>
    onChange({
      ...data,
      cards: [...data.cards.filter((item, i) => i !== index)],
      activeTab: 0,
    });

  const card = data?.cards?.length > 0 ? data.cards[data.activeTab || 0] : null;

  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <FormField
          type="text"
          label="Titolo"
          value={data.title}
          onChange={({ target }) => onChange({ ...data, title: target.value })}
        />
        <FormField
          type="text"
          label="Descrizione"
          value={data.description}
          onChange={({ target }) => onChange({ ...data, description: target.value })}
        />
        <Select
          label="Sfondo azzurro"
          options={[
            { label: "Si", value: true },
            { label: "No", value: false },
          ]}
          value={data.bg}
          onChange={({ value }) => onChange({ ...data, bg: value })}
          style={{
            zIndex: 9999,
          }}
        />
      </Grid>

      <Grid item xs={12} mb={1}>
        <MDButton variant="gradient" color="info" onClick={onAddCard}>
          Aggiungi Card
        </MDButton>
      </Grid>

      <Grid item xs={12} mb={1}>
        {data?.cards?.map((item, index) => (
          <MDButton
            variant="gradient"
            color={data.activeTab === index ? "primary" : "secondary"}
            onClick={() => onChange({ ...data, activeTab: index })}
          >
            Card {index + 1}
          </MDButton>
        ))}
      </Grid>

      {card && (
        <Grid item xs={12} key={card.id}>
          <MDTypography>Scegli se inserire un immagine o un video</MDTypography>
          <Grid container>
            <Grid item xs={12}>
              <MDButton
                variant="gradient"
                color={card.mode === "IMAGE" ? "primary" : "secondary"}
                onClick={() => onEditCard(data.activeTab, "mode", "IMAGE")}
              >
                Immagine
              </MDButton>
              <MDButton
                variant="gradient"
                color={card.mode === "VIDEO" ? "primary" : "secondary"}
                onClick={() => onEditCard(data.activeTab, "mode", "VIDEO")}
              >
                Video
              </MDButton>
            </Grid>
          </Grid>

          {card.mode === "VIDEO" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormField
                  type="text"
                  label="Link Youtube Video"
                  value={card.video}
                  onChange={({ target }) => onEditCard(data.activeTab, "video", target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  type="number"
                  label="Altezza iframe video"
                  value={card.videoHeight}
                  onChange={({ target }) => onEditCard(data.activeTab, "videoHeight", target.value)}
                />
              </Grid>
            </Grid>
          )}

          {card.mode === "IMAGE" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormField
                  type="number"
                  label="Altezza immagine"
                  value={card.imageHeight}
                  onChange={({ target }) => onEditCard(data.activeTab, "imageHeight", target.value)}
                />

                <MDTypography>Adattamento immagine</MDTypography>
                <MDButton
                  variant="gradient"
                  color={card.imageSize === "cover" ? "primary" : "secondary"}
                  onClick={() => onEditCard(data.activeTab, "imageSize", "cover")}
                >
                  Riempi
                </MDButton>
                <MDButton
                  variant="gradient"
                  color={card.imageSize === "contain" ? "primary" : "secondary"}
                  onClick={() => onEditCard(data.activeTab, "imageSize", "contain")}
                >
                  Adatta
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <UploadSingle
                  item={card.image}
                  onUpdate={({ url }) => onEditCard(data.activeTab, "image", url)}
                  accept=".jpg,.jpeg,image/jpg,image/jpeg"
                />
              </Grid>
            </Grid>
          )}
          <MDButton variant="gradient" color="error" onClick={() => onDeleteCard(data.activeTab)}>
            Rimuovi
          </MDButton>
        </Grid>
      )}
    </Grid>
  );
};

export default {
  Renderer: ({ data }) => <Component {...data} />,
  id: "cards-landing",
  title: "Cards Landing",
  description: "Crea card con video o immagini",
  version: 1,
  controls: {
    type: "custom",
    Component: Form,
  },
};
