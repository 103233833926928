/* eslint-disable react/no-danger */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GhostButton from "components/GhostButton/GhostButton";
import { Snackbar } from "@mui/material";
import Alert, { AlertContainer } from "components/ComponentsNSP/AlertDiv";

export default function Notification({ notification, isNotificationLoading, readNotification }) {
  const [snackMessage, setSnackMessage] = useState("");
  const navigate = useNavigate();
  const handleNotificationClick = ({ idNotifica, idPratica, praticaCompleta }) => {
    readNotification(idNotifica);
    navigate(`/dettagliopratica/${idPratica}`);
  };
  return (
    <section className="">
      <Snackbar
        open={snackMessage}
        autoHideDuration={3000}
        onClose={() => {
          setSnackMessage("");
        }}
      >
        <div>{snackMessage}</div>
      </Snackbar>
      <div className="row">
        <div className="col-12">
          <div className="my-2 testo-dashbard">
            <div className="fs-2 text-black text-center">Le tue notifiche</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 border-bottom border-lightgray fs-5 mb-2 text-black fw-bold">
          Nuove
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AlertContainer type="fill" closeOff>
            {notification.filter(({ read }) => !read).length === 0
              ? "Non ci sono nuove notifiche"
              : ""}
            {isNotificationLoading
              ? "Aggiornamento..."
              : notification
                  .filter(({ read }) => !read)
                  .map(({ text, id, item, type, meta }) => (
                    <GhostButton
                      onClick={() => {
                        if (!text.includes("rimborso")) {
                          handleNotificationClick({
                            idNotifica: id,
                            idPratica: item,
                            praticaCompleta: text.includes("pagamento della tua pratica")
                              ? "sospesa"
                              : meta.done,
                          });
                        }
                        if (text.includes("rimborso")) {
                          readNotification(id);
                          setSnackMessage("Rimborso Approvato");
                        }
                      }}
                      classNamemia="w-100"
                    >
                      <Alert
                        info={meta && meta?.severity === "alert"}
                        pending={type === 1}
                        check={meta && meta?.severity === "info"}
                        notification={type === 3}
                      >
                        <div dangerouslySetInnerHTML={{ __html: text }} key={id} />
                      </Alert>
                    </GhostButton>
                  ))}
          </AlertContainer>
        </div>
      </div>
      <div className="separatore p-2 w-100 d-block"> </div>
      <div className="row">
        <div className="col-12 border-bottom border-lightgray fs-5 mb-2 text-black fw-bold">
          Gi&agrave; lette
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AlertContainer type="fill" closeOff>
            {notification.filter(({ read }) => read).length === 0 ? "Non ci sono notifiche" : ""}
            {isNotificationLoading
              ? "Aggiornamento..."
              : notification
                  .filter(({ read }) => read)
                  .map(({ text, id, item, type, meta }) => (
                    <GhostButton
                      onClick={() => {
                        if (!text.includes("rimborso")) {
                          handleNotificationClick({
                            idNotifica: id,
                            idPratica: item,
                            praticaCompleta: text.includes("pagamento della tua pratica")
                              ? "sospesa"
                              : meta.done,
                          });
                        }
                        if (text.includes("rimborso")) {
                          readNotification(id);
                          setSnackMessage("Rimborso Approvato");
                        }
                      }}
                      classNamemia="w-100"
                    >
                      <Alert
                        info={meta && meta?.severity === "alert"}
                        pending={type === 1}
                        check={meta && meta?.severity === "info"}
                        notification={type === 3}
                        readed
                      >
                        <div dangerouslySetInnerHTML={{ __html: text }} key={id} />
                      </Alert>
                    </GhostButton>
                  ))}
          </AlertContainer>
        </div>
      </div>
    </section>
  );
}
