import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useState } from "react";

/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */

const WizardSimulationBonus = ({ open, onClose, wizardData }) => {
  const [answers, setAnswers] = useState({});
  const handleAnswer = (questionUuid, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionUuid]: answer,
    }));
  };

  const checkRequirements = (requirements) =>
    Object.entries(requirements).every(
      ([uuid, requiredAnswer]) => answers[uuid] === requiredAnswer
    );

  const filteredQuestions = wizardData?.wizard?.filter((question) =>
    checkRequirements(question.requirements)
  );

  const eligibleBonuses = Object.values(wizardData?.bonuses || {}).filter((bonus) =>
    bonus.requirements.every((requirement) => answers[requirement.uuid] === requirement.answer)
  );

  if (wizardData?.status === "error") {
    return (
      <Dialog fullWidth open={open} onClose={onClose} maxWidth="lg" scroll="body">
        <Alert severity="error" title="ERRORE DA RISOLVERE">
          {wizardData?.message}
          {wizardData?.content?.map((e, i) => (
            <div key={i}>
              <MDTypography component="label" variant="h6">
                {e.question}
              </MDTypography>
            </div>
          ))}
        </Alert>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="lg" scroll="body">
      <DialogTitle>Elenco domande wizard</DialogTitle>
      <DialogContent>
        <DialogContentText tabIndex={-1}>
          {filteredQuestions?.map((question, index) => (
            <div key={question.uuid} style={{ marginBottom: 20 }}>
              <h5>
                {index + 1}. {question.question}
              </h5>
              <div>
                {question.answers.map((answer) => (
                  <Button
                    key={answer}
                    variant={answers[question.uuid] === answer ? "contained" : "text"}
                    color="primary"
                    onClick={() => handleAnswer(question.uuid, answer)}
                    style={{ marginRight: 10 }}
                  >
                    {answer}
                  </Button>
                ))}
              </div>
            </div>
          ))}

          <Divider style={{ margin: "20px 0" }} />

          <DialogTitle>Elenco Bonus</DialogTitle>
          {eligibleBonuses?.map((bonus) => (
            <div key={bonus.name} style={{ marginBottom: 20 }}>
              <h5>{bonus.name || "BONUS SENZA NOME"}</h5>
              <p dangerouslySetInnerHTML={{ __html: bonus.description || "Nessuna descrizione" }} />
              {bonus.requirements.map((requirement) => (
                <Chip
                  key={requirement.uuid}
                  color={requirement.answer === "Sì" ? "success" : "error"}
                  variant="outlined"
                  label={
                    wizardData.wizard.find((q) => q.uuid === requirement.uuid)?.question ||
                    "DOMANDA NON TROVATA"
                  }
                  style={{ margin: 3 }}
                />
              ))}
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default WizardSimulationBonus;
