import { Dropzone } from "@dropzone-ui/react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";

function UploadSingle({ item, onUpdate, accept }) {
  const updateFiles = (incommingFiles) => {
    const filesx = incommingFiles[0].file;
    const reader = new FileReader();
    reader.readAsDataURL(filesx);
    reader.onload = (e) => {
      const mime = e.target.result.match(/^data:(.*);/)[1];
      const img = e.target.result.replace(`data:${mime};base64,`, "");
      onUpdate({
        name: filesx.name,
        data: img,
        url: e.target.result,
      });
    };
  };

  const getImageUrl = () => (typeof item === "string" ? item : item.url);

  return (
    <MDBox>
      {!item ? (
        <>
          <MDTypography variant="button">Utilizza una immagine non piu grande di 2Mb</MDTypography>
          <Dropzone
            onChange={updateFiles}
            value={item ? [item] : []}
            maxFiles={1}
            maxFileSize={2998000}
            accept={accept}
          />
        </>
      ) : (
        <>
          <GestUploadImg UploadImage={getImageUrl()} imgHandlerRemove={() => onUpdate(null)} />
        </>
      )}
    </MDBox>
  );
}

UploadSingle.defaultProps = {
  item: null,
  onUpdate: () => {},
  accept: ".jpg,.jpeg,.png,image/*",
};

export default UploadSingle;
