import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
// import Avatar from "@mui/material/Avatar";
// import IMGConsulenteEsempio from "assets/images/team-4.jpg";

export default function StartPanel({
  selectedRoom,
  handleSelectedRoom,
  room,
  messages,
  type,
  roomCustomer,
}) {
  console.log("alex room", roomCustomer);
  return (
    <div className="chat">
      {type === 0 ? (
        <ProductAssistance
          {...{ selectedRoom, handleSelectedRoom, room, messages, roomCustomer }}
        />
      ) : (
        <ContattaOperatore
          {...{ selectedRoom, handleSelectedRoom, room, messages, roomCustomer }}
        />
      )}
    </div>
  );
}

function ContattaOperatore({ selectedRoom, handleSelectedRoom, room, messages, roomCustomer }) {
  return (
    <>
      <div className="overflow-y-auto mb-3">
        <span className="title d-block">
          I nostri operatori specializzati sono a tua disposizione
        </span>
        <span className="description">
          I nostri operatori Wewelfare sono a tua disposizione per aiutarti nella pratica, chiarire
          qualsiasi dubbio o assisterti nella procedura di acquisto della pratica
        </span>
      </div>

      <section style={{ position: "relative", width: "100%" }}>
        <input type="text" placeholder="Come possiamo aiutarti?" />
        <button type="button" className="inputBtn">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </section>
    </>
  );
}

function ProductAssistance({ selectedRoom, handleSelectedRoom, room, messages, roomCustomer }) {
  return (
    <>
      <div className="w-100 scrollbar-typeA scrollbar-tertiary">
        <div className="py-3 mb-2 border-top border-2 w-100 overflow-y-auto overflow-x-hidden">
          <p className="title lh-1 text-center fw-bold text-black">
            Per quale prodotto hai bisogno di assistenza?
          </p>
          {room?.map((contact) => (
            <Tooltip title={contact.name} placement="left">
              <button
                onClick={() => {
                  handleSelectedRoom(contact.identifier);
                }}
                type="button"
                className="btn m-auto text-truncate btn-outline-primary p-1 fs-6 my-2 d-block w-100"
              >
                {contact.name}
              </button>
            </Tooltip>
          ))}
        </div>
        {/* <p className="title lh-1 fw-bold text-center text-black">
          Preferisco parlare con assistenza clienti
        </p>
        <p className="text-center">
          <span className="text-center description small">
            I nostri operatori Wewelfare sono a tua disposizione per aiutarti nella pratica,
            chiarire qualsiasi dubbio o assisterti nella procedura di acquisto della pratica
          </span>
        </p>
        <button
          type="button"
          className="btn m-auto fs-6 my-2 d-block"
          aria-label="Customer Care"
          data-mui-internal-clone-element="true"
          onClick={() => {
            handleSelectedRoom(roomCustomer.identifier);
          }}
        >
          Customer Care
        </button> */}
      </div>
    </>
  );
}
