import Switch from "@mui/material/Switch";

// ProductsList page components
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import MDBadge from "components/MDBadge";

// Images
import EditIcon from "@mui/icons-material/Edit";

import MDButton from "components/MDButton";

export default {
  columns: [
    { Header: "Ufficio", accessor: "businessName" },
    { Header: "Email", accessor: "email" },
    { Header: "Telefono", accessor: "phone" },
    { Header: "Citta", accessor: "city" },
    { Header: "Numero Operatori", accessor: "numeroOperator" },
    {
      Header: "azione",
      accessor: "azione",
      Cell: ({ value }) => (
        <>
          <MDButton
            onClick={() => {
              value.action();
            }}
            color="error"
          >
            <EditIcon />
          </MDButton>
        </>
      ),
    },
  ],
};
