import Switch from "@mui/material/Switch";

// ProductsList page components
import MDBadge from "components/MDBadge";

// Images
import EditIcon from "@mui/icons-material/Edit";

import MDButton from "components/MDButton";

export default {
  columns: [
    { Header: "nome", accessor: "nome" },
    {
      Header: "categoria",
      accessor: "categoria",
      Cell: ({ value }) => <MDBadge badgeContent={value} color="info" />,
    },
    { Header: "Piattaforme", accessor: "platforms" },
    { Header: "prezzo", accessor: "prezzo" },
    { Header: "tipologia", accessor: "tipologia" },
    {
      Header: "stato",
      accessor: "stato",
      Cell: ({ value }) => <Switch onClick={() => value.action()} checked={value.value} />,
    },
    {
      Header: "azione",
      accessor: "azione",
      Cell: ({ value }) => (
        <>
          <MDButton onClick={() => value.action()} color="error">
            <EditIcon />
          </MDButton>
        </>
      ),
    },
  ],
};
