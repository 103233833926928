import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "commoncomponent/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commoncomponent/Navbars/DashboardNavbar";
import Footer from "commoncomponent/Footer";
import DataTable from "commoncomponent/Tables/DataTable";

// ProductsList page components
import { Link, useNavigate, useParams } from "react-router-dom";

import FormField from "pagensp/CustomComponents/FormField";
import usePagination from "hooks/use-pagination";
import Pagination from "components/Pagination";

import useHttp from "../../../hooks/use-http";
import dataTableData from "./data/dataTableData";

/* eslint-disable no-shadow */
function ArchiveUserNsp() {
  const history = useNavigate();
  const [search, setSearch] = useState("");
  const {
    currentPage: page,
    paginate: setPage,
    totalPages,
    // calculateTotalPages,
    setTotalPosts,
    nextPage,
    firstPage,
    lastPage,
    prevPage,
    selectPage,
  } = usePagination();
  const { sendRequest, isLoading } = useHttp();

  const paginationCallback = (pag) => (e) => setPage(pag);
  const [rows, setRows] = useState([]);
  const handleRows = useCallback(
    (tempRows) => {
      const appoggio = tempRows.list.map((row) => ({
        id: row.id,
        nome: row.name,
        cognome: row.surname,
        status: row.status || "Paid",
        email: row.email,
        type: row.type,
        azione: {
          action: () => {
            history(`/gestione/utente/${row.id}`);
          },
        },
      }));
      setRows(appoggio);
      setTotalPosts(tempRows.tot);
    },
    [setRows, setTotalPosts]
  );

  const onClickSearch = () => {
    setPage(1);
    getList({ search, page: 1 });
  };
  const onClickReset = () => {
    setSearch("");
    getList({ search: "", page: 1 });
  };
  const getList = ({ search, page }) => {
    sendRequest({
      url: `/users?filters=[["deleted","=",0]${
        search.length > 0 ? `,[["email","name","surname"], "MATCH", "%25${search}%25"]` : ``
      }]&per_page=25&page=${page}`,
      manageData: handleRows,
    });
  };

  const [dataTable, setDataTable] = useState({ ...dataTableData, rows: [...rows] });
  useEffect(() => {
    setDataTable({ ...dataTableData, rows: [...rows] });
  }, [rows]);

  useEffect(() => {
    getList({ search, page });
  }, [page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                Elenco Utenti
                <Link to={{ pathname: "/gestione/utente" }}>
                  <MDButton variant="gradient" color="info" sx={{ m: 2 }}>
                    <Icon>add</Icon>&nbsp; Nuovo utente
                  </MDButton>
                </Link>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={6} offset={4}>
                  <FormField
                    onChange={({ target }) => setSearch(target.value)}
                    type="text"
                    label="Cerca"
                    value={search}
                    onKeyDown={({ key }) => key === "Enter" && onClickSearch()}
                  />
                </Grid>
                <Grid container p={2} xs={2} gap={1}>
                  <MDButton variant="gradient" color="info" onClick={onClickSearch}>
                    Cerca
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={onClickReset}>
                    Reset
                  </MDButton>
                </Grid>
              </Grid>
              {isLoading ? (
                <Grid container p={4} justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  <DataTable table={dataTable} />
                  <Pagination
                    activePage={page}
                    onNext={nextPage}
                    onPrev={prevPage}
                    numbersOfPage={totalPages}
                    callback={paginationCallback}
                    onLast={lastPage}
                    onFirst={firstPage}
                    selectPage={selectPage}
                  />
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ArchiveUserNsp;
