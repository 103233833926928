import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { Backdrop, Button, Fade, Grid, Modal, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import styleModal from "assets/theme/base/modalconfig";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import formatDate from "function/format-date";
import useHttp from "hooks/use-http";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import moment from "moment";
import { useState } from "react";
import PreviewFile from "../PreviewFile";
import downloadMultipleFiles from "./download";

/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
const ApprovalProcess = ({
  user,
  documents,
  mandate_signed: mandateSigned,
  signed_file: signedFile,
  fields,
  id,
  handleLoadData,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewList, setPreviewList] = useState(null);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [open, setOpen] = useState();
  const [tempIdDocField, setTempIdDocField] = useState(0);
  const [msgRejected, setMsgRejected] = useState("");
  const [typeRejected, setTypeRejected] = useState("");
  const a = "";
  const { sendRequest } = useHttp();

  const handleClose = () => setOpen(false);
  const rejectedDocumentModal = (dId, documentType) => {
    setOpen(true);
    setTempIdDocField(dId);
    setTypeRejected(documentType);
  };
  const msgHandler = (e) => {
    setMsgRejected(e.target.value);
  };
  const rejectedDocument = () => {
    if (typeRejected === "field") {
      confirmField({ fId: tempIdDocField, msg: msgRejected, tempApproved: false });
    }
    if (typeRejected === "document") {
      confirmDocument({ dId: tempIdDocField, msg: msgRejected, tempApproved: false });
    }
    setOpen(false);
  };

  const confirmDocument = async ({ dId, msg = null, tempApproved = true }) => {
    if (previewFile) {
      setPreviewFile((prev) => ({ ...prev, loading: true }));
    }

    const data = {
      documents: [
        {
          id: dId,
          approved: tempApproved,
          operator_notes: msg,
        },
      ],
    };
    await sendRequest({
      url: `/orders/${id}`,
      methodH: "PUT",
      bodyH: { data, delete_items: false },
      manageData: handleLoadData,
    });

    if (previewFile) {
      setPreviewFile((prev) => ({
        ...prev,
        approved: tempApproved,
        operator_notes: msg,
        loading: false,
      }));
    }
  };
  const confirmField = async ({ fId, msg = null, tempApproved = true }) => {
    const data = { fields: [{ id: fId, approved: tempApproved, operator_notes: msg }] };
    await sendRequest({
      url: `/orders/${id}`,
      methodH: "PUT",
      bodyH: { data, delete_items: false },
      manageData: handleLoadData,
    });
  };
  const onClickPreview = (item, list, index) => {
    setPreviewFile(item);
    setPreviewList(list);
    setPreviewIndex(index);
  };
  const onCategorizeDocument = async (document, practiceDocument) => {
    setPreviewFile((prev) => ({ ...prev, loading: true }));
    await sendRequest({
      url: `/users/${user.id}`,
      methodH: "PUT",
      bodyH: {
        data: {
          documents: [
            {
              id: document.id,
              document: practiceDocument.document.id,
            },
          ],
        },
        delete_items: false,
      },
      manageData: handleLoadData,
    });
    await sendRequest({
      url: `/orders/${id}`,
      methodH: "GET",
      manageData: handleLoadData,
    });
    setPreviewFile((prev) => ({
      ...prev,
      document: { ...practiceDocument.document },
      loading: false,
    }));
  };
  const onDeleteDocument = (document) => {
    if (!confirm("Sei sicuro di voler rimuovere questo documento?")) return;
    sendRequest({
      url: `/users/${user.id}`,
      methodH: "PUT",
      bodyH: {
        data: {
          documents: [
            {
              id: document.id,
              deleted: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
          ],
        },
        delete_items: false,
      },
      manageData: handleLoadData,
    });
  };
  const onDownloadAllUserDocuments = () => {
    const documentsDownload = user?.documents?.map(({ file }) => file);
    downloadMultipleFiles(documentsDownload);
  };

  const onDownloadAllOrderDocuments = () => {
    const documentsDownload = documents
      .filter(({ user_document }) => user_document?.text)
      .map(({ user_document }) => user_document.text);
    downloadMultipleFiles(documentsDownload);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <MDBox sx={styleModal} pt={2}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} lg={12}>
                <FormField
                  type="text"
                  label="Descrivere Motivo del Rifiuto"
                  defaultValue=""
                  // value={name}
                  onChange={msgHandler}
                />
              </Grid>
              <Grid container justifyContent="center" xs={12} lg={12}>
                <MDButton color="primary" onClick={() => rejectedDocument()}>
                  Rifiuta
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Fade>
      </Modal>
      {/* <Accordion expanded>
        <AccordionSummary>Mandato di incarico</AccordionSummary>
        <AccordionSummary>
          <Typography>
            Firmato il {mandateSigned && formatDate(mandateSigned, "dd/mm/YYYY")}
          </Typography>
          <MDButton onClick={() => window.open(signedFile)}>Download</MDButton>
        </AccordionSummary>
      </Accordion> */}
      <MDBox mb={2}>
        <Card>
          <MDBox p={3}>
            <Typography variant="h4">Mandato di incarico</Typography>
            <Typography variant="body2">
              {mandateSigned ? (
                <>Firmato il {formatDate(mandateSigned, "dd/mm/YYYY")}</>
              ) : (
                <>Non Ancora Firmato</>
              )}
            </Typography>
            {mandateSigned && (
              <>
                <MDButton
                  variant="contained"
                  color="primary"
                  mt={2}
                  onClick={() => window.open(signedFile, "_parent")}
                >
                  Scarica
                </MDButton>
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>

      <MDBox my={2}>
        <Card>
          <MDBox p={3}>
            <Typography my={2} variant="h4">
              Campi anagrafici
            </Typography>

            {fields?.map(
              ({ field, user_field: userField, id: fId, approved, operator_notes: notes }) => (
                <MDBox
                  className="elemento"
                  my={2}
                  sx={{ borderBottom: "0.0625rem solid #f6f6f6" }}
                  key={fId}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                    <div style={{ flexGrow: "2" }}>
                      <div className="stato" style={{ float: "left" }}>
                        {approved === true && (
                          <>
                            <StatusCell icon="done" color="success" status="Accettato" />
                          </>
                        )}
                        {approved === false && (
                          <>
                            <StatusCell icon="clear" color="error" status="Rifiutato" />
                          </>
                        )}
                      </div>
                      <Typography>
                        <strong>{field.text}:</strong> {userField?.text}{" "}
                      </Typography>
                      {approved === false && (
                        <Typography variant="" style={{ fontSize: ".8rem" }} color="error" mt={-2}>
                          {notes}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <Button onClick={() => confirmField({ fId })} disabled={approved === true}>
                        Conferma
                      </Button>
                      <Button
                        onClick={() => rejectedDocumentModal(fId, "field")}
                        disabled={approved === false}
                      >
                        Rifiuta
                      </Button>
                    </div>
                  </div>
                </MDBox>
              )
            )}
          </MDBox>
        </Card>
      </MDBox>

      <MDBox my={2}>
        <Card>
          <MDBox p={3}>
            <Typography variant="h4">Documenti pratica</Typography>

            {documents?.map(
              (
                { document, user_document: userDocument, id: dId, approved, operator_notes: notes },
                index
              ) => (
                <MDBox
                  className="elemento"
                  my={2}
                  sx={{ borderBottom: "0.0625rem solid #f6f6f6" }}
                  key={dId}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ flexGrow: "2", overflow: "hidden" }} className="mobile-100">
                      <div className="stato" style={{ float: "left" }}>
                        {approved === true && (
                          <StatusCell icon="done" color="success" status="Accettato" />
                        )}
                        {approved === false && (
                          <StatusCell icon="clear" color="error" status="Rifiutato" />
                        )}
                      </div>
                      <Typography>{document.text}</Typography>
                      {userDocument?.text && approved === false && (
                        <Typography variant="" style={{ fontSize: ".8rem" }} color="error" mt={-2}>
                          {notes}
                        </Typography>
                      )}
                    </div>
                    <div className="mobile-100" style={{ minWidth: 220 }}>
                      {userDocument?.text && (
                        <>
                          <MDButton
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onClickPreview(
                                { ...documents[index], url: userDocument.text },
                                documents
                                  .filter(({ user_document }) => user_document)
                                  .map((item) => ({ ...item, url: item.user_document.text })),
                                index
                              )
                            }
                          >
                            Anteprima
                          </MDButton>
                          <MDButton
                            variant="contained"
                            color="primary"
                            onClick={() => window.open(userDocument?.text, "_parent")}
                            style={{ marginLeft: 5 }}
                          >
                            <DownloadIcon />
                          </MDButton>
                          <br />
                          <Button
                            onClick={() => confirmDocument({ dId })}
                            disabled={approved === true}
                          >
                            Conferma
                          </Button>
                          <Button
                            onClick={() => rejectedDocumentModal(dId, "document")}
                            disabled={approved === false}
                          >
                            Rifiuta
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </MDBox>
              )
            )}

            <MDButton
              variant="contained"
              color="primary"
              onClick={onDownloadAllOrderDocuments}
              style={{ marginLeft: 5 }}
            >
              <DownloadIcon />
              Scarica tutti i documenti
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>

      <MDBox my={2}>
        <Card>
          <MDBox p={3}>
            <Typography variant="h4">Documenti utente associabili alla pratica</Typography>
            <MDBox>
              {user?.documents?.map((item, index) => (
                <MDBox
                  className="elemento"
                  my={2}
                  sx={{ borderBottom: "0.0625rem solid #f6f6f6" }}
                  key={item.id}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ flexGrow: "2", overflow: "hidden" }} className="mobile-100">
                      <Typography variant="h6">
                        {item.file_filename}.{item.file_ext}
                      </Typography>
                      <Typography variant="subtitle2">{item.document.text}</Typography>
                    </div>
                    <div className="mobile-100" style={{ minWidth: 190 }}>
                      <MDButton
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          onClickPreview(
                            { ...item, url: item.file },
                            user?.documents.map((item) => ({ ...item, url: item.file })),
                            index
                          )
                        }
                      >
                        Anteprima
                      </MDButton>
                      <MDButton
                        variant="contained"
                        color="primary"
                        onClick={() => window.open(item.file)}
                        style={{ marginLeft: 5 }}
                      >
                        <DownloadIcon />
                      </MDButton>
                      <MDButton
                        variant="contained"
                        color="error"
                        onClick={() => onDeleteDocument(item)}
                        style={{ marginLeft: 5 }}
                      >
                        <DeleteIcon />
                      </MDButton>
                    </div>
                  </div>
                </MDBox>
              ))}
            </MDBox>

            <MDButton
              variant="contained"
              color="primary"
              onClick={onDownloadAllUserDocuments}
              style={{ marginLeft: 5 }}
            >
              <DownloadIcon />
              Scarica tutti i documenti
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>

      {previewFile && (
        <PreviewFile
          item={previewFile}
          categories={documents}
          onSubmit={onCategorizeDocument}
          onClose={() => setPreviewFile(null)}
          onConfirm={() => confirmDocument({ dId: previewFile.id })}
          onReject={() => rejectedDocumentModal(previewFile.id, "document")}
          onPrev={() => {
            const newIndex = previewIndex - 1 >= 0 ? previewIndex - 1 : previewList.length - 1;
            setPreviewIndex(newIndex);
            setPreviewFile(previewList[newIndex]);
          }}
          onNext={() => {
            const newIndex = previewIndex + 1 < previewList.length ? previewIndex + 1 : 0;
            setPreviewIndex(newIndex);
            setPreviewFile(previewList[newIndex]);
          }}
        />
      )}

      <iframe title="downloader-frame" name="downloader-frame" style={{ display: "none" }} />
    </div>
  );
};
export default ApprovalProcess;
