// The editor core
import Editor from "@react-page/editor";

// Plugins custom
import HtmlElements from "./plugins/HtmlElements";
import CustomImage from "./plugins/CustomImage";
import CustomBackground from "./plugins/CustomBackground";
import CustomSlate from "./plugins/CustomSlate";
import CustomSpacer from "./plugins/CustomSpacer";
import CustomDivider from "./plugins/CustomDivider";
import CustomVideo from "./plugins/CustomVideo";
import LandingVideo from "./plugins/LandingVideo";
import CtaInline from "./plugins/CtaInline";
import Trustpilot from "./plugins/Trustpilot";
import TrustpilotLanding from "./plugins/TrustpilotLanding";
import ParlanoDiNoi from "./plugins/ParlanoDiNoi";
import Cards from "./plugins/Cards";
import CardsLanding from "./plugins/CardsLanding";
import Steps from "./plugins/Steps";
import Percentages from "./plugins/Percentages";
import CtaProduct from "./plugins/CtaProduct";
import FAQ from "./plugins/FAQ";
import TimerLanding from "./plugins/TimerLanding";
import CounterLanding from "./plugins/CounterLanding";

import "@react-page/editor/lib/index.css";
import "@react-page/plugins-slate/lib/index.css";
import "@react-page/plugins-image/lib/index.css";
import "./css/react-page.css";

export const cellPlugins = [
  ...HtmlElements,
  CustomSlate,
  CustomImage,
  CustomBackground,
  CustomVideo,
  LandingVideo,
  CustomSpacer,
  CustomDivider,
  CtaInline,
  CtaProduct,
  Trustpilot,
  TrustpilotLanding,
  ParlanoDiNoi,
  Cards,
  CardsLanding,
  Steps,
  Percentages,
  FAQ,
  TimerLanding,
  CounterLanding,
];

export default Editor;
