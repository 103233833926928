import Box from "@mui/material/Box";

import AlertPending from "assets/images/nsp/btn-pending.png";
import AlertInfo from "assets/images/nsp/btn-alert.png";
import AlertCheck from "assets/images/nsp/btn-check.png";
import AlertNotification from "assets/images/nsp/btn-notification.png";
import CloseIcon from "assets/images/nsp/closeIcon.png";
import "./index.css";

let typeAlert = "default";
let underlineAlert = "none";
let closeBtn = 0;

export const AlertContainer = (props) => {
  const { children, type, closeOff } = props;
  typeAlert = type;
  closeBtn = closeOff;

  return (
    <Box
      className={`NSPalert-container border-radius-bottom-start-xl border-radius-bottom-end-xl montserrat 
      ${type === "fill" && "fill-container"}`}
    >
      {children}
    </Box>
  );
};

export const AlertLink = (props) => {
  const { children, onClick } = props;
  return (
    <button
      type="button"
      className={`NSP-alertLink ${underlineAlert && "text-decoration-underline"}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Alert = (props) => {
  const { info, check, pending, notification, underline, inverse, children, readed } = props;
  underlineAlert = underline;

  const getImage = () => {
    let image;

    if (pending) {
      image = AlertPending;
    } else if (info) {
      image = AlertInfo;
    } else if (check) {
      image = AlertCheck;
    } else if (notification) {
      image = AlertNotification;
    }

    return image;
  };

  const getSquare = () => {
    let side;
    switch (typeAlert) {
      case "fill":
        side = 30;
        break;
      default:
        side = 25;
        break;
    }
    return side;
  };

  const DefaultAlert = () => (
    <div className={`NSP-alert ${typeAlert === "fill" && "fill-alert"}`}>
      <div className={`d-flex align-items-center ${closeBtn ? "col-12" : "col-11"}`}>
        <div
          className={`alert-icon
          ${typeAlert === "fill" ? "px-4" : "col-2"} 
          ${inverse && "inverse"}
          ${readed && "readed"}
          `}
        >
          <img
            src={getImage()}
            loading="lazy"
            alt="alertImg"
            height={getSquare()}
            width={getSquare()}
          />
        </div>

        <div
          className={`fs-8 fs-md-6 text-black text-truncate ms-n2 text-start pe-2 d-flex flex-grow-1
            ${typeAlert === "fill" ? "px-2" : "fst-italic"} 
            ${underline && "text-decoration-underline"}
            ${readed && "readed"}
            `}
        >
          <small> {children} </small>
        </div>
      </div>

      <div className={`col-1 text-center ${closeBtn && "d-none"}`}>
        <img
          src={CloseIcon}
          loading="lazy"
          alt="closeButton"
          className="cursor-pointer"
          style={{ width: "1em" }}
        />
      </div>
    </div>
  );

  return <DefaultAlert />;
};
export default Alert;
