import React, { useState, useEffect, useCallback, useContext } from "react";

import ErrContext from "pagensp/store/error-context";
import AuthContext from "pagensp/store/auth-context";

const useHttp = () => {
  const { sendError } = useContext(ErrContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token, isLoggedIn, logout } = useContext(AuthContext);

  const sendRequest = useCallback(
    async ({ url, methodH, headersH, bodyH, manageData = (x) => x, cache = true }) => {
      setIsLoading(true);
      setError(null);

      try {
        const fullUrl = new URL(`${process.env.REACT_APP_API_ENDPOINT}${url}`);

        if (!cache) {
          const timestamp = Math.floor(Date.now() / 1000);
          fullUrl.searchParams.append("nocache", timestamp.toString());
        }

        const response = await fetch(fullUrl.toString(), {
          method: methodH || "GET",
          headers: headersH || {
            "content-type": "application/json",
            Authorization: token || "",
          },
          body: JSON.stringify(bodyH) || null,
        });

        if (!response.ok) {
          response.text().then((text) => {
            sendError(JSON.parse(text).error);
            if (JSON.parse(text).error === "Unauthorized") {
              logout();
            }
            throw new Error(JSON.parse(text).message);
          });
        }

        const data = await response.json();
        manageData(data);
      } catch (err) {
        console.log("ERRORE THROWATO", err);
        // sendError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  return { isLoading, error, sendRequest };
};
export default useHttp;
