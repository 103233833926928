import { Autocomplete, TextField } from "@mui/material";
import { Container } from "./style";

const Select = ({ label, options, name, value, onChange, disabled }) => (
  <Container>
    <Autocomplete
      autoComplete
      options={options || []}
      isOptionEqualToValue={(option, val) => option.value === val}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={label} name={name} variant="standard" autoComplete="off" />
      )}
      value={options?.find((item) => item.value === value) || null}
      onChange={(event, val) => onChange(val)}
      disabled={disabled}
    />
  </Container>
);

Select.defaultProps = {
  options: [],
  onChange: () => {},
  value: null,
  disabled: false,
};

export default Select;
