// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function SelectPrersonaldocumentation({
  documents,
  addDocument,
  documentsOption,
  editDocumentValue,
  editRequiredDocumentValue,
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Documenti necessari</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={2} pt={2}>
            {documents.map((doc, index) => (
              <>
                <Grid item xs={6} lg={6}>
                  <Autocomplete
                    value={
                      doc.document.text
                        ? { label: doc.document.text, value: doc.document.id }
                        : doc.value
                    }
                    defaultValue=""
                    options={documentsOption}
                    onChange={(event, data) => {
                      if (data) {
                        editDocumentValue(data.value, index);
                      } else {
                        editDocumentValue("del", index);
                      }
                    }}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <FormControlLabel
                    control={<Switch checked={doc.required} />}
                    label="Richiesto"
                    onChange={() => {
                      editRequiredDocumentValue(index);
                    }}
                  />
                </Grid>
              </>
            ))}
            <Grid item xs={12} lg={12} pt={2}>
              <MDButton
                onClick={() => {
                  addDocument({ document: "", required: false, reference_year: 0 });
                }}
                variant="gradient"
                color="info"
              >
                + Elemento
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default SelectPrersonaldocumentation;
