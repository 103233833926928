import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { Dropzone, FileItem } from "@dropzone-ui/react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// EditProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import GestUploadImg from "pagensp/CustomComponents/GestUploadImg/GestUploadImg";
import { Link } from "react-router-dom";
import Preloader from "components/Preloader/Preloader";
import { platforms } from "config";

function ProductDxSidebar({
  activePlatforms,
  setActivePlatforms,
  tags,
  visible,
  visibleHandler,
  home,
  homeHandler,
  consultingAllowed,
  consultingHandler,
  price,
  priceHandler,
  saveHandler,
  categoryOption,
  editCategory,
  category,
  subCategoryOption,
  editSubCategory,
  subCategory,
  files,
  images,
  updateFiles,
  imgHandlerRemove,
  allTag,
  editTag,
  removeProduct,
  updateAttachments,
  attachments,
  attachmentsHandlerRemove,
  isYearly,
  isYarlyHandler,
  isSaveLoading,
}) {
  const [mainImmage, setMainImmage] = useState();

  useEffect(() => {
    setMainImmage(images.find((data) => data.main === true));
  }, [images]);

  return (
    <Card>
      <MDBox p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" onClick={saveHandler} fullWidth>
                <Preloader isLoading={isSaveLoading}>save</Preloader>
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex">
              <MDButton variant="gradient" color="light" size="large">
                <Link to={{ pathname: "/gestione/prodottie" }} color="light">
                  Chiudi
                </Link>
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex">
              <MDButton variant="gradient" color="error" size="large" onClick={removeProduct}>
                <DeleteIcon />
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="h5" fontWeight="bold">
          Configurazione prodotto
        </MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField
                type="number"
                value={price}
                onChange={priceHandler}
                label="Prezzo prodotto"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={isYearly} onChange={isYarlyHandler} />}
                label="Prodotto Annuale"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={visible} onChange={visibleHandler} />}
                label="Prodotto Attivo"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={home} onChange={homeHandler} />}
                label="Prodotto in Evidenza"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={consultingAllowed} onChange={consultingHandler} />}
                label="Abilitato alla consulenza"
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Pubblica su piattaforme
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                value={activePlatforms}
                options={platforms}
                onChange={(event, data) => {
                  setActivePlatforms(data);
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Tags
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                value={tags}
                options={allTag}
                onChange={(event, data) => {
                  editTag(data);
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Categorie dei prodotti
                </MDTypography>
              </MDBox>
              <Autocomplete
                value={
                  category
                    ? {
                        label: category.text ?? category.label,
                        value: category.id ?? category.value,
                      }
                    : { label: "", value: "" }
                }
                options={categoryOption}
                onChange={(event, data) => {
                  if (data) {
                    editCategory(data, "");
                  } else {
                    editCategory(data, "del");
                  }
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
              {category ? (
                <>
                  <MDBox my={2} display="inline-block">
                    <MDTypography component="label" variant="h6" fontWeight="bold">
                      Sottocategoria
                    </MDTypography>
                  </MDBox>
                  <Autocomplete
                    value={
                      subCategory
                        ? {
                            label: subCategory.text ?? subCategory.label,
                            value: subCategory.id ?? subCategory.value,
                          }
                        : { label: "", value: "" }
                    }
                    options={subCategoryOption}
                    onChange={(event, data) => {
                      if (data) {
                        editSubCategory(data, "");
                      } else {
                        editSubCategory(data, "del");
                      }
                    }}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                {!mainImmage ? (
                  <>
                    <br />
                    <MDTypography variant="button">
                      Utilizza una immagine non piu grande di 2Mb
                    </MDTypography>
                    <Dropzone
                      onChange={updateFiles}
                      value={files}
                      maxFiles={1}
                      maxFileSize={2998000}
                      accept=".jpg,.jpeg,.png,image/*"
                    >
                      {files.map((file) => (
                        <FileItem {...file} preview />
                      ))}
                    </Dropzone>
                  </>
                ) : (
                  <>
                    <GestUploadImg
                      UploadImage={mainImmage.img}
                      imgHandlerRemove={imgHandlerRemove}
                    />
                  </>
                )}
              </MDBox>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="h6" fontWeight="bold">
                  Documentazione prodotto
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid container spacing={2}>
              {attachments.map((attApp, index) => (
                <>
                  <Grid item xs={12} lg={9}>
                    {attApp.id !== undefined ? `Documento ${index + 1}` : attApp.file.name}
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      size="small"
                      onClick={() => {
                        attachmentsHandlerRemove(attApp.id);
                      }}
                    >
                      <DeleteIcon />
                    </MDButton>
                  </Grid>
                </>
              ))}
            </Grid>
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
                <FormField type="file" size="medium" onChange={updateAttachments} />
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ProductDxSidebar;
