import { useEffect } from "react";

export default function useBlockScroll() {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.overflowY = "";
      document.body.style.height = "";
    };
  }, []);
}
