import MDTypography from "components/MDTypography";

// ProductsList page components
import { Chip, Grid } from "@mui/material";

export default {
  columns: [
    {
      Header: "Prodotto",
      accessor: "name",
      Cell: ({ value }) => <MDTypography variant="button">{value}</MDTypography>,
    },
    {
      Header: "Pratiche Totali",
      accessor: "totals.all",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="primary"
          />
        </Grid>
      ),
    },
    {
      Header: "Sospese non pagate",
      accessor: "totals.pending_unpaid",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="primary"
          />
        </Grid>
      ),
    },
    {
      Header: "Totale pagato",
      accessor: "totals.all_paid",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="default"
          />
        </Grid>
      ),
    },
    {
      Header: "Sospese pagate",
      accessor: "totals.pending_paid",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="primary"
          />
        </Grid>
      ),
    },
    {
      Header: "Rimborsate",
      accessor: "totals.refund",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="error"
          />
        </Grid>
      ),
    },
    {
      Header: "In corso",
      accessor: "totals.active",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="warning"
          />
        </Grid>
      ),
    },
    {
      Header: "Complete",
      accessor: "totals.done",
      Cell: ({ value }) => (
        <Grid container gap={1}>
          <Chip label={value.count} color="secondary" />
          <Chip
            label={
              new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(value.amount) || 0
            }
            color="success"
          />
        </Grid>
      ),
    },
  ],
};
