import { useEffect, useState } from "react";

const dateWrapper = (date) => {
  if (typeof date === "string") {
    const dateApp = date.replace(" ", "T");
  } else if (typeof date === "undefined") {
    return new Date();
  }

  const dt = new Date(date);
  if (dt !== "Invalid date") {
    return dt !== "Invalid date" ? dt : new Date();
  }
  return date;
};

export default dateWrapper;
